const columns = (renderValueCell, renderActions, renderTypeCell, isOutputEditable) => [
  {
    field: 'pumpType',
    headerName: 'Pump Type',
    width: 220,
    renderEditCell: renderTypeCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'liner_size',
    headerName: 'Liner Size (inches)',
    width: 200,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'stroke_length',
    headerName: 'Stroke Length (inches)',
    width: 220,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'efficiency',
    headerName: 'Efficiency (%)',
    width: 200,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'bbl_stk',
    headerName: 'Output (bbl/stk)',
    width: 200,
    editable: isOutputEditable,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 200,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  }
];
export default columns;
