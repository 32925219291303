import fetch from '../../../api/fetch';
import { REDUX_ACTIONS } from '../../../shared/config/constants';
import { parseError } from '../../../utils/fetchErrorParser';
import { enqueueSnackbar } from '../snackbar';

export const createEpicorReportRequest = () => ({
  type: REDUX_ACTIONS.EPICOR_REPORTS_INVENTORY_PDF_REQUEST,
});

export const createEpicorReportSuccess = (data) => ({
  type: REDUX_ACTIONS.EPICOR_REPORTS_INVENTORY_PDF_SUCCESS,
  payload: data,
});

export const createEpicorReportFailure = (error) => ({
  type: REDUX_ACTIONS.EPICOR_REPORTS_INVENTORY_PDF_ERROR,
  payload: error,
});

export const createEpicorReportInventoryPDF = (OrderNum) => (dispatch) => {
  dispatch(createEpicorReportRequest());

  const body = {
    OrderNum
  };
  return fetch
    .downloadPost('reports/inventory', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.blob();
    })
    .then((blob) => {
      // Create a download link for the PDF file
      const url = window.URL.createObjectURL(blob);
      const fileName = `${OrderNum}.pdf`;

      // Create an anchor element and set the download attribute
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;

      // Append the link to the document, click it, and remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      dispatch(createEpicorReportSuccess({ message: 'PDF downloaded successfully' }));
      dispatch(
        enqueueSnackbar(
          'File downloaded Successfully.',
          'success',
          new Date().getTime() + Math.random()
        )
      );
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(createEpicorReportFailure(errorMessage));
      dispatch(
        enqueueSnackbar(
          'Error in downloading file',
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};
