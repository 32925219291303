import fetch from '../../api/fetch';
import { ORS_URL } from '../../shared/config/config';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { parseError } from '../../utils/fetchErrorParser';
import getDistance from '../../utils/getDistance';
import { enqueueSnackbar } from './snackbar';

export const listMarketplaceFeatured = () => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.MARKETPLACE_FEATURED_LIST_LOADING,
  });
  return fetch
    .post('marketplace/products/featured')
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.MARKETPLACE_FEATURED_LIST_SUCCESS,
        payload: data.data
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);

      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};

export const searchMarketplace = (searchValue, page) => (dispatch) => {
  if (page === 1) {
    dispatch({
      type: REDUX_ACTIONS.MARKETPLACE_SEARCH_LOADING,
    });
  }

  const body = {
    query: {
      searchValue,
      page,
      limit: 51
    },
  };

  return fetch
    .post('marketplace/products', body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.MARKETPLACE_SEARCH_SUCCESS,
        payload: {
          data: data.data,
          paginatedElements: data.count,
          currentPage: page,
        },

      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);

      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.MARKETPLACE_SEARCH_ERROR,
        payload: { ...error, message: errorMessage }
      });
    });
};

export const searchMKTRigs = (value, setAutocomplete) => (dispatch) => {
  const body = {
    query: {
      searchValue: value,
    },
  };
  return fetch
    .post('marketplace/rigs', body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then(async (data) => {
      setAutocomplete(data.data);
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};

export const searchMKTLocations = (setLocations) => (dispatch) => {
  const body = {
    query: {
      limit: 20,
    },
  };
  return fetch
    .post('marketplace/locations', body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then(async (data) => {
      setLocations(data.data);
    })
    .catch(async (error) => {
      setLocations([]);
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};

export const updateDistanceToRigFromLocation = (location, rig, setDistance) => () => {
  const body = {
    coordinates: [
      [Number(location.longitude), Number(location.latitude)],
      [rig.RigLongitudeWGS84, rig.RigLatitudeWGS84],
    ],
    radiuses: [50000, 50000],
    elevation: true
  };
  getDistance(
    ORS_URL,
    body
  ).then((data) => {
    const distance = data.distance;
    const polylineCoordinates = data.route.map(coord => [coord[1], coord[0]]);
    setDistance({
      distance,
      polylineData: polylineCoordinates
    });
  });
};

export const MKTsubscribe = (body, setSubscribed, setError) => () => {
  return fetch
    .post('marketplace/subscribe', body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then(() => {
      setSubscribed({
        status: true,
        loading: false
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      setSubscribed({
        status: false,
        loading: false
      });
      setError({
        email: errorMessage,
        checkbox: ''
      });
    });
};

export const verifyCaptcha = (captchaToken, handleSuccess, setSubscribed, setError) => () => {
  const body = {
    input: {
      captchaToken
    }
  };
  return fetch
    .post('users/verify/recaptcha', body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then(() => {
      handleSuccess();
    })
    .catch(() => {
      setSubscribed({
        status: false,
        loading: false
      });
      setError({
        email: '',
        checkbox: '',
        captcha: 'Captcha verification failed.'
      });
    });
};

export const marketplaceTrackActions = (action, values) => (dispatch) => {
  const body = {
    input: {
      action,
      ...values
    },
  };
  return fetch
    .post('marketplace/userActions', body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};
