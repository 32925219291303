import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Box, Chip, TextField, Typography } from '@mui/material';

function AutocompleteComponent({ title, data, onChange, options, hideHeader, loading }) {
  const [inputValue, setInputValue] = React.useState('');

  return (
        <Box sx={{ mb: !hideHeader && 2.5 }}>
            <Typography sx={{ fontWeight: 'bold', fontSize: '15px', display: hideHeader && 'none' }} gutterBottom>
                {title}
            </Typography>
            <Autocomplete
                loading={loading}
                multiple
                id={title}
                options={options}
                value={data}
                onChange={onChange}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                            <Chip variant="outlined" label={option} key={key} {...tagProps} />
                    );
                  })
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label=""
                        placeholder={'Add ' + title}
                    />
                )}
            />
        </Box>
  );
}

AutocompleteComponent.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  hideHeader: PropTypes.bool,
  loading: PropTypes.bool
};

AutocompleteComponent.defaultProps = {
  data: [],
  hideHeader: false,
  loading: false
};

export default AutocompleteComponent;
