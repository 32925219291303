import { REDUX_ACTIONS } from '../../shared/config/constants';

const initialState = {
  actuator_report: null,
  actuator_reportLoading: false,
  actuator_reportError: null,
};

export default function actuatorReducer(state = initialState, action) {
  switch (action.type) {
    case REDUX_ACTIONS.CREATE_ACTUATOR_REPORT_REQUEST:
      return { ...state, actuator_reportLoading: true, actuator_reportError: null };
    case REDUX_ACTIONS.CREATE_ACTUATOR_REPORT_SUCCESS:
      return { ...state, actuator_reportLoading: false, actuator_report: action.payload };
    case REDUX_ACTIONS.CREATE_ACTUATOR_REPORT_FAILURE:
      return { ...state, actuator_reportLoading: false, actuator_reportError: action.payload };
    default:
      return state;
  }
}
