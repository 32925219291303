const columns = () => [
  {
    field: '_id',
    headerName: '_id',
    hide: true,
  },
  {
    field: 'state',
    headerName: 'Status',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'message',
    headerName: 'Message',
    width: 800,
    headerAlign: 'center',
    align: 'center',
  },
];

export default columns;
