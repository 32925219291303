import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Box,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  FilledInput,
  styled,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetPassword,
  resetPasswordReset,
} from './../../../store/actions/authorization';
import styles from './styles';
import Premium from '../../../assets/images/PRO logo PMS green only.svg';
import { isMobile } from '../../../utils/isMobile';
import MobileResetPassword from '../../mobile/resetPassword';
import ButtonComponent from '../../Button';

const ResetPassword = () => {
  const classes = styles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [userId, setUserId] = useState('');
  const [key, setKey] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const { loading, resetPasswordSuccess, resetPasswordError, errorMessage } =
    useSelector((state) => state.currentUser);

  const SuccessTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.Authentication_Success.main,
  }));
  useEffect(() => {
    let timer;
    if (resetPasswordSuccess || resetPasswordError) {
      timer = setTimeout(() => {
        dispatch(resetPasswordReset());
        navigate('/Login');
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [resetPasswordSuccess, resetPasswordError]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const linkUserId = searchParams.get('userId');
    const linkKey = searchParams.get('key');
    if (!linkUserId || !linkKey || !/^[0-9A-Fa-f]+$/.test(linkUserId)) {
      navigate('/Login');
    } else {
      setUserId(linkUserId);
      setKey(linkKey);
    }
  }, [location.search]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSet = (event) => {
    event.preventDefault();
    if (password.length === 0 || confirmPassword.length === 0) {
      setError('Please enter both password and confirm password');
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    setError('');
    dispatch(resetPassword(userId, key, password));
  };

  return (
    <Grid
      container
      alignItems="center"
      textAlign="center"
      justifyContent="center"
      className={classes.mainContainer}
    >
      <Box
          component="form"
          onSubmit={handleSet}
          noValidate
          justifyContent="center"
          sx={{ height: isMobile() ? '100%' : 'none' }}
          >
        <Grid
          item
          container
          xs={isMobile() ? 12 : 8}
          margin="auto"
          justifyContent="center"
          className={isMobile() ? '' : classes.loginContainer}
        >
          <Grid item container xs={12} justifyContent="center">
            <Box
              component="img"
              alt="Logo"
              src={Premium}
              className={classes.logo}
            />
          </Grid>
          { isMobile()
            ? <MobileResetPassword
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
                password={password}
                showPassword={showPassword}
                setPassword={setPassword}
                handleClickShowPassword={handleClickShowPassword}
                handleMouseDownPassword={handleMouseDownPassword}
                error={error}
                resetPasswordSuccess={resetPasswordSuccess}
                resetPasswordError={resetPasswordError}
                errorMessage={errorMessage}
                 />
            : <Grid item xs={12} padding={1} className={classes.formContainer}>
            <FormControl
              sx={{
                bgcolor: 'transparent',
              }}
              fullWidth
              variant="filled"
              value={password}
              onChange={(value) => {
                setPassword(value.target.value);
              }}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <FilledInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                disableUnderline={true}
                className={classes.passwordInput}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <FormControl
              sx={{
                bgcolor: 'transparent',
              }}
              fullWidth
              variant="filled"
              value={confirmPassword}
              onChange={(value) => {
                setConfirmPassword(value.target.value);
              }}
            >
              <InputLabel htmlFor="outlined-adornment-confirm-password">
                Confirm Password
              </InputLabel>
              <FilledInput
                id="outlined-adornment-confirm-password"
                type={showPassword ? 'text' : 'password'}
                disableUnderline={true}
                className={classes.passwordInput}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
              />
            </FormControl>
            <Typography color="error">{error}</Typography>
            {resetPasswordSuccess && (
              <SuccessTypography>Password reset successful.</SuccessTypography>
            )}
            {resetPasswordError && (
              <Typography color="error">
                 {errorMessage}
              </Typography>
            )}
          </Grid>}
          <Grid item xs={isMobile() ? 10 : 6}
            className={isMobile() ? classes.loginButton : ''}
          >
            <ButtonComponent
              label='Set'
              fullWidth
              className={classes.button}
              disabled={loading || resetPasswordSuccess || resetPasswordError }
              type="submit"
            >
            </ButtonComponent>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default ResetPassword;
