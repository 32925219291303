import { makeStyles } from '@mui/styles';

const styles = makeStyles(({ spacing }) => ({
  card: {
    position: 'relative',
    borderRadius: 10
  },
  overlay: {
    top: '150px',
    color: 'black',
    position: 'absolute',
    backgroundColor: 'white',
    width: '100%',
    padding: spacing(2),
    opacity: 0.8,
    fontSize: 14,
    fontWeight: 'bold',
  },
}));

export default styles;
