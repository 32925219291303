import { REDUX_ACTIONS } from '../../shared/config/constants';

export const dataLoading = (
  loading,
) => {
  if (loading) {
    return {
      type: REDUX_ACTIONS.DATA_LOADING,
    };
  }

  return {
    type: REDUX_ACTIONS.DATA_LOADING_ENDED,
  };
};
