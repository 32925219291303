import { Box, Grid, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { AnalyticsRounded, NotificationsNoneRounded, RoomRounded, SettingsSuggestRounded } from '@mui/icons-material';
import { ASSET_DATA_TYPES } from '../../../../shared/config/constants';
import AlarmsStatusDialogMobile from '../faultsWarningsAlarmDialog';

// eslint-disable-next-line react/prop-types
const JobStatus = ({ status, getPinColor }) => {
  const pinColor = getPinColor(status);

  return (
    pinColor && (
        <>
            <Grid justifyContent="center">
                <IconButton
                    key="systemStatus"
                    variant="solid"
                    sx={{ padding: '4px' }}
                >
                    <RoomRounded sx={{ fontSize: '20px' }} color={`${pinColor}Status`} />
                </IconButton>
            </Grid>
        </>

    )
  );
};

// eslint-disable-next-line react/prop-types
const JobAlarm = ({ data, overallStatus }) => {
  return (
    (data === 2 || data === 3) && (
            <>
                <Grid justifyContent="center">
                <IconButton
                      sx={{ padding: '4px' }}
                      key="actuator_data"
                      ariant="solid">
                <NotificationsNoneRounded sx={{ fontSize: '20px' }} color={`alarm${overallStatus}`} />
              </IconButton>
                </Grid>
            </>
    )
  );
};

const drillingModal = (assetDataType, jobId, rigName) => {
  let pageTitle = '';

  if (rigName) {
    pageTitle = `&pageTitle=${rigName}&filterId=0`;
  }

  window.open(
    `/Data?assetDataType=${assetDataType}_${jobId}${pageTitle}`,
    '_blank'
  );
};

const handleNavigate = (assetDataType, jobId, rigName) => {
  let pageTitle = '';

  if (rigName) {
    pageTitle = `&pageTitle=${rigName}&filterId=2`;
  }
  window.open(
      `/Data?assetDataType=${assetDataType}_${jobId}${pageTitle}`,
      '_blank'
  );
};

// eslint-disable-next-line react/prop-types
const RenderDataCell = ({ data, jobId, rigName }) => {
  return (
    <Grid container justifyContent="center">
        {
          /* prettier-ignore */
          data === 1
            ? (
              <IconButton
                variant="solid"
                key="drill_data"
                onClick={() => {
                  drillingModal(ASSET_DATA_TYPES.DRILLINGDATA, jobId, rigName);
                }}
              >
                <AnalyticsRounded sx={{ fontSize: '20px' }}/>
              </IconButton>
              )
            : data === 2
              ? (
                <IconButton
                  key="actuator_data"
                  variant="solid"
                  onClick={() =>
                    handleNavigate(ASSET_DATA_TYPES.ACTUATORDATA, jobId, rigName)
                  }
                >
                  <SettingsSuggestRounded sx={{ fontSize: '20px' }} />
                </IconButton>
                )
              : data === 3
                ? (
                  <>
                  <Box display='flex' flexDirection='row' marginLeft='10%'>
                  <Box>
                    <IconButton
                      key="actuator_data"
                      variant="solid"
                      onClick={() => {
                        handleNavigate(ASSET_DATA_TYPES.ACTUATORDATA, jobId, rigName);
                      }}
                    >
                      <SettingsSuggestRounded sx={{ fontSize: '20px' }} />
                    </IconButton>
                    </Box>
                    <Box>
                    <IconButton
                      variant="solid"
                      key="drill_data"
                      onClick={() => {
                        drillingModal(ASSET_DATA_TYPES.DRILLINGDATA, jobId, rigName);
                      }}
                    >
                      <AnalyticsRounded sx={{ fontSize: '20px' }} />
                    </IconButton>
                    </Box>
                    </Box>
                  </>
                  )
                : null
        }
      </Grid>
  );
};

function JobsCard({
  job,
  getPinColor,
  setRowId
}) {
  const classes = styles();
  const [isAlarmStatusModalOpen, setIsAlarmStatusModalOpen] = useState(false);
  const [currentJob, setCurrentJob] = useState();

  const handleWellsClick = (job) => {
    setRowId(job);
    setCurrentJob(job);
    const { faults, warnings } = job.systemStatusDetails;
    if (faults.length || warnings.length || job.alarms?.alarms?.length) {
      setIsAlarmStatusModalOpen(true);
    }
  };
  return (
      <Grid container>
          <Grid container className={classes.rigNameContainer}>
            <Typography className={classes.rigname}>{job?.Rig}</Typography>
            <Typography sx={{ marginLeft: 2 }}>{job?.latestTimestamp}</Typography>
          </Grid>
          <Grid container display={'flex'} alignItems={'center'} className={classes.wellNameContainer}>
              <Grid item xs={8.5} onClick={() => handleWellsClick(job)}>
                  <Typography className={classes.wellname}>{job?.Well}</Typography>
                  <Grid container>
                      {job?.usage && (
                          <Typography className={classes.wellDetails}>
                              Usage: <span style={{ fontWeight: 'bold ' }}>{job?.usage}</span>
                          </Typography>
                      )}
                      {job?.remainingLife && (
                          <Typography className={classes.wellDetails} sx={{ marginLeft: 1 }}>
                              Remaining Life: <span style={{ fontWeight: 'bold ' }}>{job?.remainingLife}</span>
                          </Typography>
                      )}
                  </Grid>
              </Grid>
              <Grid item xs={1} onClick={() => handleWellsClick(job)}>
                  <JobStatus
                      status={job?.systemStatus}
                      getPinColor={getPinColor}
                      job={job}
                  />
                  <JobAlarm
                      data={job?.data}
                      overallStatus={job?.alarms.overallStatus}
                      job={job}
                  />
              </Grid>
              <Grid item xs={2}>
                 <RenderDataCell
                      data={job?.data}
                      rigName={job?.Rig}
                      jobId={job?._id}
                  />
              </Grid>
              <AlarmsStatusDialogMobile
                    isOpen={isAlarmStatusModalOpen}
                    onCloseClick={() => setIsAlarmStatusModalOpen(false)}
                    selectedJob={currentJob}
                />
          </Grid>
      </Grid>
  );
}

JobsCard.propTypes = {
  job: PropTypes.object,
  getPinColor: PropTypes.func,
  setRowId: PropTypes.func
};

export default JobsCard;
