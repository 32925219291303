import { REDUX_ACTIONS } from '../../shared/config/constants';

const initialState = {
  loading: false,
  errorMessage: '',
  data: [],
  marker_data: [],
  paginatedElements: 0,
  initialData: [],
  limit: 100,
  totalCount: 0,
  currentPage: 1,
  searchKey: null,
  startDate: null,
  endDate: null,
  filters: [],
  sort: null,
  checkInsRigs: {
    data: [],
    paginatedElements: 0
  }
};

const checkInsDrillReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.CHECKINS_DRILL_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };

    case REDUX_ACTIONS.CHECKINS_DRILL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...action.payload.data],
        marker_data: [...action.payload.data],
        initialData: [...action.payload.data],
        totalCount: action.payload.totalCount,
        paginatedElements: action.payload.paginatedElements,
        errorMessage: '',
      };

    case REDUX_ACTIONS.CHECKINS_DRILL_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: [],
        marker_data: []
      };
    case REDUX_ACTIONS.CHECKINS_DRILL_SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case REDUX_ACTIONS.CHECKINS_DRILL_SEARCH_CONTROL:
      return {
        ...state,
        searchKey: action.payload.searchKey,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        division: action.payload.division
      };
    case REDUX_ACTIONS.CHECKINS_DRILL_SET_FILTER:
      return {
        ...state,
        filters: action.payload
      };
    case REDUX_ACTIONS.CHECKINS_DRILL_SET_SORT:
      return {
        ...state,
        sort: action.payload
      };
    case REDUX_ACTIONS.CHECKINS_RIGS_LIST_LOADING:
      return {
        ...state,
        checkInsRigs: {
          ...state.checkInsRigs,
          loading: true,
          errorMessage: ''
        }
      };
    case REDUX_ACTIONS.CHECKINS_RIGS_LIST_SUCCESS:
      return {
        ...state,
        checkInsRigs: {
          ...state.checkInsRigs,
          loading: false,
          data: [...action.payload.data],
          paginatedElements: action.payload.paginatedElements,
          errorMessage: ''
        }
      };
    case REDUX_ACTIONS.CHECKINS_RIGS_LIST_ERROR:
      return {
        ...state,
        checkInsRigs: {
          ...state.checkInsRigs,
          loading: false,
          errorMessage: action.payload.message,
          data: []
        }
      };

    default:
      return state;
  }
};

export default checkInsDrillReducer;
