// src/hooks/useAspectRatio.js
import { useDispatch } from 'react-redux';
import { REDUX_ACTIONS } from '../shared/config/constants';

const useAspectRatio = () => {
  const dispatch = useDispatch();

  const handleAspectRatio = (aspectRatio) => {
    let aspectRatioPayload = { maxWidth: 'lg', style: {} };
    if (aspectRatio) {
      aspectRatioPayload = { maxWidth: 'xl', style: { padding: 0 } };
    }
    dispatch({
      type: REDUX_ACTIONS.SET_ASPECT_RATIO_PAGE,
      payload: { aspect: aspectRatioPayload, status: aspectRatio },
    });
  };

  return handleAspectRatio;
};

export default useAspectRatio;
