import React, { useEffect, useState } from 'react';
import CanvasJSReact from './canvasjs.react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { getDateInfo } from '../../../utils/dateInfo';
import { kFormatter } from '../../../utils/numbers';
import styles from './styles';

const CanvasChart = CanvasJSReact.CanvasJSChart;

let yAxisInterval = 600000; // set to 10 mins in milliseconds by default

const CanvasJSChart = ({
  data,
  rangeChanging,
  viewportMinimum,
  viewportMaximum,
  setPresetDate,
  height,
  chartRef,
  yAxisLabelsToHide,
  stackedView,
}) => {
  const classes = styles();
  const { datachartSettings } = useSelector((state) => state.currentUser.preferences);
  const [chartData, setChartData] = useState({
    nDataSource: [],
    yAxis: []
  });
  const [isVertical, setIsVertical] = useState(true);
  const options = {
    theme: 'light2',
    animationEnabled: true,
    zoomEnabled: true,
    exportEnabled: true,
    toolbar: {
      itemBackgroundColor: '#d3d3d3',
      itemBackgroundColorOnHover: '#3e3e3e',
      buttonBorderColor: '#3e3e3e',
    },
    backgroundColor: '#f0f0f0',
    axisX: {
      viewportMinimum: 0,
      viewportMaximum: 0,
    },
    axisY: {
      viewportMinimum: null,
      viewportMaximum: null,
    },
    rangeChanged: function(e) {
      if (stackedView === 'vertical') {
        if (e.chart.axisY[0].range < 600000) {
          yAxisInterval = null;
          e.chart.axisY[0].set('interval', null);
        } else {
          yAxisInterval = 600000;
          e.chart.axisY[0].set('interval', yAxisInterval);
        }
      }
    },
  };

  const generateChartData = () => {
    const nDataSource = [];
    const yAxis = [];
    data.forEach((item) =>
      Object.keys(item)
        .filter((key) => key !== 'timestamp')
        .forEach((key, i) => {
          const hideThisLabel = yAxisLabelsToHide.includes(key);
          let existingItem = nDataSource.find(
            (datapoint) => datapoint.name === key
          );
          if (!existingItem) {
            existingItem = {
              name: key,
              type: 'line',
              axisYIndex: i,
              showInLegend: false,
              color: `#${
                datachartSettings.columnsColorDefinition[
                  key
                ] ?? '000'
              }`,
              click: setPresetDate,
              dataPoints: [],
            };
            nDataSource.push(existingItem);
            const objYAxis = {
              labelFontColor: `#${
                datachartSettings.columnsColorDefinition[
                  key
                ] ?? 'hsla(0, 0%, 75%)'
              }`,
              labelFontSize: 16,
              labelPlacement: 'outside',
              minimum: null,
              maximum: null,
              tickLength: hideThisLabel ? 0 : 8,
              reversed: !isVertical && key.toUpperCase().includes('DEPT'),
              labelFormatter: (e) => {
                if (hideThisLabel) {
                  return '';
                }
                return e.value.toFixed(2);
              }
            };

            if (
              datachartSettings.columnsRange &&
              datachartSettings.columnsRange[key]
            ) {
              if (
                datachartSettings.columnsRange[key].min
              ) {
                objYAxis.minimum =
                  datachartSettings.columnsRange[key].min;
              }
              if (
                datachartSettings.columnsRange[key].max
              ) {
                objYAxis.maximum =
                datachartSettings.columnsRange[key].max;
              }
            }
            yAxis.push(objYAxis);
          }

          if (isVertical) {
            existingItem.dataPoints.push({
              y: (new Date(item.timestamp)).getTime(),
              x: item[key],
            });
          } else {
            existingItem.dataPoints.push({
              x: new Date(item.timestamp),
              y: item[key],
            });
          }
        })
    );

    setChartData({
      nDataSource,
      yAxis
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      generateChartData();
    }, 100);
    return () => clearTimeout(timer);
  }, [data, isVertical, yAxisLabelsToHide]);

  useEffect(() => {
    if (stackedView !== 'vertical') {
      setIsVertical(false);
    } else {
      setIsVertical(true);
    }
  }, [stackedView]);

  if (rangeChanging) {
    options.rangeChanging = rangeChanging;
  }

  if (viewportMinimum && viewportMaximum) {
    options.axisX.viewportMinimum = viewportMinimum;
    options.axisX.viewportMaximum = viewportMaximum;

    options.axisY.viewportMinimum = viewportMinimum;
    options.axisY.viewportMaximum = viewportMaximum;
  }

  if (height) {
    options.height = height;
  }

  if (!chartData?.yAxis[0] || !chartData?.nDataSource[0]) return <div />;

  if (stackedView === 'vertical') {
    return (
      <Box className={classes.chartContainer}>
          {chartData?.nDataSource.map((chart, index) => {
            return (
                  <CanvasChart
                  key={`v${chart.name}`}
                  containerProps={{ minWidth: '250px', minHeight: '800px' }}
                  options={{
                    ...options,
                    data: [chart],
                    zoomType: 'y',
                    axisY: {
                      ...options.axisY,
                      reversed: true,
                      labelFontSize: 16,
                      labelPlacement: 'outside',
                      labelMaxWidth: 150,
                      labelWrap: false,
                      interval: yAxisInterval,
                      minimum: chart.dataPoints[0].y,
                      maximum: chart.dataPoints[chart.dataPoints.length - 1].y,
                      labelFormatter: function (e) {
                        if (index !== 0) {
                          return '';
                        }
                        return (new Date(e.value)).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
                      },
                    },
                    toolTip: {
                      contentFormatter: function (e) {
                        return getDateInfo(new Date(e.entries[0].dataPoint.y), '-')?.formattedDayTime + ' : ' + e.entries[0].dataPoint.x;
                      },
                    },
                    axisX: {
                      ...chartData?.yAxis[index],
                      labelFontSize: 14,
                      labelPlacement: 'outside',
                      labelFormatter: function (e) {
                        return kFormatter(e.value.toFixed(2));
                      },
                    },
                    height: 800
                  }} />
            );
          })}
      </Box>
    );
  }

  if (stackedView === 'horizontal') {
    return (
      <Box>
          {chartData?.nDataSource.map((chart, index) => {
            return (
                  <CanvasChart
                  key={`h${chart.name}`}
                  options={{
                    ...options,
                    data: [chart],
                    axisY: [{ ...chartData?.yAxis[index], labelPlacement: 'outside' }],
                    axisX: {
                      ...options.axisX,
                      labelFontSize: 18,
                      labelPlacement: 'outside',
                      labelFormatter: (e) => {
                        if (index !== chartData?.nDataSource.length - 1) {
                          return '';
                        }
                        return e.value.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                      }
                    },
                    height: 250,
                  }} />
            );
          })}
      </Box>
    );
  }

  return <CanvasChart ref={chartRef} options={{ ...options, data: chartData?.nDataSource, axisY: chartData?.yAxis }} />;
};

CanvasJSChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  yAxisLabelsToHide: PropTypes.arrayOf(PropTypes.string),
  rangeChanging: PropTypes.func,
  viewportMinimum: PropTypes.number,
  viewportMaximum: PropTypes.number,
  setPresetDate: PropTypes.func,
  height: PropTypes.number,
  chartRef: PropTypes.object,
  stackedView: PropTypes.any,
  columnsVisibilityChange: PropTypes.any
};

CanvasJSChart.defaultProps = {
  data: [],
  yAxisLabelsToHide: [],
  rangeChanging: () => {},
  setPresetDate: () => {},
  stackedView: null
};

export default React.memo(CanvasJSChart);
