import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../ModalDialog';
import Datagrid from '../Datagrid';
import { Grid, gridClasses, IconButton, Typography } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { DeleteRounded, ContentCopy, PrintRounded, CancelPresentationRounded, ShoppingCartCheckoutRounded } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../hooks/useAuth';
import { listQuoteParts, postQuoteParts, listQuotes, downloadQuotePDF, removeFromGrayList, addToGrayList } from '../../../store/actions/quotes';
import { isFullArray } from '../../../utils/validators';
import { renderCell } from '../../ui/Datagrid/table_config';
import DescriptionImageModalDialog from '../DescriptionImageModalDialog';
import columns from '../OrdersCartModalDialog/table_config';
import { cartDialogSort } from '../../../utils/cartDialogSort';
import SliderOrderConfirmModalDialog from '../SliderOrderConfirmModalDialog';
import OrderProcessingModalDialog from '../OrderProcessingModalDialog';

const QuotesCartModalDialog = ({
  isOpen,
  partIDs,
  quoteData,
  onCloseClick,
  onActionClick,
  saveButtonText,
}) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { loading, grayPartsDetails, quotePartsPostSuccess } = useSelector(
    (state) => state.quotes
  );
  const [cart, setCart] = useState([]);
  const [cartPrice, setCartPrice] = useState(0);
  const apiRef = useGridApiRef([]);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [partImage, setPartImage] = useState({
    open: false,
    data: {}
  });
  const [dialogTitle, setDialogTitle] = useState(null);
  const quotes = useSelector((state) => state.quotes);
  const [sliderModal, setSliderModal] = useState(false);

  const handleDownloadQuotePDF = (quoteId) => {
    dispatch(downloadQuotePDF(quoteId));
  };

  useEffect(() => {
    if (quoteData && Object.keys(quoteData)?.length) {
      setDialogTitle(getDialougeTitleWithPrint(quoteData));
    }
  }, [quotes.printState]);

  const getDialougeTitleWithPrint = ({ orderNumber, timestamp, quantity, price }) => {
    return (
      <>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            { `Date: ${timestamp}, Quote Id: ${orderNumber}, Quantity: ${quantity}, Total Price: $${price.toFixed(2)}`}
          </Grid>
          <Grid item>
            <IconButton
              variant="solid"
              onClick={() => {
                handleDownloadQuotePDF(orderNumber);
              }}
              disabled={quotes.printState === 'loading'}
            >
              <PrintRounded fontSize='large' />
            </IconButton>
            <IconButton
              onClick={() => {
                handleQuoteButtonClick();
              }}
            >
              <ShoppingCartCheckoutRounded fontSize='large'/>
            </IconButton>
            <IconButton
              onClick={() => {
                onCloseClick();
              }}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(listQuoteParts(user.companyId, partIDs));
      if (saveButtonText) {
        setDialogTitle(getDialougeTitleWithPrint(quoteData));
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (quotePartsPostSuccess) {
      onCloseClick();
      setIsProcessing(false);
    }
  }, [quotePartsPostSuccess]);

  useEffect(() => {
    if (isFullArray(grayPartsDetails)) {
      const cartDetails = partIDs
        .map((id) => grayPartsDetails.find((part) => part._id === id))
        .filter((part) => !!part);
      const sortedCart = cartDetails.sort(cartDialogSort);
      setCart(sortedCart);
      const cartTotalPrice = cartDetails
        ?.map((i) => +i?.Price)
        .reduce((sum, num) => sum + num, 0);
      setCartPrice(cartTotalPrice.toFixed(2));
    } else {
      setCart([]);
    }
  }, [grayPartsDetails]);

  const handleQuoteButtonClick = (e) => {
    setSliderModal(true);
    onActionClick(e);
  };

  const placeOrder = () => {
    dispatch(postQuoteParts(user, cart));
    setSliderModal(false);
    setIsProcessing(true);
    setLoadingDialog(true);
  };

  const closeLoadingDialog = () => {
    setLoadingDialog(false);
    onCloseClick();
    dispatch(listQuotes(user));
  };

  const getModalTitle = () => {
    const cartQuantity = cart?.length;
    return (
      <>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {`Quantity: ${cartQuantity}, Total Price: $${cartPrice}`}
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                handleQuoteButtonClick();
              }}
            >
              <ShoppingCartCheckoutRounded fontSize='large' />
            </IconButton>
            <IconButton
              onClick={() => {
                onCloseClick();
              }}
            >
              <CancelPresentationRounded fontSize='large' />
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const createDialogTitle = (quoteData, cart, cartPrice) => {
    return (
      <>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
          {`Date: ${quoteData.timestamp}, Order Id: ${quoteData.orderNumber}, Quantity: ${cart.length}, Total Price: $${cartPrice}`}
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                handleQuoteButtonClick();
              }}
            >
              <ShoppingCartCheckoutRounded fontSize='large' />
            </IconButton>
            <IconButton
              onClick={() => {
                onCloseClick();
              }}
            >
              <CancelPresentationRounded fontSize='large' />
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const removeRow = (row, id) => {
    apiRef.current.selectRow(id, true);
    const allRows = Array.from(apiRef.current.getRowModels());
    const currentCart = apiRef.current.getAllRowIds();

    const newList = [];
    for (const key of apiRef.current.getSelectedRows().keys()) {
      const deleteRow = currentCart.filter((row) => row === key);
      newList.push(deleteRow[0]);
    }

    const filtered = allRows.filter((x) => {
      return !newList.includes(x[0]);
    });

    const newCart = [];
    for (const row of filtered) {
      newCart.push(row[1]);
    }
    setCart(newCart);
    const cartTotalPrice = newCart
      ?.map((i) => +i?.Price)
      .reduce((sum, num) => sum + num, 0);
    const sortedCart = newCart.sort(cartDialogSort);
    setCart(sortedCart);
    setCartPrice(cartTotalPrice.toFixed(2));
    dispatch(removeFromGrayList(row._id));
    if (saveButtonText) {
      setDialogTitle(createDialogTitle(quoteData, newCart, cartTotalPrice.toFixed(2)));
    }
    if (sortedCart.length === 0) {
      onCloseClick();
    }
  };

  const duplicateRow = (row, id) => {
    apiRef.current.selectRow(id, true);
    const newCart = [...cart];
    for (const row of apiRef.current.getSelectedRows().values()) {
      newCart.push(row);
    }

    const cartTotalPrice = newCart
      ?.map((i) => +i?.Price)
      .reduce((sum, num) => sum + num, 0);
    const sortedCart = newCart.sort(cartDialogSort);
    setCart(sortedCart);
    dispatch(addToGrayList(row._id));
    setCartPrice(cartTotalPrice.toFixed(2));
    if (saveButtonText) {
      setDialogTitle(createDialogTitle(quoteData, newCart, cartTotalPrice.toFixed(2)));
    }
  };

  const renderActions = ({
    id,
    row
  }) => {
    return (
      <Grid container justifyContent="center">
        <IconButton
        variant="solid"
        onClick={() => removeRow(row, id)}
      >
        <DeleteRounded />
      </IconButton>
        <IconButton
          variant="solid"
          onClick={() => duplicateRow(row, id)}
        >
          <ContentCopy/>
        </IconButton>
      </Grid>
    );
  };

  const renderDescriptionCell = ({ value, row }) => (
    <Typography
      onClick={() => setPartImage({
        open: true, data: row
      })}
      style={{ fontSize: '14px', fontWeight: 'bold', textWrap: 'wrap', cursor: 'pointer' }}
    >{value}
    </Typography>
  );

  if (apiRef.current === null) apiRef.current = {};

  return (
    !loading && (
      <>
        <ModalDialog
          title={dialogTitle || getModalTitle()}
          isOpen={isOpen}
          onClose={onCloseClick}
        >
          <Grid>
            <Datagrid
              loading={loading}
              data={cart}
              columns={columns(renderCell, user.type, renderActions, renderDescriptionCell)}
              apiRef={apiRef}
              getRowId={(row) => `${Math.random(1000)}_${row._id}`}
              getRowHeight={() => 'auto'}
              pageSize={100}
              disableStickyHeader={false}
              autoHeight={false}
              height={'60vh'}
              sx={{
                '.MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold !important',
                },
                [`& .${gridClasses.cell}`]: {
                  py: 1,
                },
                '.cartModalCell': {
                  fontSize: '16px',
                  fontWeight: 'bold'
                }
              }}
              toolbar={{
                options: {
                  columns: false,
                  filters: true,
                  density: false,
                  export: false,
                },
              }}
            />
          </Grid>
        </ModalDialog>
        {partImage.open &&
          <DescriptionImageModalDialog
          data={partImage.data}
          image={partImage.data.Image}
          isOpen={partImage.open}
          onClose={() => setPartImage({
            open: false,
            data: {}
          })}
          />
        }
        <OrderProcessingModalDialog
          isOpen={loadingDialog}
          isProcessing={isProcessing}
          closeLoadingDialog={closeLoadingDialog}
          />
        {sliderModal && (
          <SliderOrderConfirmModalDialog
            title={'Quote'}
            placeOrder={placeOrder}
            isOpen={sliderModal}
            setSliderModal={setSliderModal}
          />
        )}
      </>
    )
  );
};

QuotesCartModalDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  partIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  quoteData: PropTypes.any,
  onCloseClick: PropTypes.func.isRequired,
  onActionClick: PropTypes.func.isRequired,
  saveButtonText: PropTypes.string,
};

export default QuotesCartModalDialog;
