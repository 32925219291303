import * as React from 'react';
import {
  Avatar,
  Card,
  CardHeader,
  CardMedia,
  Grid,
} from '@mui/material';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import styles from './styles';
import { green } from '@mui/material/colors';
import { useEffect } from 'react';
import { useTheme } from '@mui/styles';
import { isMobile } from '../../../../utils/isMobile';
import { DEFAULT_IMG } from '../../../../shared/config/constants';

const FeaturedCard = function ({
  description,
  pn,
  category,
  myimage,
  name,
  isSelected = false,
  loading = false
}) {
  const [isDescriptionHidden, setDescriptionHidden] = React.useState(true);
  const classes = styles();
  const theme = useTheme();
  const mobile = isMobile();

  const handleClick = () => {
    setDescriptionHidden((state) => {
      if (isSelected) return false;
      else return !state;
    });
  };

  useEffect(() => {
    setDescriptionHidden(!isSelected);
  }, [isSelected]);

  const imageHandler = (img, pn) => {
    if (pn && img.includes('placeholder.png')) {
      const imageName = img.substring(0, img.lastIndexOf('/') + 1);
      return `${imageName}${pn}.png`;
    }
    return img;
  };

  return (
    <Card
      component="li"
      sx={{ minWidth: mobile ? '75vw' : 345, flexGrow: 1, margin: mobile ? 0 : 'inherit', padding: 0 }}
      className={classes.card}
      key={`part_card_${pn}`}
      elevation={isSelected ? 15 : 1}
    >
      <CardHeader
        avatar={
          loading
            ? (
              <Skeleton animation="wave" variant="circular" width={45} height={45} />
              )
            : (
              <Avatar sx={{ bgcolor: green[500] }} aria-label="recipe">
                A
              </Avatar>
              )
        }
        title={ loading
          ? (
            <>
              <Skeleton
                animation="wave"
                height={30}
                width="90%"
              />
              <Skeleton
                animation="wave"
                height={20}
                width="50%"
                style={{ marginBottom: 6 }}
              />
            </>
            )
          : (
          `${name} - ${pn}`
            )}
        subheader={category}
        titleTypographyProps={{ variant: 'h6', noWrap: true }}
        style={{
          textAlign: 'start'
        }}
      />
       {loading
         ? (
        <Skeleton sx={{ height: 320, width: 'auto', m: theme.spacing(2) }} animation="wave" variant="rectangular" />
           )
         : (
        <CardMedia
        component="img"
        width="auto"
        height="350px"
        image={imageHandler(myimage, pn)}
        alt={pn}
        onTouchStart={() => {
          setDescriptionHidden((value) => !value);
        }}
        onMouseEnter={(e) => {
          e.stopPropagation();
          if (mobile) {
            return;
          }
          setDescriptionHidden(() => false);
        }}
        onMouseLeave={(e) => {
          e.stopPropagation();
          if (mobile) {
            return;
          }
          setDescriptionHidden(() => !isSelected ?? true);
        }}
        className={classes.media}
        sx={{ objectFit: 'contain', p: theme.spacing(2) }}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = DEFAULT_IMG;
        }}
      />
           )}
      <Grid
        onMouseEnter={() => setDescriptionHidden(false)}
        onMouseLeave={handleClick}
        className={classes.overlay}
        hidden={isDescriptionHidden && !isSelected}
      >
        {description}
      </Grid>
    </Card>
  );
};

FeaturedCard.propTypes = {
  myimage: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  pn: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  loading: PropTypes.bool
};

export default FeaturedCard;
