import { REDUX_ACTIONS } from '../../../shared/config/constants';

const initialState = {
  loading: false,
  deleting: false,
  data: [],
  errorMessage: '',
  loadingDelete: false,
  saved: false,
  paginatedElements: 0,
  executeSearch: false,
};

const epicorPartReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.EPICOR_PARTS_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: ''
      };
    case REDUX_ACTIONS.EPICOR_PARTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        executeSearch: false,
        data: [...action.payload.epicorParts],
        paginatedElements: action.payload.paginatedElements,
        errorMessage: ''
      };
    case REDUX_ACTIONS.EPICOR_PARTS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        executeSearch: false,
        errorMessage: action.payload.message,
        data: []
      };
    case REDUX_ACTIONS.EPICOR_PARTS_LIST_ERROR_NOTIFIED:
      return {
        ...state,
        errorMessage: '',
        data: []
      };
    case REDUX_ACTIONS.EPICOR_PARTS_SEARCH:
      return {
        ...state,
        executeSearch: true,
      };
    case REDUX_ACTIONS.EPICOR_PARTS_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default epicorPartReducer;
