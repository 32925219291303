import { REDUX_ACTIONS } from '../../../shared/config/constants';

const initialState = {
  loading: false,
  deleting: false,
  data: [],
  errorMessage: '',
  loadingDelete: false,
  saved: false,
  paginatedElements: 0,
  executeSearch: false,
  saleOrdersPosting: false,
  saleOrdersPostSuccess: false,
  saleOrdersPostError: false,
  saleOrdersPostErrorMessage: '',
};

const epicorSaleOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.EPICOR_SALE_ORDERS_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case REDUX_ACTIONS.EPICOR_SALE_ORDERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        executeSearch: false,
        data: [...action.payload.epicorSaleOrders],
        paginatedElements: action.payload.paginatedElements,
        errorMessage: '',
      };
    case REDUX_ACTIONS.EPICOR_SALE_ORDERS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        executeSearch: false,
        errorMessage: action.payload.message,
        data: [],
      };
    case REDUX_ACTIONS.EPICOR_SALE_ORDERS_LIST_ERROR_NOTIFIED:
      return {
        ...state,
        errorMessage: '',
        data: [],
      };
    case REDUX_ACTIONS.EPICOR_SALE_ORDERS_SEARCH:
      return {
        ...state,
        executeSearch: true,
      };
    case REDUX_ACTIONS.EPICOR_SALE_ORDERS_POSTING:
      return {
        ...state,
        saleOrdersPosting: true,
        saleOrdersPostSuccess: false,
        saleOrdersPostError: false,
      };

    case REDUX_ACTIONS.EPICOR_SALE_ORDERS_POST_SUCCESS:
      return {
        ...state,
        saleOrdersPosting: false,
        saleOrdersPostSuccess: true,
        saleOrdersPostError: false,
        newOrderNum: action.payload.newOrderNum,
        salesOrdersCreated: action.payload.salesOrdersCreated,
      };
    case REDUX_ACTIONS.EPICOR_SALE_ORDERS_POST_ERROR:
      return {
        ...state,
        saleOrdersPosting: false,
        saleOrdersPostSuccess: false,
        saleOrdersPostError: true,
        saleOrdersPostErrorMessage: action.payload.message,
      };
    case REDUX_ACTIONS.EPICOR_SALE_ORDERS_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default epicorSaleOrdersReducer;
