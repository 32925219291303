export const parseError = async (error) => {
  let message = '';
  if (error.status === 500) {
    message = error.statusText;
  } else if (error.json) {
    const jsonObject = await error.json().then((result) => result);
    message = jsonObject.message;
  } else if (!error.status) {
    return 'network error';
  }
  return message;
};

export const parseEpicorError = async (error) => {
  let message = '';
  if (error.json) {
    const jsonObject = await error.json().then((result) => result);
    message = jsonObject.details.ErrorMessage || 'Internal Server Error';
  } else {
    return 'network error';
  }
  return message;
};
