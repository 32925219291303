/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import ModalDialog from '../../ModalDialog';
import styles from '../styles';
import { Grid, IconButton } from '@mui/material';
import Datagrid from '../../Datagrid';
import PropTypes from 'prop-types';
import { CancelPresentationRounded, DeleteRounded, EditOutlined, LogoutOutlined, SaveRounded } from '@mui/icons-material';
import columns from './table_config';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { addNewHydraulicsDetailsRow, createHydraulicsDetails, deleteHydraulicsDetails, listHydraulicsDetails, runHydraulics, updateHydraulicProperty, updateHydraulicsDetails } from '../../../../store/actions/hydraulics';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from '../../../../store/actions/snackbar';
import SelectEditInputTextField from '../../SelectEditInputTextField';
import { HYDRAULICS_RUN_DETAILS } from '../../../../utils/constants';
import CuttingPropertyModal from '../CuttingPropertyModal';
import DeleteConfirmationModal from '../../../deleteConfirmationModal';
import SelectDrillDetails from '../../SelectDrillDetails';

const HydraulicsDetailsModal = ({
  onCloseClick,
  selectedJob,
  isOpen,
  data
}) => {
  const classes = styles();
  const apiRef = useGridApiRef();
  const hydraulics = useSelector((state) => state.hydraulics);
  const dispatch = useDispatch();
  const [properties, setProperties] = useState([]);
  const [invalidRows, setInvalidRows] = useState({});
  const [showCuttingPropertyModal, setShowCuttingPropertyModal] = useState(false);
  const [deleteAction, setDeleteAction] = useState(false);
  const [deleteRow, setDeleteRow] = useState();
  const [hydraulicsExitRunIcon, setHydraulicsExitRunIcon] = useState(true);

  useEffect(() => {
    if (!hydraulics.details.loading && !hydraulics?.drillDetails?.loading) {
      const { data: runDetails = [] } = hydraulics.details;
      const { drillStrings = [], drillFluids = [] } = hydraulics?.drillDetails?.hydraulicsDrillDetails || {};

      if (runDetails.length) {
        const [firstRunDetail] = runDetails;
        const checkForDeletedDrillStringId = drillStrings.some(drillString => drillString._id === firstRunDetail.drillStringsId);
        const checkForDeletedDrillFluidId = drillFluids.some(drillString => drillString._id === firstRunDetail.drillFluidsId);
        if (checkForDeletedDrillStringId && checkForDeletedDrillFluidId) {
          setHydraulicsExitRunIcon(false);
        } else {
          setHydraulicsExitRunIcon(true);
        }
        const drillString =
          drillStrings.find(item => item._id === firstRunDetail.drillStringsId) || drillStrings[0];
        firstRunDetail.drillStringsId = drillString
          ? `${drillString.name.trim()} @ ${drillString.MD}ft`
          : '';

        const drillFluid =
          drillFluids.find(item => item._id === firstRunDetail.drillFluidsId) || drillFluids[0];
        firstRunDetail.drillFluidsId = drillFluid
          ? `${drillFluid.name.trim()} @ ${drillFluid.MD}ft`
          : '';
        firstRunDetail.bit_depth_offset = firstRunDetail?.bit_depth_offset ? firstRunDetail?.bit_depth_offset : 0.0;
        setProperties(runDetails);
      } else {
        addHydraulicsDetails();
      }
    }
  }, [hydraulics]);

  useEffect(() => {
    if (properties[0]?.isNewRow === true) {
      const id = properties[0]._id;
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: 0
        });
        apiRef.current.setCellMode(id, 'hole_depth', 'edit');
        setTimeout(() => {
          apiRef.current.setCellFocus(id, 'hole_depth');
        }, 50);
      }, 50);
      apiRef.current.setPage(0);
    }
  }, [properties?.length]);

  const addHydraulicsDetails = () => {
    dispatch(addNewHydraulicsDetailsRow());
  };

  const removeHydraulicsDetails = (row) => {
    const filteredProperties = properties.filter((item) => item._id !== row._id);
    const updatedHydraulicsData = {
      ...data,
      _id: hydraulics?.hydraulicsId ? hydraulics?.hydraulicsId : data?._id,
      run_details: filteredProperties
    };
    dispatch(updateHydraulicProperty(updatedHydraulicsData));
    setProperties(filteredProperties);
    if (!row?.isNewRow) {
      dispatch(deleteHydraulicsDetails(hydraulics?.hydraulicsId ? hydraulics?.hydraulicsId : data?._id));
    } else {
      dispatch(listHydraulicsDetails(hydraulics?.hydraulicsId ? hydraulics?.hydraulicsId : data?._id));
    }
    setDeleteAction(false);
    setDeleteRow({});
  };

  const showError = (message, rowId) => {
    dispatch(enqueueSnackbar(message, 'error'));
    setInvalidRows(prev => ({ ...prev, [rowId]: true }));
    return false;
  };

  const validateRunDetails = (runDetails) => {
    const { bit_depth_offset, flow_in, hole_depth, sbp, rop, _id } = runDetails;
    if (bit_depth_offset < -hole_depth || bit_depth_offset > 0) return showError(`Bit Off-Bottom Offset should be in the range from ${-hole_depth} to 0`, _id);
    if (hole_depth < 0) return showError(HYDRAULICS_RUN_DETAILS.HOLE_DEPTH, _id);
    if (rop < 0) return showError(HYDRAULICS_RUN_DETAILS.ROP, _id);
    if (sbp < 0) return showError(HYDRAULICS_RUN_DETAILS.SBP, _id);
    if (flow_in < 0) return showError(HYDRAULICS_RUN_DETAILS.FLOW_IN, _id);
    return true;
  };

  const checkHydraulicsValues = (value) => {
    const row = value;
    const invalidFields = [];
    const isInvalidNumber = (val) => {
      // eslint-disable-next-line no-mixed-operators
      return isNaN(val) || val === '' || typeof val === 'string' && val.trim() === '';
    };

    if (row?.hole_depth === undefined || row?.hole_depth === null || isInvalidNumber(row.hole_depth)) {
      invalidFields.push('HOLE_DEPTH');
    }
    if (row?.bit_depth_offset === undefined || row?.bit_depth_offset === null || isInvalidNumber(row.bit_depth_offset)) {
      invalidFields.push('BIT OFF-BOTTOM OFFSET');
    }
    if (row?.rop === undefined || row?.rop === null || isInvalidNumber(row.rop)) {
      invalidFields.push('ROP');
    }
    if (row?.flow_in === undefined || row?.flow_in === null || isInvalidNumber(row.flow_in)) {
      invalidFields.push('FLOW_RATE');
    }
    if (row?.sbp === undefined || row?.sbp === null || isInvalidNumber(row.sbp)) {
      invalidFields.push('SBP');
    }

    if (invalidFields.length === 0) {
      setInvalidRows(prev => ({ ...prev, [row._id]: false }));
      return validateRunDetails(row);
    } else {
      setInvalidRows(prev => ({ ...prev, [row._id]: true }));
      dispatch(
        enqueueSnackbar(
          `Please enter valid numeric values for: ${invalidFields.join(', ')}`,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return false;
    }
  };

  const saveProperty = (row) => {
    if (checkHydraulicsValues(row)) {
      const validationError = '';
      if (validationError === '') {
        const body = {
          hole_depth: Number(row.hole_depth),
          bit_depth_offset: Number(row.bit_depth_offset),
          rop: Number(row.rop),
          flow_in: Number(row.flow_in),
          sbp: Number(row.sbp),
          drillStringsId: hydraulics?.drillDetails?.hydraulicsDrillDetails?.drillStrings.filter((item) => `${item?.name?.trim()} @ ${item?.MD}ft` === row?.drillStringsId)[0]._id,
          drillFluidsId: hydraulics?.drillDetails?.hydraulicsDrillDetails?.drillFluids.filter((item) => `${item?.name?.trim()} @ ${item?.MD}ft` === row?.drillFluidsId)[0]._id,
          cuttings_data: row.cuttings_data
        };

        if (row?.isNewRow) {
          dispatch(createHydraulicsDetails(data?._id ? data?._id : hydraulics.hydraulicsId, body));
        } else {
          dispatch(updateHydraulicsDetails(data?._id ? data?._id : hydraulics.hydraulicsId, body));
        }

        const updatedData = {
          ...data,
          _id: hydraulics?.hydraulicsId ? hydraulics?.hydraulicsId : data?._id,
          run_details: { ...data.run_details, ...body }
        };

        dispatch(updateHydraulicProperty(updatedData));
      } else {
        if (validationError.length) {
          setInvalidRows(prev => ({ ...prev, [row._id]: true }));
        }
        dispatch(
          enqueueSnackbar(
            validationError,
            'error',
            new Date().getTime() + Math.random()
          )
        );
      }
    }
  };

  const runHydraulicsProxy = (hydraulicsRunID) => {
    const body = {
      query: selectedJob?.fromWells
        ? {
            wellsInfoId: selectedJob?.wellsInfoId
          }
        : {
            jobId: selectedJob?._id
          },
      runId: hydraulicsRunID
    };
    dispatch(runHydraulics(body, () => {
      onCloseClick();
    }));
  };

  const getModalTitle = () => {
    return (
      <>
      <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {`${selectedJob?.Rig} - ${data?.name}` || ''}
          </Grid>
          <Grid item>
          <IconButton
              onClick={() => {
                runHydraulicsProxy(data?._id);
              }}
              disabled={hydraulics?.details?.data[0]?.isNewRow || hydraulicsExitRunIcon}
            >
              <LogoutOutlined fontSize='large'/>
            </IconButton>
          <IconButton
              onClick={() => {
                onCloseClick();
              }}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const updateData = (id, field, value) => {
    setProperties(prevProperties => {
      const updatedProperties = prevProperties.map(item => {
        if (item._id === id) {
          return {
            ...item,
            [field]: (value)
          };
        }
        return item;
      });
      return updatedProperties;
    });
  };

  const renderDrillstringCell = (params) => {
    return (
      <SelectDrillDetails
        {...params}
        closeCellAfterSelect={true}
        updateData={updateData}
        data={hydraulics?.drillDetails?.hydraulicsDrillDetails?.drillStrings}
      />
    );
  };

  const renderDrillFluidCell = (params) => {
    return (
      <SelectDrillDetails
        {...params}
        closeCellAfterSelect={true}
        updateData={updateData}
        data={hydraulics?.drillDetails?.hydraulicsDrillDetails?.drillFluids}
      />
    );
  };

  const renderValueCell = (params) => {
    return <SelectEditInputTextField numberFormatOnly {...params} updateData={updateData} />;
  };

  const renderEditAction = () => {
    return (
      <Grid container justifyContent="center">
       <IconButton
          variant="solid"
          disabled={hydraulics?.details?.data[0]?.isNewRow}
          onClick={() => {
            setShowCuttingPropertyModal(true);
          }}
        >
          <EditOutlined/>
        </IconButton>
      </Grid>
    );
  };

  const renderActionCell = ({ row }) => {
    return (
      <Grid container justifyContent="center">
       <IconButton
          variant="solid"
          disabled={hydraulics.details.loading}
          onClick={() => {
            setTimeout(() => {
              saveProperty(row);
            }, 100);
          }}
        >
          <SaveRounded />
        </IconButton>
        <IconButton
          variant="solid"
          disabled={hydraulics.details.loading || row?.isNewRow}
          onClick={() => {
            setDeleteAction(true);
            setDeleteRow(row);
          }}
        >
            <DeleteRounded />
        </IconButton>
      </Grid>
    );
  };

  return (
    <>
    <ModalDialog
    title={getModalTitle()}
    isOpen={isOpen}
    onClose={onCloseClick}
    dialogClass={classes.dialogModal}
    maxWidth={'lg'}
  >
    <Grid container>
    <Grid item className={classes.customTool} xs={1} justifyContent="space-between" >
        </Grid>
      <Grid item xs={12}>
        <Datagrid
          apiRef={apiRef}
          data={properties}
          sx={{
            marginLeft: '-6px',
            '.MuiDataGrid-cell': {
              fontSize: '1rem',
              fontWeight: 900
            },
            '.MuiDataGrid-columnHeader': {
              fontSize: '1.2rem',
              fontWeight: '900 !important'
            }
          }}
          loading={hydraulics.details.loading || hydraulics?.drillDetails?.loading}
          columns={columns(renderValueCell, renderDrillstringCell, renderDrillFluidCell, renderActionCell, renderEditAction)}
          onRowClick={(params, evt) => {
            evt.preventDefault();
            evt.stopPropagation();
          }}
          disableStickyHeader={false}
          autoHeight={false}
          autoheight
          height={'50vh'}
          getRowClassName={(params) => {
            return invalidRows[params.id] ? classes.invalidRow : '';
          }}
          pageSize={100}
          editMode="cell"
          toolbar={{
            options: {
              columns: false,
              filters: false,
              density: false,
              export: false
            },
          }}
        />
      </Grid>
    </Grid>
    {
    deleteAction && <DeleteConfirmationModal
      open={deleteAction}
      handleDelete={removeHydraulicsDetails}
      handleClose={setDeleteAction}
      deleteRow={deleteRow}
    />
  }
  </ModalDialog>
    {showCuttingPropertyModal
      ? <CuttingPropertyModal
      data={data}
      selectedJob={selectedJob}
      isOpen={showCuttingPropertyModal}
      onCloseClick={() => {
        setShowCuttingPropertyModal(false);
      }}
    />
      : null
  }
  </>
  );
};
HydraulicsDetailsModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  selectedJob: PropTypes.object,
  data: PropTypes.any,
  isOpen: PropTypes.bool.isRequired
};
HydraulicsDetailsModal.defaultProps = {
  selectedJob: {}
};

export default HydraulicsDetailsModal;
