import fetch from '../../api/fetch';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { parseError } from '../../utils/fetchErrorParser';
import store from '../store';
import { enqueueSnackbar } from './snackbar';

export const listCheckInsDrill = (payload) => (dispatch) => {
  const { checkInsDrill } = store.getState();
  dispatch({
    type: REDUX_ACTIONS.CHECKINS_DRILL_LIST_LOADING,
  });
  const body = {
    query: {
      searchValue: payload?.search ? payload?.search : checkInsDrill?.searchKey || '',
      startDate: payload?.selectedStartDateTime ? payload?.selectedStartDateTime : checkInsDrill?.startDate || '',
      endDate: payload?.selectedEndDateTime ? payload?.selectedEndDateTime : checkInsDrill?.endDate || '',
      filters: checkInsDrill?.filters?.items || [],
      limit: 100,
      page: payload?.page || 1,
      sort: checkInsDrill?.sort || null,
      linkOperator: checkInsDrill?.filters?.linkOperator || '',
    }
  };
  return fetch
    .postWithUTC('checkins/fetch-drill', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.CHECKINS_DRILL_LIST_SUCCESS,
        payload: {
          data: data.data,
          paginatedElements: data.count,
          totalCount: data.count
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.CHECKINS_DRILL_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};

export const listCheckInsRigs = (sRig) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.CHECKINS_RIGS_LIST_LOADING,
  });
  const body = {
    query: {
      sRig,
    }
  };
  return fetch
    .postWithUTC('checkins/rigs', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.CHECKINS_RIGS_LIST_SUCCESS,
        payload: {
          data,
          paginatedElements: data.count,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.RIGS_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};
