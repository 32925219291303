import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';

const styles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      justifyItems: 'end',
      width: '235px',
      marginRight: '7px',
      marginLeft: '14px'
    },
    logo: {
      width: '60%',
      margin: 'auto',
    },
    dropArea: {
      border: '2px dashed #ccc',
      borderRadius: '10px',
      padding: '20px',
      margin: '20px 0',
      width: '600px',
      textAlign: 'center'
    },
    dragDrop: {
      border: '2px dashed #ccc',
      borderRadius: '10px',
      padding: '20px',
      margin: '20px 0',
      width: '600px',
      textAlign: 'center',
      backgroundColor: grey[300]
    },
    errorMessage: {
      color: 'red',
      fontWeight: 900
    },
    selectedFileMessage: {
      color: 'green',
      fontWeight: 900
    },
    fileDescription: {
      marginTop: '2px'
    }
  };
});

export default styles;
