import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  map: {
    height: '50vh',
    border: '5px solid white'
  },
  noActionPin: {
    cursor: 'initial',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  title: {
    textAlign: 'center',
    width: '600px',
  },
  logo: {
    width: '70%',
    margin: 'auto',
  },
  statusMessage: {
    textAlign: 'center',
  },
  rigIconPopup: {
    opacity: '0.2'
  }
}));

export default styles;
