import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => {
  return {
    logo: {
      height: 233,
      width: '50vh',
      border: 'none',
      display: 'flex',
      margin: '0 auto'
    },
    mobile_logo: {
      height: 'auto',
      width: '60vw',
      border: 'none',
      display: 'flex',
      margin: '0 auto'
    }
  };
});

export default styles;
