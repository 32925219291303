import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import {
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import image014 from '../../assets/images/placeholder.png';
import RCD from '../../assets/images/T-3000-RCD-Section-landing.png';
import mpdBackground from '../../assets/images/mpd_background.png';
import { PrintRounded } from '@mui/icons-material';
import { useSelector } from 'react-redux';

const images = [image014, mpdBackground, RCD];

const JobsCustomCard = ({ getReports, reportEndDate, reportStartDate, fieldName, header, itemId, disablePrint }) => {
  const classes = styles();
  const theme = useTheme();
  const [isDescriptionHidden, setDescriptionHidden] = React.useState(true);
  const [disableIcon, setDisableIcon] = useState(false);
  const actuatorReport = useSelector((state) => state.actuatorReport);

  let placeholderImage = '';
  if (header === 'MPD Utilization') {
    placeholderImage = images.find((element) => element.includes('mpd'));
  } else if (header === 'RCD (Bearing Assy)') {
    placeholderImage = images.find((element) => element.includes('RCD'));
  } else {
    placeholderImage = images.find((element) =>
      element.includes('placeholder'));
  }

  useEffect(() => {
    if (reportEndDate && reportStartDate) {
      const startDate = new Date(reportStartDate);
      const endDate = new Date(reportEndDate);
      const differenceInTime = endDate.getTime() - startDate.getTime();
      const differenceInDays = differenceInTime / (1000 * 3600 * 24 + (60 * 1000));
      if (differenceInDays > 7 || endDate > new Date()) {
        setDisableIcon(true);
      } else {
        setDisableIcon(false);
      }
    }
  }, [reportEndDate, reportStartDate]);

  return (
    <Card
      sx={{ maxWidth: 500, flexGrow: 1 }}
      className={classes.card}
      key={`filter_card_${header}`}
      onMouseEnter={() => setDescriptionHidden(() => false)}
      onMouseLeave={() => setDescriptionHidden(() => true)}
    >
      <Grid item xs={12} className={classes.cardHeaderConatiner}>
      <CardHeader
        title={header}
        titleTypographyProps={{ variant: 'h6' }}
      />
      <IconButton onClick={() => {
        getReports(itemId);
      }}
      disabled={!reportEndDate || !reportStartDate || reportEndDate < reportStartDate || disablePrint || disableIcon || actuatorReport?.actuator_reportLoading}
      >
          <PrintRounded fontSize='large'/>
      </IconButton>
      </Grid>
      <CardMedia
        component="img"
        width="90px"
        height="350px"
        image={placeholderImage}
        className={classes.media}
        sx={{ objectFit: 'contain', p: theme.spacing(2) }}
      />
      <Grid className={classes.overlay} hidden={isDescriptionHidden}>
          <Typography key={`field_${itemId}`} variant="h6">{fieldName}</Typography>
      </Grid>
      <CardActions className={classes.footer} />
    </Card>
  );
};

JobsCustomCard.propTypes = {
  item: PropTypes.object,
  getReports: PropTypes.func,
  reportEndDate: PropTypes.any,
  reportStartDate: PropTypes.any,
  header: PropTypes.any,
  fieldName: PropTypes.any,
  itemId: PropTypes.any,
  disablePrint: PropTypes.any
};

export default JobsCustomCard;
