import { DirectionsRunRounded, AspectRatioRounded } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useAspectRatio from '../../../../hooks/useAspectRatio';
import { REDUX_ACTIONS } from '../../../../shared/config/constants';

const EpicorControls = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const handleAspectRatio = useAspectRatio();
  const { preferences } = useSelector((state) => state.currentUser);

  const handleButtonClick = () => {
    switch (location.pathname) {
      case '/Epicor/Customers':
        dispatch({ type: REDUX_ACTIONS.EPICOR_CUSTOMERS_SEARCH });
        break;
      case '/Epicor/Parts':
        dispatch({ type: REDUX_ACTIONS.EPICOR_PARTS_SEARCH });
        break;
      case '/Epicor/PriceLists':
        dispatch({ type: REDUX_ACTIONS.EPICOR_PRICE_LISTS_SEARCH });
        break;
      case '/Epicor/SaleOrders':
        dispatch({ type: REDUX_ACTIONS.EPICOR_SALE_ORDERS_SEARCH });
        break;
      case '/Epicor/Workforce':
        dispatch({ type: REDUX_ACTIONS.EPICOR_WORK_FORCE_SEARCH });
        break;
      case '/Epicor/Bins':
        dispatch({ type: REDUX_ACTIONS.EPICOR_BINS_SEARCH });
        break;
      default:
        break;
    }
  };

  return (
    <Box
    sx={{
      width: 'fit-content',
      display: 'flex',
      gap: '24px',
      marginLeft: 'auto'
    }}
    >
      <Box display="flex">
        <Button color="graypart" onClick={handleButtonClick}>
          <DirectionsRunRounded />
        </Button>
        <IconButton
          edge="end"
          onClick={() => {
            handleAspectRatio(!preferences.aspectChanged);
          }}
        >
          <AspectRatioRounded />
        </IconButton>
      </Box>
    </Box>
  );
};

export default EpicorControls;
