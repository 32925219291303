import { WebAssetOffRounded } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import React from 'react';

const NotFound = () => {
  return (
    <Grid container justifyContent="center" justifyItems="center">
      <Grid item xs={3} textAlign="center">
        <Typography variant="h6" fontWeight="bolder">Page Not Found</Typography>
        <WebAssetOffRounded fontSize="large" />
      </Grid>
    </Grid>
  );
};

export default NotFound;
