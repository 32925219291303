/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import { Box, CircularProgress, Grid, InputBase, Typography } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import Checkbox from '@mui/material/Checkbox';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import React, { useRef, useState } from 'react';
import Zoom from '@mui/material/Zoom';
import styles from './styles';
import { useDispatch } from 'react-redux';
import { validateEmail } from '../../../utils/validateEmail';
import { MKTsubscribe, verifyCaptcha } from '../../../store/actions/marketplace';
import { isMobile } from 'react-device-detect';
import ReCAPTCHA from 'react-google-recaptcha';
import { GOOGLE_RECAPTCHA_SITE_KEY } from '../../../shared/config/constants';
import ButtonComponent from '../../Button';

const SignUpComponent = ({ subscribed, setSubscribed }) => {
  const classes = styles();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [check, setCheck] = useState(false);
  const [error, setError] = useState({
    email: '',
    checkbox: '',
    captcha: ''
  });

  const [captcha, setCaptcha] = useState(null);
  const reCaptchaRef = useRef(null);

  const handleChange = (value) => {
    setCaptcha(value);
    if (value) {
      setError(prev => ({
        ...prev,
        captcha: ''
      }));
    }
  };

  const validateInputs = () => {
    let isValid = true;
    const newErrors = {};

    if (!email || !validateEmail(email)) {
      isValid = false;
      newErrors.email = 'Please enter a valid email address';
    } else {
      newErrors.email = '';
    }

    if (!check) {
      isValid = false;
      newErrors.checkbox = 'Please check this required field';
    } else {
      newErrors.checkbox = '';
    }

    if (!captcha && reCaptchaRef.current) {
      isValid = false;
      newErrors.captcha = 'Please verify reCAPTCHA';
    } else {
      newErrors.captcha = '';
    }

    setError(prev => ({
      ...prev,
      ...newErrors
    }));

    return isValid;
  };

  const captchaVerificationSuccess = () => {
    const body = {
      emailAddress: email,
      emailOptIn: true
    };
    dispatch(MKTsubscribe(body, setSubscribed, setError));
  };

  const handleSignUp = () => {
    if (validateInputs()) {
      setSubscribed({
        status: false,
        loading: true
      });
      // verify captcha before subscribe
      dispatch(verifyCaptcha(captcha, captchaVerificationSuccess, setSubscribed, setError));
    }
  };

  return (
        <>
            <Grid className={classes.searchBar} container alignItems='center'>
                <Box sx={{ flex: 1 }}>
                    <InputBase
                        fullWidth
                        className={classes.inputBase}
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.currentTarget.value);
                        }}
                        inputProps={{ 'aria-hidden': false }}
                        disabled={subscribed.loading}
                    />
                    {error.email && (<Typography className={classes.errorMessage}>{error.email}</Typography>)}
                </Box>
            </Grid>
            <Grid display='flex' justifyContent='center' alignItems='center' sx={{ position: 'relative', marginTop: isMobile ? 4 : 0, marginBottom: 4 }}>
                <Checkbox
                    checked={check}
                    onChange={(e) => setCheck(e.target.checked)}
                    size="large"
                    sx={{
                      color: '#404040',
                      '&.Mui-checked': {
                        color: '#404040',
                      },
                    }}
                    disabled={subscribed.loading}
                />
                <Typography className={classes.checkboxText}>I want to receive emails containing product updates and special offers
                    {error.checkbox && (<><br></br><span style={{ top: isMobile ? 45 : 30, display: 'block' }} className={classes.errorMessage}>{error.checkbox}</span></>)}
                </Typography>
            </Grid>
            <Grid container justifyContent='center' sx={{ pb: error.captcha && 5, position: 'relative' }}>
              {error.captcha && (
                <Typography sx={{ position: 'absolute', bottom: 0 }} className={classes.errorMessage}>{error.captcha}</Typography>
              )}
              <ReCAPTCHA
                theme="light"
                ref={reCaptchaRef}
                sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                onChange={handleChange}
              />
            </Grid>
            {subscribed.loading
              ? (
                    <CircularProgress sx={{ color: '#303030' }} size={24} />
                )
              : (
                <ButtonComponent size="extraLarge" onClick={handleSignUp} className={classes.button} label="Sign Up"/>
                )}
        </>
  );
};

function Subscribe() {
  const classes = styles();
  const [subscribed, setSubscribed] = useState({
    status: false,
    loading: false
  });

  return (
      <Box className={classes.subscribeWrapper}>
          {subscribed.status
            ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Zoom in={subscribed.status} {...(subscribed.status ? { timeout: 500 } : {})}>
                          <Box>
                              <Zoom in={subscribed.status} {...(subscribed.status ? { timeout: 1500 } : {})}>
                                  <TaskAltIcon sx={{ fontSize: 150 }} />
                              </Zoom>
                              <Typography variant='h4' style={{ marginTop: '50px' }} className={classes.subscribeTitle}>You're in.</Typography>
                              <Typography variant='h5' className={classes.subscribeDesc}>Check your inbox soon.</Typography>
                          </Box>
                      </Zoom>
                  </Box>
              )
            : (
                  <>
                      <MailIcon sx={{ fontSize: 50 }} />
                      <Typography variant='h4' className={classes.subscribeTitle}>Join Our List</Typography>
                      <Typography variant='h5' className={classes.subscribeDesc}>Don't miss out. Stay up to date on the latest products, deals and more.</Typography>
                      <SignUpComponent setSubscribed={setSubscribed} subscribed={subscribed} />
                  </>
              )}
      </Box>
  );
}

export default Subscribe;
