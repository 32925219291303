import React, { useEffect, useRef, useState } from 'react';
import { getAssetData, getChartPreferences, updateChartPreferences } from '../../../store/actions/assetData';
import { useDispatch, useSelector } from 'react-redux';
import { ASSET_DATA_TYPES, REDUX_ACTIONS } from '../../../shared/config/constants';
import { useLocation } from 'react-router-dom';
import MobileCanvasJS from '../ui/canvasjs';
import styles from './styles';
import { generateChartData } from '../utils/generateChartData';
import { generateDefaultActuatorColumns } from '../utils/generateDefaultActuatorData';
import { generateDefaultDrillingColumns } from '../utils/generateDefaultDrillingData';
import { DEVICE_CLASSIFICATION } from '../../../utils/constants';
import { listJobs } from '../../../store/actions/jobs';

function AssetDataMobile() {
  const dispatch = useDispatch();
  const classes = styles();
  const { assetData } = useSelector((state) => state);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [assetDataType] = useState(
    searchParams.get('assetDataType') ?? ASSET_DATA_TYPES.ACTUATORDATA
  );
  const [data, setData] = useState({});
  const assetDataId = assetDataType.replace('ActuatorData_', '').replace('DrillingData_', '');
  const { preferences, userId } = useSelector((state) => state.currentUser);
  const [filterId] = useState(searchParams.get('filterId') ?? '2');
  const latestRecordRef = useRef([]);
  const chartsMounted = useRef(null);
  const [loadingPreferences, setLoadingPreferences] = useState(true);

  useEffect(() => {
    if (preferences.datachartSettings?.datakeys) {
      setLoadingPreferences(false);
    }
    const timer = setTimeout(() => {
      const body = {
        input: {
          userId,
          jobId: assetDataId,
          platform: DEVICE_CLASSIFICATION.MOBILE,
          filterId: Number(filterId)
        }
      };
      if (!chartsMounted.current && loadingPreferences && !preferences.datachartSettings?.datakeys) {
        dispatch(getChartPreferences(body, () => setLoadingPreferences(false)));
      } else if (chartsMounted.current && !loadingPreferences) {
        body.input.preferences = {
          datachartSettings: preferences.datachartSettings
        };
        dispatch(updateChartPreferences(body));
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [
    preferences.datachartSettings.datakeys,
    preferences.datachartSettings.columnsColorDefinition,
    preferences.datachartSettings.columnsRange
  ]);

  function _data() {
    let arr = [];
    for (let i = 0; i < assetData.data.length; ++i) {
      const item = {
        timestamp: new Date(assetData.data[i].timestamp).getTime(),
      };

      preferences.datachartSettings.datakeys?.forEach(
        (datakey) => (item[datakey] = parseFloat(assetData.data[i][datakey]))
      );

      arr.push(item);
    }
    const { nDataSource, yAxis } = generateChartData(arr, preferences.datachartSettings, []);
    setData({
      nDataSource,
      yAxis
    });
    arr = null;
  }

  useEffect(() => {
    dispatch(listJobs());
    if (!assetData.data.length) {
      dispatch(getAssetData(
        assetDataType,
        { preset: assetData.dateRange.preset },
      ));
    }
  }, []);

  useEffect(() => {
    if (!assetData.loading) {
      if (assetData.data.length) {
        if (
          Object.keys(preferences.datachartSettings.columnVisibilityModel)
            .length > 0 && Object.keys(preferences.datachartSettings.columnsColorDefinition)
            .length > 0
        ) {
          return;
        }
        const { columnsInitialState, columnsColorDefinition, datakeys } = assetDataType.split('_')[0].toLocaleLowerCase() === 'drillingdata' ? generateDefaultDrillingColumns(assetData.data[0]) : generateDefaultActuatorColumns(assetData.data[0]);
        dispatch({
          type: REDUX_ACTIONS.SET_DATA_CHART_PREFERENCES,
          payload: {
            columnsInitialState,
            columnVisibilityModel: columnsInitialState,
            columnsColorDefinition,
            datakeys
          },
          filters: {
            assetDataId,
            filterId
          }
        });
      }
    }
  }, [assetData.loading]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (assetData.data.length) {
        const latest = assetData.data.slice(assetData.data.length - 1);
        latestRecordRef.current = latest;
        _data();
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [
    assetData.data,
    preferences.datachartSettings.datakeys,
    preferences.datachartSettings.columnsColorDefinition,
    preferences.datachartSettings.columnsRange,
    preferences.datachartSettings.viewportMinimum,
  ]);

  useEffect(() => {
    if (data?.nDataSource?.length > 0) {
      const pageTitle = searchParams.get('pageTitle');
      if (pageTitle) {
        dispatch({
          type: REDUX_ACTIONS.MENU_SET_TITLE,
          payload: pageTitle,
        });
      }
      const interval = setInterval(() => {
        if (!assetData.dateRange.paused) {
          dispatch(
            getAssetData(
              assetDataType,
              {
                preset: assetData.dateRange.preset,
                end: latestRecordRef.current[0].timestamp,
              },
              false,
              true
            )
          );
        }
      }, 15000);

      return () => {
        clearInterval(interval);
        dispatch({
          type: REDUX_ACTIONS.MENU_SET_TITLE,
          payload: '',
        });
      };
    }
  }, [data, assetData.dateRange.paused]);

  useEffect(() => {
    if (data?.nDataSource?.length && !chartsMounted.current) {
      setTimeout(() => {
        chartsMounted.current = true;
      }, 1000);
    }
  }, [data.nDataSource]);

  const rangeChanging = (e) => {
    dispatch({
      type: REDUX_ACTIONS.SET_DATA_CHART_PREFERENCES,
      payload: {
        viewportMinimum: e.axisY[0].viewportMinimum,
        viewportMaximum: e.axisY[0].viewportMaximum,
      },
      filters: {
        assetDataId,
        filterId
      }
    });
  };

  if (!data?.nDataSource) return null;

  return (
    <div className={classes.assetDataMobileContainer}>
      <MobileCanvasJS
        nDataSource={data?.nDataSource}
        yAxis={data?.yAxis}
        rangeChanging={rangeChanging}
        viewportMinimum={
          preferences.datachartSettings.viewportMinimum
        }
        viewportMaximum={
          preferences.datachartSettings.viewportMaximum
        }
      />
    </div>
  );
}

export default AssetDataMobile;
