import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import styles from './styles';
import FeaturedCard from '../../ui/marketplace/FeaturedCard';
import { listMarketplaceFeatured, marketplaceTrackActions } from '../../../store/actions/marketplace';
import { MKT_ACTIONS } from '../../../utils/constants';

const Featured = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const featuredList = useSelector((state) => state.marketplace.featuredList);
  const scrollContainerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  useEffect(() => {
    dispatch(marketplaceTrackActions(MKT_ACTIONS.PAGE_LOADED));
    dispatch(listMarketplaceFeatured());
  }, []);

  useEffect(() => {
    if (featuredList.length) {
      const container = scrollContainerRef.current;

      const updateScrollButtons = () => {
        if (container) {
          setCanScrollLeft(container.scrollLeft > 0);
          setCanScrollRight(container.scrollLeft + container.clientWidth < container.scrollWidth - 80);
        }
      };

      const handleScroll = () => {
        updateScrollButtons();
      };

      if (container) {
        updateScrollButtons(); // Initial check
        container.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (container) {
          container.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [featuredList]);

  const scrollLeft = () => {
    const container = scrollContainerRef.current;
    if (container) {
      container.scrollTo({
        left: container.scrollLeft - 320,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    const container = scrollContainerRef.current;
    if (container) {
      container.scrollTo({
        left: container.scrollLeft + 320,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Box className={classes.featuredWrapper}>
    <Typography variant='h3' className={classes.marketSubtitle}>Featured Products</Typography>
        <Box ref={scrollContainerRef} component="ul" className={classes.carouselContainerGridView}>
          {featuredList?.map((item) => {
            return (
              <FeaturedCard
                key={`part-card-${item._id}`}
                myimage={item.Image}
                pn={item.PN}
                description={item.Description}
                category={item.Category}
                name={item.Name}
                classification={item.Classification}
                price={item.Price}
                itemId={item._id}
              />
            );
          })}
        </Box>
        <IconButton
            onClick={scrollLeft}
            disabled={!canScrollLeft}
            className={clsx(classes.navIcons, classes.leftIcon)}
            sx={{ visibility: !canScrollLeft && 'hidden' }}>
            <NavigateBefore fontSize='large' />
          </IconButton>
          <IconButton
            onClick={scrollRight}
            disabled={!canScrollRight}
            className={clsx(classes.navIcons, classes.rightIcon)}
            sx={{ visibility: !canScrollRight && 'hidden' }}>
            <NavigateNext fontSize='large' />
          </IconButton>
    </Box>
  );
};

export default Featured;
