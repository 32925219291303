import CurrencyFormat from 'react-currency-format';

export const renderCustomPrice = ({ value }) => (
  <strong>
    <CurrencyFormat
      value={value?.toString()}
      decimalScale={2}
      fixedDecimalScale={true}
      displayType={'text'}
      thousandSeparator={true}
      prefix={'$'}
    />
  </strong>
);
