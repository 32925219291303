import * as React from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  Tooltip,
  Popover,
  TextareaAutosize,
  Typography,
  Box,
  Avatar,
} from '@mui/material';
import Price from '../../Price';
import PropTypes from 'prop-types';
import { CheckCircle as Check, Add, Remove, MailOutlineRounded, SendRounded, CloseRounded, Favorite, FavoriteBorder } from '@mui/icons-material';
import styles from './styles';
import classNames from 'classnames';
import { PARTS_CLASSIFICATION, USER_TYPE } from '../../../../utils/constants';
import { addToGreenList, removeFromGreenList } from '../../../../store/actions/orders';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../../hooks/useAuth';
import { mailPart, tooglePartClassification, updateFavoritesList } from '../../../../store/actions/parts';
import { addToGrayList, removeFromGrayList } from '../../../../store/actions/quotes';
import { useEffect } from 'react';
import { useTheme } from '@mui/styles';
import { useSearchParams } from 'react-router-dom';
import { validateEmail } from '../../../../utils/validateEmail';
import { enqueueSnackbar } from '../../../../store/actions/snackbar';
import { isMobile } from '../../../../utils/isMobile';
import { DEFAULT_IMG } from '../../../../shared/config/constants';
import { green } from '@mui/material/colors';

const favoritePermissions = [USER_TYPE.CUSTOMER_USER, USER_TYPE.CUSTOMER_RIG];

const Index = function ({
  classification,
  description,
  pn,
  price,
  category,
  myimage,
  name,
  isSelected = false,
  itemId,
  setMobileClassification,
  handleClassificationChange,
}) {
  const [isDescriptionHidden, setDescriptionHidden] = React.useState(true);
  const classes = styles();
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders);
  const quotes = useSelector((state) => state.quotes);
  const parts = useSelector((state) => state.parts);
  const { user } = useAuth();
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [emailAddresses, setEmailAddresses] = React.useState('');
  const mobile = isMobile();
  const hideControls = Number(price) <= 0;
  const isFavorite = parts?.partsFavorites?.includes(itemId);

  const handleClick = () => {
    setDescriptionHidden((state) => {
      if (isSelected) return false;
      else return !state;
    });
  };
  const handleOnMail = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setEmailAddresses('');
    setAnchorEl(null);
  };

  const validateEmailAddress = (emailAddress) => {
    let isValid = true;
    const emailList = emailAddress.split(' ');
    if (!emailList.every(validateEmail) || emailList.length < 0) {
      dispatch(
        enqueueSnackbar(
          'Please Check Email Address',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      isValid = false;
    }
    return isValid;
  };

  const handleEmailAddition = (e) => {
    const Emails = e.target.value.replace(/\n/g, ' ').trim();
    const emailAddress = Emails.split(' ').filter(item => item !== '').join(' ');
    setEmailAddresses(emailAddress);
  };

  const handleOnSubmit = () => {
    const partObject = parts.data.find(item => item._id === itemId);
    if (validateEmailAddress(emailAddresses) && partObject) {
      dispatch(mailPart(emailAddresses, partObject));
      handleClose();
    }
  };

  const handleAddToCart = () => {
    if (classification === PARTS_CLASSIFICATION.GRAY) {
      dispatch(addToGrayList(itemId));
    } else {
      dispatch(addToGreenList(itemId));
    }
  };
  const handleRemoveFromCart = () => {
    if (classification === PARTS_CLASSIFICATION.GRAY) {
      dispatch(removeFromGrayList(itemId));
    } else {
      dispatch(removeFromGreenList(itemId));
    }
  };
  const handleToogleGreenPart = () => {
    let newClassification = PARTS_CLASSIFICATION.UNCLASSIFIED;
    if (
      classification === PARTS_CLASSIFICATION.UNCLASSIFIED ||
      classification === PARTS_CLASSIFICATION.GRAY
    ) {
      newClassification = PARTS_CLASSIFICATION.GREEN;
    }
    const companyId = searchParams.get('companyId') ? searchParams.get('companyId') : user.companyId;
    handleClassificationChange(itemId, newClassification);
    dispatch(tooglePartClassification(companyId, itemId, newClassification));
    if (setMobileClassification) {
      setMobileClassification(itemId, newClassification);
    }
  };

  const handleToogleGrayPart = () => {
    let newClassification = PARTS_CLASSIFICATION.UNCLASSIFIED;
    if (
      classification === PARTS_CLASSIFICATION.UNCLASSIFIED ||
      classification === PARTS_CLASSIFICATION.GREEN
    ) {
      newClassification = PARTS_CLASSIFICATION.GRAY;
    }
    const companyId = searchParams.get('companyId') ? searchParams.get('companyId') : user.companyId;
    handleClassificationChange(itemId, newClassification);
    dispatch(tooglePartClassification(companyId, itemId, newClassification));
    if (setMobileClassification) {
      setMobileClassification(itemId, newClassification);
    }
  };

  useEffect(() => {
    setDescriptionHidden(!isSelected);
  }, [isSelected]);

  const imageHandler = (img, pn) => {
    if (pn && img.includes('placeholder.png')) {
      const imageName = img.substring(0, img.lastIndexOf('/') + 1);
      return `${imageName}${pn}.png`;
    }
    return img;
  };

  if (parts.showFavorites && !isFavorite) return null;

  return (
    <Card
      component="li"
      sx={{ maxWidth: mobile ? '90%' : 345, flexGrow: 1, margin: mobile ? 0 : 'inherit', padding: mobile ? 0 : 'inherit' }}
      className={classes.card}
      key={`part_card_${pn}`}
      elevation={isSelected ? 15 : 1}
    >
      <CardHeader
        avatar={favoritePermissions.includes(user.type)
          ? (
          <IconButton
          onClick={() => {
            dispatch(updateFavoritesList(itemId, isFavorite ? 'remove' : 'add'));
          }}
          sx={{ p: 0, m: 0 }}
          aria-label="favorite"
          >
            {isFavorite ? <Favorite fontSize='large' /> : <FavoriteBorder fontSize='large' />}
          </IconButton>
            )
          : (
          <Avatar sx={{ bgcolor: green[500] }} aria-label="avatar">
            A
          </Avatar>
            )}
        title={`${name} - ${pn}`}
        subheader={category}
        titleTypographyProps={{ variant: 'h6', noWrap: true }}
      />
      <CardMedia
        component="img"
        width="auto"
        height="350px"
        image={imageHandler(myimage, pn)}
        alt={pn}
        onTouchStart={() => {
          setDescriptionHidden((value) => !value);
        }}
        onMouseEnter={(e) => {
          e.stopPropagation();
          if (mobile) {
            return;
          }
          setDescriptionHidden(() => false);
        }}
        onMouseLeave={(e) => {
          e.stopPropagation();
          if (mobile) {
            return;
          }
          setDescriptionHidden(() => !isSelected ?? true);
        }}
        className={classes.media}
        sx={{ objectFit: 'contain', p: theme.spacing(2) }}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = DEFAULT_IMG;
        }}
      />
      <Grid
        onMouseEnter={() => setDescriptionHidden(false)}
        onMouseLeave={handleClick}
        className={classes.overlay}
        hidden={isDescriptionHidden && !isSelected}
      >
        {description}
      </Grid>
      <CardContent>
        {hideControls ? <Box sx={{ height: 30 }} /> : <Price mount={price} fixedDecimalScale={true} />}
      </CardContent>
      <CardActions disableSpacing className={classes.cardActionsMain}>
        <Grid container alignItems="center">
          <Grid item xs={6} alignItems="center" sx={{ display: hideControls && 'none' }}>
            {
              /* prettier-ignore */
              (user.type === USER_TYPE.CUSTOMER_USER || user.type === USER_TYPE.CUSTOMER_RIG) &&
              (classification === PARTS_CLASSIFICATION.GREEN
                ? (
                    <Check
                      color="greenpart"
                      className={classNames(classes.classificationIcon)}
                    />
                  )
                : (
                      <Check
                      color="graypart"
                      className={classNames(classes.classificationIcon)}
                    />
                  )
              )
            }
            {(user.type === USER_TYPE.CUSTOMER_MANAGER || user.type === USER_TYPE.CUSTOMER_ADMIN || user.type === USER_TYPE.SELLER_MARKETPLACE_ADMIN || user.type === USER_TYPE.PREMIUM_ADMIN || user.type === USER_TYPE.PREMIUM_MARKETPLACE_ADMIN) && (
              <>
                <IconButton
                  aria-label="add green part to list"
                  color={
                    classification &&
                    classification === PARTS_CLASSIFICATION.GREEN
                      ? 'greenpart'
                      : 'noclassification'
                  }
                  onClick={() => handleToogleGreenPart()}
                  disabled={
                    quotes?.grayList?.includes(itemId) ||
                    orders?.greenList?.includes(itemId)
                  }
                >
                  <Check />
                </IconButton>

                <IconButton
                  disabled={
                    quotes?.grayList?.includes(itemId) ||
                    orders?.greenList?.includes(itemId)
                  }
                  aria-label="add gray part to list"
                  color={
                    classification &&
                    classification === PARTS_CLASSIFICATION.GRAY
                      ? 'graypart'
                      : 'noclassification'
                  }
                  onClick={() => handleToogleGrayPart()}
                >
                  <Check />
                </IconButton>
              </>
            )}
          </Grid>
          <Grid item xs={!hideControls ? 6 : 12} className={classes.cardActionsB} alignItems="center">
            {user && user.type !== USER_TYPE.PREMIUM_ADMIN && user.type !== USER_TYPE.SELLER_MARKETPLACE_ADMIN && user.type !== USER_TYPE.PREMIUM_MARKETPLACE_ADMIN && user.type !== USER_TYPE.CUSTOMER_MANAGER && user.type !== USER_TYPE.CUSTOMER_ADMIN && !hideControls && (
              <Tooltip disableTouchListener title="Add to Shopping cart">
                <span>
                  <Button
                    className={classes.shoppingButon}
                    variant="contained"
                    aria-label="add to shopping cart"
                    onClick={handleAddToCart}
                    size="small"
                    placeholder="Add to Shopping cart"
                    disabled={
                      classification === PARTS_CLASSIFICATION.UNCLASSIFIED
                    }
                  >
                    <Add />
                  </Button>
                </span>
              </Tooltip>
            )}
            {user && user.type !== USER_TYPE.PREMIUM_ADMIN && user.type !== USER_TYPE.SELLER_MARKETPLACE_ADMIN && user.type !== USER_TYPE.PREMIUM_MARKETPLACE_ADMIN && user.type !== USER_TYPE.CUSTOMER_MANAGER && user.type !== USER_TYPE.CUSTOMER_ADMIN && !hideControls && (
              <Tooltip disableTouchListener title="Remove from Shopping cart">
                <span>
                  <Button
                    className={classes.shoppingButon}
                    variant="contained"
                    aria-label="remove from shopping cart"
                    onClick={handleRemoveFromCart}
                    size="small"
                    placeholder="Remove from Shopping cart"
                    disabled={
                      ((classification === PARTS_CLASSIFICATION.UNCLASSIFIED ||
                        classification === PARTS_CLASSIFICATION.GREEN) &&
                        !orders?.greenList?.includes(itemId)) ||
                      ((classification === PARTS_CLASSIFICATION.UNCLASSIFIED ||
                        classification === PARTS_CLASSIFICATION.GRAY) &&
                        !quotes?.grayList?.includes(itemId))
                    }
                  >
                    <Remove />
                  </Button>
                </span>
              </Tooltip>
            )}
            <IconButton onClick={handleOnMail}>
              <MailOutlineRounded fontSize='large' />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Typography sx={{ p: 1, textAlign: 'end' }}>
                <TextareaAutosize
                  onChange={handleEmailAddition}
                  aria-label="minimum height"
                  minRows={5}
                  style={{ width: '400px', maxWidth: '100%' }}
                  placeholder="Email address"
                  autoFocus
                />
                <IconButton
                  sx={{ marginRight: mobile ? '6px' : 0 }}
                  size={mobile ? 'large' : 'medium'}
                  edge="end"
                  onClick={handleOnSubmit}>
                  <SendRounded />
                </IconButton>
                <IconButton
                  size={mobile ? 'large' : 'medium'}
                  edge="end"
                  onClick={handleClose}>
                  <CloseRounded />
                </IconButton>
              </Typography>
            </Popover>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

Index.propTypes = {
  myimage: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  pn: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  classification: PropTypes.any.isRequired,
  price: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  itemId: PropTypes.string.isRequired,
  setMobileClassification: PropTypes.func,
  handleClassificationChange: PropTypes.func.isRequired,
};

export default Index;
