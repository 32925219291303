import { REDUX_ACTIONS } from '../../shared/config/constants';

const initialState = {
  loading: false,
  deleting: false,
  data: [],
  grayList: [],
  grayPartsDetails: [],
  errorMessage: '',
  loadingDelete: false,
  saved: false,
  paginatedElements: 0,
  quotePartsPosting: false,
  quotePartsPostSuccess: false,
  quotePartsPostError: false,
  quotePartsPostErrorMessage: '',
  printState: 'idle', // idle, error, loading
};

const quotesReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.QUOTES_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case REDUX_ACTIONS.QUOTES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...action.payload.quotes],
        paginatedElements: action.payload.paginatedElements,
        errorMessage: '',
      };
    case REDUX_ACTIONS.QUOTES_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: [],
      };
    case REDUX_ACTIONS.QUOTES_LIST_ERROR_NOTIFIED:
      return {
        ...state,
        errorMessage: '',
        data: [],
      };
    case REDUX_ACTIONS.ORDERS_SET_GRAY_LIST:
      return {
        ...state,
        grayList: action.payload,
      };
    case REDUX_ACTIONS.ORDERS_SET_GRAY_ITEM_NOT_FOUND:
      return {
        ...state,
        isGrayItemNotFound: action.payload,
      };
    case REDUX_ACTIONS.QUOTES_GRAY_PARTS_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case REDUX_ACTIONS.QUOTES_SET_GRAY_PARTS_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        grayPartsDetails: [...action.payload.parts],
        errorMessage: '',
      };
    case REDUX_ACTIONS.QUOTES_GRAY_PARTS_ERROR_NOTIFIED:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        grayPartsDetails: [],
      };
    case REDUX_ACTIONS.QUOTES_RESET:
      return {
        ...initialState,
        grayList: [],
      };
    case REDUX_ACTIONS.QUOTE_PARTS_POSTING:
      return {
        ...state,
        quotePartsPosting: true,
        quotePartsPostSuccess: false,
        quotePartsPostError: false,
      };
    case REDUX_ACTIONS.QUOTE_PARTS_POST_SUCCESS:
      return {
        ...state,
        quotePartsPosting: false,
        quotePartsPostSuccess: true,
        quotePartsPostError: false,
        grayList: [],
      };
    case REDUX_ACTIONS.QUOTE_PARTS_POST_ERROR:
      return {
        ...state,
        quotePartsPosting: false,
        quotePartsPostSuccess: false,
        quotePartsPostError: true,
        quotePartsPostErrorMessage: action.payload.message,
      };
    case REDUX_ACTIONS.DOWNLOAD_QUOTE_IDLE:
      return {
        ...state,
        printState: 'idle',
      };
    case REDUX_ACTIONS.DOWNLOAD_QUOTE_LOADING:
      return {
        ...state,
        printState: 'loading',
      };
    case REDUX_ACTIONS.DOWNLOAD_QUOTE_ERROR:
      return {
        ...state,
        printState: 'error',
      };
    default:
      return state;
  }
};

export default quotesReducer;
