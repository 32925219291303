import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FormControl, Grid, IconButton, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const MobileAuthentication = ({
  setEmailAddress,
  password,
  setPassword,
  showPassword,
  handleClickShowPassword,
  handleMouseDownPassword,
}) => {
  const user = useSelector((state) => state.currentUser);

  return (
    <Grid item xs={12} padding={1}>
              <Typography style={{ backgroundColor: '#f0f0f0', borderRadius: '8px', marginBottom: '4px' }} color="error">{user.errorMessage}</Typography>
              <FormControl
                fullWidth
                variant="filled"
                onChange={(value) => {
                  setEmailAddress(value.target.value);
                }}>
                <OutlinedInput
                  size="medium"
                  style={{
                    backgroundColor: '#f0f0f0',
                    borderRadius: '8px',
                  }}
                  id="outlined-adornment-email"
                  type="email"
                  placeholder='Email Address'
                  autoFocus
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent !important',
                    },
                  }}
                  />
              </FormControl>
              <FormControl
                style={{ marginTop: '5px' }}
                fullWidth
                variant="filled"
                value={password}
                onChange={(value) => {
                  setPassword(value.target.value);
                }}
              >
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder='Password'
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent !important',
                    },
                  }}
                  style={{
                    backgroundColor: '#f0f0f0',
                    borderRadius: '8px',
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </Grid>
  );
};

MobileAuthentication.propTypes = {
  password: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
  setEmailAddress: PropTypes.func.isRequired,
  showPassword: PropTypes.bool.isRequired,
  handleClickShowPassword: PropTypes.func.isRequired,
  handleMouseDownPassword: PropTypes.func.isRequired
};

export default MobileAuthentication;
