import { AspectRatioRounded, BarChart } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useState } from 'react';
import SpendChartDialog from '../../SpendChartDialog';
import { useAuth } from '../../../../hooks/useAuth';
import { useSelector } from 'react-redux';
import useAspectRatio from '../../../../hooks/useAspectRatio';

const SpendControls = () => {
  const { user } = useAuth();
  const handleAspectRatio = useAspectRatio();
  const { preferences } = useSelector((state) => state.currentUser);
  const [spendChartDialogOpen, setSpendChartDialogOpen] = useState({
    open: false,
    data: null
  });

  return (
    <>
      <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
        <Box>
          <IconButton
            edge="end"
            onClick={() => {
              setSpendChartDialogOpen({
                open: true,
                data: {
                  _id: user?.userId,
                  name: user.firstName
                }
              });
            }}
          >
            <BarChart />
          </IconButton>
        </Box>
        <Box>
          <IconButton
            edge="end"
            onClick={() => {
              handleAspectRatio(!preferences.aspectChanged);
            }}
            sx={{ ml: '10px' }}
          >
            <AspectRatioRounded />
          </IconButton>
        </Box>
      </Box>
      {spendChartDialogOpen.open && (
        <SpendChartDialog
          open={spendChartDialogOpen.open}
          handleClose={() => {
            setSpendChartDialogOpen({
              open: false,
              data: {}
            });
          }}
          rowData={spendChartDialogOpen.data}
          type='user'
        />
      )}
    </>
  );
};

export default SpendControls;
