import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Box } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { WarningAmberRounded } from '@mui/icons-material';
import ButtonComponent from '../../Button';

const statusData = {
  1: 'Approve',
  2: 'Decline',
  3: 'Cancel'
};

function ConfirmStatusUpdateDialog({ open, handleClose, selectedData, handleConfirm }) {
  return (
    <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    maxWidth='md'
    >
    <Box
        sx={{ display: 'flex', padding: '20px' }}
    >
        <DialogTitle id="alert-dialog-title" display='flex' alignItems='center' fontSize='26px' fontWeight='bold'>
        <WarningAmberRounded fontSize='large' sx={{ marginBottom: '4px', marginRight: '4px' }} />
        {`${statusData[selectedData?.status]} ${selectedData?.orderNumber}?`}
        </DialogTitle>
        <DialogActions>
        <ButtonComponent
        label='Cancel'
        size='small'
        onClick={handleClose} />
        <ButtonComponent
        size='small'
        label='Confirm'
        onClick={() => {
          handleClose();
          handleConfirm();
        }} autoFocus />
        </DialogActions>
        </Box>
    </Dialog>
  );
}

ConfirmStatusUpdateDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  selectedData: PropTypes.object
};

ConfirmStatusUpdateDialog.defaultProps = {
  open: false,
};

export default ConfirmStatusUpdateDialog;
