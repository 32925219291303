import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { Grid, Typography, IconButton, DialogTitle, Box } from '@mui/material';
import ModalDialog from '../ui/ModalDialog/index';
import { CancelPresentationRounded, DeleteRounded, SaveRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';
import styles from './style';
import Datagrid from '../ui/Datagrid';
import columns from './table_config';
import { renderCell } from '../ui/Datagrid/table_config';
import { updateAssetType, updateAssetTypeDetails } from '../../store/actions/assetTypes';
import { csvFileToArray } from '../../utils/csvFileToArray';
import SelectEditValueTypes from '../ui/SelectEditValueTypes';

function generateRandom() {
  const length = 24;
  const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

const valueTypes = ['Alphanumeric', 'Number', 'Decimal'];

const AssetDetails = ({
  handleClose,
  data,
  open
}) => {
  const dispatch = useDispatch();
  const classes = styles();
  const apiRef = useGridApiRef();
  const [properties, setProperties] = useState([]);
  const [detailsSaved, setDetailsSaved] = useState(true);
  const fileReader = new FileReader();

  useEffect(() => {
    if (data?.details) {
      setProperties(data.details);
    }
  }, [data.propertyName]);

  useEffect(() => {
    if (properties[0]?.propertyName === '') {
      const id = properties[0]?._id;
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: 1
        });
        apiRef.current.setCellMode(id, 'propertyName', 'edit');
        setTimeout(() => {
          apiRef.current.setCellFocus(id, 'propertyName');
        }, 50);
      }, 50);
    }
  }, [properties.length]);

  const handleAddProperty = () => {
    setDetailsSaved(false);
    const currentProperties = [...properties];
    const nextId = currentProperties.length - 1;
    const newRow = {
      propertyName: '',
      valueType: valueTypes[0], // Set Alphanumeric as default
      grid_id: nextId + 1,
      _id: generateRandom(),
    };
    currentProperties.unshift(newRow);
    setProperties(currentProperties);
  };

  const handleImportedData = (csvData) => {
    const newAssetTypes = csvData.map((item) => {
      return {
        ...item,
        _id: generateRandom(),
      };
    });
    if (newAssetTypes) {
      setProperties(newAssetTypes);
      dispatch(updateAssetTypeDetails({ ...data, details: newAssetTypes }));
    }
  };

  const removeProperty = (row) => {
    setDetailsSaved(false);
    const newProperties = [...properties];
    const filtered = newProperties.filter(item => item._id !== row?._id);
    setProperties(filtered);
  };

  const saveProperty = () => {
    dispatch(updateAssetTypeDetails({ ...data, details: properties }));
    const body = {
      _id: data?._id,
      name: data?.name,
      details: properties
    };
    dispatch(updateAssetType(body, 'details'));
    setDetailsSaved(true);
  };

  const updateData = (id, field, value) => {
    setDetailsSaved(false);
    const currentProperties = [...properties];
    const index = currentProperties.findIndex((item) => item._id === id);
    currentProperties[index][field] = value;
    setProperties(currentProperties);
  };

  const uploadCSVFile = (e) => {
    setDetailsSaved(false);
    const file = e.target.files[0];
    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;
        const importedAssetTypes = csvFileToArray(csvOutput, 'preserveType');
        handleImportedData(importedAssetTypes);
      };
      fileReader.readAsText(file);
    }
  };

  const getModalTitle = () => {
    return (
      <>
       <Grid container alignItems="center" justifyContent="space-between">
          <DialogTitle sx={{ fontWeight: 'bold' }}>{data.name} Details</DialogTitle>
          <Grid item>
            <IconButton
              variant="solid"
              fontSize='large'
              onClick={() => {
                setTimeout(() => {
                  saveProperty();
                }, 100);
              }}
            >
              <SaveRounded />
            </IconButton>
            <IconButton
              disabled={!detailsSaved}
              onClick={detailsSaved ? handleClose : () => {}}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderActions = ({
    id,
    row,
  }) => {
    return (
      <Grid container justifyContent="center">
        <IconButton
        variant="solid"
        onClick={() => {
          apiRef.current.selectRow(row.id, true);
          removeProperty(row, id);
        }}
      >
        <DeleteRounded/>
      </IconButton>
      </Grid>
    );
  };

  const renderValueTypeCell = (params) => {
    return <SelectEditValueTypes {...params} closeCellAfterSelect={true} data={valueTypes} updateData={updateData} />;
  };

  return (
    <ModalDialog
      title={getModalTitle()}
      isOpen={open}
      onClose={detailsSaved ? handleClose : () => {}}
      maxWidth='sm'
    >
      <Grid container>
      <Grid item className={classes.customTool} xs={2} justifyContent="flex-start" >
            <Box>
              <Typography variant='button' fontWeight='700' onClick={handleAddProperty}>+ Add</Typography>
            </Box>
            <Box>
              <label className={classes.importLabel} htmlFor='getCSVFile'>Import</label>
              <input type='file' id='getCSVFile' accept={'.csv'} style={{ display: 'none' }}
              onChange={uploadCSVFile}
              />
            </Box>
          </Grid>
        <Grid item xs={12}>
          <Datagrid
            apiRef={apiRef}
            data={properties}
            getRowId={(row) => row._id}
            columns={columns(renderCell, renderActions, renderValueTypeCell)}
            disableStickyHeader={false}
            autoHeight={false}
            height={'50vh'}
            pageSize={100}
            toolbar={{
              options: {
                columns: false,
                filters: false,
                density: false,
                export: false
              },
            }}
            onCellEditCommit={({ id, value, field }) => {
              updateData(id, field, value);
            }}
          />
        </Grid>
      </Grid>
    </ModalDialog>
  );
};
AssetDetails.propTypes = {
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.object,
  open: PropTypes.bool.isRequired
};
AssetDetails.defaultProps = {
  data: {}
};
export default AssetDetails;
