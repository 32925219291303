import { useEffect, useState } from 'react';
import { DirectionsRunRounded, AspectRatioRounded } from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  TextField,
  FormControl,
  FilledInput,
  InputLabel,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import styles from './styles';
import useAspectRatio from '../../../../hooks/useAspectRatio';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getMarkeplaceTransactionList } from '../../../../store/actions/marketplaceTransactions';
import { CALENDER_PROPS } from '../../../../utils/constants';

const MarketplaceTransactionControls = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const location = useLocation();

  const { preferences } = useSelector((state) => state.currentUser);

  const [userEmailTerm, setUserEmailTerm] = useState(null);
  const [selectedStartDateTime, setSelectedStartDateTime] = useState(null);
  const [selectedEndDateTime, setSelectedEnDateTime] = useState(null);

  const handleUserEmailTerm = (e) => {
    setUserEmailTerm(e.target.value);
  };
  const handleStartTime = (date) => {
    setSelectedStartDateTime(date);
  };
  const handleEndTime = (date) => {
    setSelectedEnDateTime(date);
  };

  const handleSearch = () => {
    dispatch(
      getMarkeplaceTransactionList(
        userEmailTerm,
        selectedStartDateTime,
        selectedEndDateTime
      )
    );
  };

  const handleAspectRatio = useAspectRatio();

  useEffect(() => {
    setUserEmailTerm(null);
    setSelectedStartDateTime(null);
    setSelectedEnDateTime(null);
    dispatch(getMarkeplaceTransactionList());
  }, [location.pathname]);

  return (
    <Grid alignItems="flex-end" justifyContent="flex-end" sx={{ marginLeft: 'auto' }}>
      <Box className={classes.container}>
        <Box>
        <FormControl>
          <InputLabel
            htmlFor="outlined-adornment-search"
            inputprops={{ classes: { root: classes.searchBar } }}
          >
            User Email
          </InputLabel>
          <FilledInput
            className={classes.searchBar}
            id="outlined-adornment-search"
            type="text"
            label="User Email"
            value={userEmailTerm || ''}
            onChange={handleUserEmailTerm}
          />
        </FormControl>
        </Box>
        <Box className={classes.dateContainer}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            {...CALENDER_PROPS}
            label="Start Date"
            disableFuture
            value={selectedStartDateTime}
            onChange={handleStartTime}
            className={classes.dateTimePicker}
            classes={{
              switchHeader: classes.datePickerHeader,
            }}
            textField={(params) => <TextField {...params} />}
          />
          <DateTimePicker
            {...CALENDER_PROPS}
            label="End Date"
            disableFuture
            minDate={selectedStartDateTime}
            value={selectedEndDateTime}
            onChange={handleEndTime}
            className={classes.dateTimePicker}
            disabled={!selectedStartDateTime}
            textField={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        </Box>
        <Box className={classes.runActionContainer}>
          <IconButton
            size="large"
            aria-label="orders"
            onClick={handleSearch}
          >
            <DirectionsRunRounded />
          </IconButton>
          </Box>
          <Box className={classes.aspectRatioContainer}>
          <IconButton
            edge="end"
            onClick={() => {
              handleAspectRatio(!preferences.aspectChanged);
            }}
          >
            <AspectRatioRounded />
          </IconButton>
          </Box>
      </Box>
    </Grid>
  );
};

export default MarketplaceTransactionControls;
