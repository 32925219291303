import { REDUX_ACTIONS } from '../../shared/config/constants';

const initialState = {
  loading: false,
  deleting: false,
  data: [],
  greenList: [],
  greenPartsDetails: [],
  errorMessage: '',
  loadingDelete: false,
  saved: false,
  paginatedElements: 0,
  orderPartsPosting: false,
  orderPartsPostSuccess: false,
  orderPartsPostError: false,
  orderPartsPostErrorMessage: '',
  printState: 'idle', // idle, error, loading
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.ORDERS_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case REDUX_ACTIONS.ORDERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...action.payload.orders],
        paginatedElements: action.payload.paginatedElements,
        errorMessage: '',
      };
    case REDUX_ACTIONS.ORDERS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: [],
      };
    case REDUX_ACTIONS.ORDERS_LIST_ERROR_NOTIFIED:
      return {
        ...state,
        errorMessage: '',
        data: [],
      };
    case REDUX_ACTIONS.ORDERS_SET_GREEN_LIST:
      return {
        ...state,
        greenList: [...action.payload],
      };
    case REDUX_ACTIONS.ORDERS_SET_GREEN_ITEM_NOT_FOUND:
      return {
        ...state,
        isGreenItemNotFound: action.payload,
      };
    case REDUX_ACTIONS.ORDERS_GREEN_PARTS_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case REDUX_ACTIONS.ORDERS_SET_GREEN_PARTS_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        greenPartsDetails: [...action.payload.parts],
        errorMessage: '',
      };
    case REDUX_ACTIONS.ORDERS_GREEN_PARTS_ERROR_NOTIFIED:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        greenPartsDetails: [],
      };
    case REDUX_ACTIONS.ORDERS_RESET:
      return {
        ...initialState,
        greenList: [],
      };
    case REDUX_ACTIONS.ORDER_PARTS_POSTING:
      return {
        ...state,
        orderPartsPosting: true,
        orderPartsPostSuccess: false,
        orderPartsPostError: false,
      };
    case REDUX_ACTIONS.ORDER_PARTS_POST_SUCCESS:
      return {
        ...state,
        orderPartsPosting: false,
        orderPartsPostSuccess: true,
        orderPartsPostError: false,
        greenList: action?.remainingOrderItems || [],
      };
    case REDUX_ACTIONS.ORDER_PARTS_POST_ERROR:
      return {
        ...state,
        orderPartsPosting: false,
        orderPartsPostSuccess: false,
        orderPartsPostError: true,
        orderPartsPostErrorMessage: action.payload.message,
      };

    case REDUX_ACTIONS.DOWNLOAD_ORDER_IDLE:
      return {
        ...state,
        printState: 'idle',
      };
    case REDUX_ACTIONS.DOWNLOAD_ORDER_LOADING:
      return {
        ...state,
        printState: 'loading',
      };
    case REDUX_ACTIONS.DOWNLOAD_ORDER_ERROR:
      return {
        ...state,
        printState: 'error',
      };
    default:
      return state;
  }
};

export default ordersReducer;
