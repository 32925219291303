const columns = (
  renderCell,
  renderPumpRamp,
  renderActions,
) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 300,
    hide: false,
    editable: true,
    renderCell,
  },
  {
    field: 'pump_ramp',
    headerName: 'Pump Ramp',
    width: 300,
    renderCell: renderPumpRamp,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'modify_by',
    headerName: 'Modify By',
    width: 300,
    headerAlign: 'center',
    renderCell,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 300,
    hide: false,
    headerAlign: 'center',
    renderCell: renderActions,
  },
];

export default columns;
