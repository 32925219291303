import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fade from '@mui/material/Fade';

const ScrollToTopButton = (props) => {
  const { window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : props?.scrollTarget,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
        behavior: 'smooth'
      });
    }
  };

  return (
      <Fade in={trigger}>
        <Box
          onClick={handleClick}
          role="presentation"
          sx={props?.style ? { ...props?.style } : { position: 'fixed', bottom: 16, right: 16, zIndex: 1000 }}
        >
        <Fab size={props?.size || 'medium'} aria-label="scroll back to top">
          <KeyboardArrowUpIcon sx={{ fontSize: '40px' }} />
         </Fab>
        </Box>
      </Fade>
  );
};

ScrollToTopButton.propTypes = {
  window: PropTypes.func,
  scrollTarget: PropTypes.any,
  style: PropTypes.object,
  size: PropTypes.string
};

export default ScrollToTopButton;
