import React from 'react';
import {
  Grid,
} from '@mui/material';
import MarketplaceNavbar from '../ui/marketplace/Navbar';
import Featured from './featured';
import SeamlessShopping from './seamlessShopping';
import PremiumLocation from './location';
import Subscribe from './subscribe';
import Footer from './footer';
import styles from './styles';
import StoryBook from './storybook';

const Marketplace = () => {
  const classes = styles();

  return (
    <Grid>
      <MarketplaceNavbar />
      <Grid
        className={classes.marketplaceWrapper}
      >
        <Featured />
        <SeamlessShopping />
        <PremiumLocation />
        <StoryBook />
        <Subscribe />
        <Footer />
      </Grid>
    </Grid>
  );
};

export default Marketplace;
