import { useEffect, useState } from 'react';
import { DirectionsRunRounded, AspectRatioRounded } from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  TextField,
  FormControl,
  FilledInput,
  InputLabel,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import styles from './styles';
import useAspectRatio from '../../../../hooks/useAspectRatio';
import { listAuditLogs } from '../../../../store/actions/auditLogs';
import { useDispatch, useSelector } from 'react-redux';
import { CALENDER_PROPS } from '../../../../utils/constants';
import { REDUX_ACTIONS } from '../../../../shared/config/constants';

const AuditLogControls = () => {
  const dispatch = useDispatch();
  const { preferences } = useSelector((state) => state.currentUser);
  const classes = styles();
  const [selectedStartDateTime, setSelectedStartDateTime] = useState(null);
  const [selectedEndDateTime, setSelectedEnDateTime] = useState(null);
  const [userEmailTerm, setUserEmailTerm] = useState(null);

  const handleAspectRatio = useAspectRatio();

  const handleUserEmailTerm = (e) => {
    setUserEmailTerm(e.target.value);
  };

  const handleStartTime = (date) => {
    setSelectedStartDateTime(date);
  };

  const handleEndTime = (date) => {
    setSelectedEnDateTime(date);
  };

  const handleSearch = () => {
    const body = {
      searchValue: userEmailTerm,
      startDate: selectedStartDateTime,
      endDate: selectedEndDateTime
    };
    dispatch({
      type: REDUX_ACTIONS.AUDIT_LOGS_SEARCH_CONTROL,
      payload: body,
    });
    dispatch({
      type: REDUX_ACTIONS.AUDIT_LOGS_SET_CURRENT_PAGE,
      payload: 1
    });
    dispatch(listAuditLogs(body));
  };

  useEffect(() => {
    const start = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    const end = new Date();
    const body = {
      searchValue: userEmailTerm,
      startDate: start,
      endDate: end
    };
    setUserEmailTerm(null);
    setSelectedStartDateTime(start);
    setSelectedEnDateTime(end);
    dispatch(listAuditLogs(body));
  }, []);

  return (
    <Grid alignItems="flex-end" justifyContent="flex-end" sx={{ marginLeft: 'auto' }}>
      <Box className={classes.container}>
        <Box>
        <FormControl>
          <InputLabel
            htmlFor="outlined-adornment-search"
            inputprops={{ classes: { root: classes.searchBar } }}
          >
            User Email
          </InputLabel>
          <FilledInput
            className={classes.searchBar}
            id="outlined-adornment-search"
            type="text"
            label="User Email"
            value={userEmailTerm || ''}
            onChange={handleUserEmailTerm}
          />
        </FormControl>
        </Box>
        <Box className={classes.dateContainer}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            {...CALENDER_PROPS}
            label="Start Date"
            value={selectedStartDateTime}
            onChange={handleStartTime}
            className={classes.dateTimePicker}
            classes={{
              switchHeader: classes.datePickerHeader,
            }}
            disableFuture
            textField={(params) => <TextField {...params} />}
          />
          <DateTimePicker
            {...CALENDER_PROPS}
            label="End Date"
            value={selectedEndDateTime}
            onChange={handleEndTime}
            className={classes.dateTimePicker}
            disabled={!selectedStartDateTime}
            disableFuture
            minDate={selectedStartDateTime}
            textField={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        </Box>
        <Box className={classes.runActionContainer}>
        <IconButton
            size="large"
            aria-label="orders"
            onClick={handleSearch}
          >
            <DirectionsRunRounded />
          </IconButton>
        </Box>
        <Box className={classes.aspectRatioContainer}>
          <IconButton
            edge="end"
            onClick={() => {
              handleAspectRatio(!preferences.aspectChanged);
            }}
          >
            <AspectRatioRounded />
          </IconButton>
        </Box>
      </Box>
    </Grid>
  );
};

export default AuditLogControls;
