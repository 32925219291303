import { AspectRatioRounded } from '@mui/icons-material';
import { Box, IconButton, InputAdornment } from '@mui/material';
import useAspectRatio from '../../../../hooks/useAspectRatio';
import { useSelector } from 'react-redux';

const AspectRatio = () => {
  const { preferences } = useSelector((state) => state.currentUser);

  const handleAspectRatio = useAspectRatio();

  return (
    <>
      <Box sx={{ marginLeft: 'auto' }}>
        <InputAdornment position="end">
          <IconButton
            edge="end"
            onClick={() => {
              handleAspectRatio(!preferences.aspectChanged);
            }}
          >
            <AspectRatioRounded />
          </IconButton>
        </InputAdornment>
      </Box>
    </>
  );
};

export default AspectRatio;
