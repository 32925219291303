import { useState } from 'react';
import { TagRounded, Check, Close } from '@mui/icons-material';
import { postTag } from '../../../../store/actions/assetData';
import {
  Box,
  IconButton,
  InputAdornment,
  Popover,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import styles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../../hooks/useAuth';
const EventTag = () => {
  const dispatch = useDispatch();
  const assetData = useSelector((state) => state.assetData);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { user } = useAuth();
  const classes = styles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [eventText, setEventText] = useState('');

  const data = assetData.data;

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleOnTag = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleOnChange = (e) => {
    setEventText(e.target.value);
  };

  const handleOnSubmit = () => {
    const filterId = searchParams.get('filterId');
    const jobId = searchParams.get('assetDataType').split('_')[1];
    const start = data[0].timestamp;
    const end = data[data.length - 1].timestamp;
    const event = eventText;

    dispatch(postTag(user, jobId, start, end, event, filterId));
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ marginLeft: 'auto' }}>
        <InputAdornment position="end">
          <IconButton
            edge="end"
            onClick={handleOnTag}
            style={{ marginLeft: '-24px' }}
          >
            <TagRounded />
          </IconButton>
        </InputAdornment>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Typography sx={{ p: 1 }}>
            <TextareaAutosize
              onChange={handleOnChange}
              aria-label="minimum height"
              minRows={3}
              placeholder="Hashtag"
            />
            <IconButton
              edge="end"
              className={classes.actionButton}
              onClick={handleOnSubmit}
            >
              <Check />
            </IconButton>
            <IconButton
              edge="end"
              onClick={handleClose}
              className={classes.actionButton}
            >
              <Close />
            </IconButton>
          </Typography>
        </Popover>
      </Box>
    </>
  );
};

export default EventTag;
