import { REDUX_ACTIONS } from '../../shared/config/constants';

const initialState = {
  loading: false,
  deleting: false,
  data: [],
  errorMessage: '',
  loadingDelete: false,
  saved: false,
  paginatedElements: 0,
  downloadTransactionOrderNum: '',
  printState: false,
  limit: 100,
  currentPage: 1,
  totalCount: 0,
  searchKey: null,
  startDate: null,
  endDate: null,
  filters: [],
  sort: null,
  transactionHistoryKPIsLoading: false,
  transactionHistoryKPIs: [],
  transactionHistoryDivisionKPIsLoading: false,
  transactionHistoryDivisionKPIs: []
};

const transactionHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.TRANSACTION_HISTORY_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case REDUX_ACTIONS.TRANSACTION_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...action.payload.transactionHistory],
        paginatedElements: action.payload.paginatedElements,
        totalCount: action.payload.totalCount,
        errorMessage: '',
      };
    case REDUX_ACTIONS.TRANSACTION_HISTORY_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: [],
      };
    case REDUX_ACTIONS.TRANSACTION_HISTORY_ORDER_NUM:
      return {
        ...state,
        downloadTransactionOrderNum: action.payload.orderNum,
      };
    case REDUX_ACTIONS.TRANSACTION_HISTORY_PRINT_STATE:
      return {
        ...state,
        printState: action.payload.printState
      };
    case REDUX_ACTIONS.TRANSACTION_HISTORY_SEARCH_CONTROL:
      return {
        ...state,
        searchKey: action.payload.searchKey,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate
      };
    case REDUX_ACTIONS.TRANSACTION_HISTORY_SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case REDUX_ACTIONS.TRANSACTION_HISTORY_SET_FILTER:
      return {
        ...state,
        filters: action.payload
      };
    case REDUX_ACTIONS.TRANSACTION_HISTORY_SET_SORT:
      return {
        ...state,
        sort: action.payload
      };
    case REDUX_ACTIONS.TRANSACTION_HISTORY_KPIS_LOADING:
      return {
        ...state,
        transactionHistoryKPIsLoading: true,
        errorMessage: '',
      };
    case REDUX_ACTIONS.TRANSACTION_HISTORY_KPIS_SUCCESS:
      return {
        ...state,
        transactionHistoryKPIs: action.payload,
        errorMessage: '',
        transactionHistoryKPIsLoading: false,
      };
    case REDUX_ACTIONS.TRANSACTION_HISTORY_DIVISION_KPIS_SUCCESS:
      return {
        ...state,
        transactionHistoryDivisionKPIs: action.payload,
        errorMessage: '',
        transactionHistoryDivisionKPIsLoading: false,
      };
    default:
      return state;
  }
};

export default transactionHistoryReducer;
