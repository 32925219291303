/* eslint-disable react/prop-types */
import { ShoppingCart as ShoppingCartIcon } from '@mui/icons-material';
import { Badge, Box, IconButton } from '@mui/material';
import { useState } from 'react';

const UserCartButton = ({ data, modalComponent, cartColor }) => {
  const [isCartDialogOpen, setIsCartDialogOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onCartClick = (e) => {
    e.preventDefault();
  };

  return (
    <Box sx={{}}>
      {data.length > 0 && (
        <IconButton
          size="large"
          aria-label="cart"
          color={cartColor}
          onClick={() => {
            setIsModalOpen(true);
            setIsCartDialogOpen(true);
          }}
        >
          <Badge
            badgeContent={data.length}
            color="error"
            style={{
              marginTop: '10px',
              backgroundColor: 'white',
              borderRadius: '25px',
              padding: '5px',
            }}
          >
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
      )}
      {modalComponent && isModalOpen && (
        <modalComponent.type
          isOpen={isCartDialogOpen}
          data={data}
          onCloseClick={() => {
            setIsCartDialogOpen(false);
          }}
          onActionClick={onCartClick}
          onModalCloseClick={() => {
            setIsModalOpen(false);
          }}
          {...modalComponent.props}
        />
      )}
    </Box>
  );
};

export default UserCartButton;
