import { useState } from 'react';
import { DirectionsRunRounded, AspectRatioRounded } from '@mui/icons-material';
import { Box, Grid, IconButton, TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import styles from './styles';
import useAspectRatio from '../../../../hooks/useAspectRatio';
import { listAuditLogs } from '../../../../store/actions/auditLogs';
import { useDispatch, useSelector } from 'react-redux';
import { CALENDER_PROPS } from '../../../../utils/constants';

const DateRageSearch = () => {
  const dispatch = useDispatch();
  const { preferences } = useSelector((state) => state.currentUser);
  const classes = styles();
  const [selectedStartDateTime, setSelectedStartDateTime] = useState(null);
  const [selectedEndDateTime, setSelectedEnDateTime] = useState(null);

  const handleAspectRatio = useAspectRatio();

  const handleStartTime = (date) => {
    setSelectedStartDateTime(date);
  };
  const handleEndTime = (date) => {
    setSelectedEnDateTime(date);
  };

  const handleSearch = () => {
    dispatch(listAuditLogs(selectedStartDateTime, selectedEndDateTime));
  };

  return (
    <Grid container alignItems="flex-end" justifyContent="flex-end">
      <Box className={classes.container}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            {...CALENDER_PROPS}
            label="Start Date"
            value={selectedStartDateTime}
            onChange={handleStartTime}
            className={classes.dateTimePicker}
            classes={{
              switchHeader: classes.datePickerHeader,
            }}
            disableFuture
            renderInput={(params) => <TextField {...params} />}
          />
          <DateTimePicker
            label="End Date"
            value={selectedEndDateTime}
            onChange={handleEndTime}
            className={classes.dateTimePicker}
            disabled={!selectedStartDateTime}
            disableFuture
            minDate={selectedStartDateTime}
            renderInput={(params) => <TextField {...params} />}
          />
          <IconButton
            size="large"
            aria-label="orders"
            color="darkIcons"
            onClick={handleSearch}
          >
            <DirectionsRunRounded />
          </IconButton>
          <IconButton
            edge="end"
            onClick={() => {
              handleAspectRatio(!preferences.aspectChanged);
            }}
          >
            <AspectRatioRounded />
          </IconButton>
        </LocalizationProvider>
      </Box>
    </Grid>
  );
};

export default DateRageSearch;
