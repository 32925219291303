/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import styles from './styles';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from '../../../../store/actions/snackbar';
import { Grid, IconButton } from '@mui/material';
import { CancelPresentationRounded, DeleteRounded, SaveRounded } from '@mui/icons-material';
import SelectEditInputTextField from '../../SelectEditInputTextField';
import ModalDialog from '../../ModalDialog';
import Datagrid from '../../Datagrid';
import columns from './table_config';
import PropTypes from 'prop-types';
import { HYDRAULICS_CUTTING_PROPERTY } from '../../../../utils/constants';
import { updateHydraulicProperty, updateHydraulicsDetails } from '../../../../store/actions/hydraulics';

const CuttingPropertyModal = ({
  onCloseClick,
  isOpen,
  selectedJob,
  data
}) => {
  const classes = styles();
  const apiRef = useGridApiRef();
  const hydraulics = useSelector((state) => state.hydraulics);
  const dispatch = useDispatch();
  const [properties, setProperties] = useState([]);
  const [invalidRows, setInvalidRows] = useState({});

  function generateRandom() {
    const length = 24;
    const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let retVal = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  useEffect(() => {
    if (!hydraulics.details.loading) {
      if (hydraulics?.details?.data[0]?.cuttings_data) {
        const cuttingProperty = hydraulics?.details?.data[0]?.cuttings_data;

        setProperties([{ _id: generateRandom(), ...cuttingProperty }]);
      } else {
        setProperties([{
          _id: generateRandom(),
          diameter: '',
          thickness: '',
          density: '',
          porosity: ''
        }]);
      }
    }

    return () => {
      setProperties([]);
    };
  }, [hydraulics.details]);

  useEffect(() => {
    if (properties[0]?.diameter === '') {
      const id = properties[0]._id;
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: 0
        });
        apiRef.current.setCellMode(id, 'diameter', 'edit');
        setTimeout(() => {
          apiRef.current.setCellFocus(id, 'diameter');
        }, 50);
      }, 50);
      apiRef.current.setPage(0);
    }
  }, [properties?.length]);

  const showError = (message, rowId) => {
    dispatch(enqueueSnackbar(message, 'error'));
    setInvalidRows(prev => ({ ...prev, [rowId]: true }));
    return false;
  };

  const validateCuttingDetails = (runDetails) => {
    const { thickness, porosity, diameter, density, _id } = runDetails;
    if (diameter < 0 || diameter > 0.5) return showError(HYDRAULICS_CUTTING_PROPERTY.DIAMETER, _id);
    if (thickness < 0 || thickness > 0.5) return showError(HYDRAULICS_CUTTING_PROPERTY.THICKNESS, _id);
    if (density < 10 || density > 25) return showError(HYDRAULICS_CUTTING_PROPERTY.DENSITY, _id);
    if (porosity < 0 || porosity > 0.5) return showError(HYDRAULICS_CUTTING_PROPERTY.POROSITY, _id);
    return true;
  };

  const checkCuttingPropertyValues = (value) => {
    const row = value;
    const invalidFields = [];
    const isInvalidNumber = (val) => {
      // eslint-disable-next-line no-mixed-operators
      return isNaN(val) || val === '' || typeof val === 'string' && val.trim() === '';
    };

    if (row?.diameter === undefined || row?.diameter === null || isInvalidNumber(row.diameter)) {
      invalidFields.push('Diameter');
    }
    if (row?.thickness === undefined || row?.thickness === null || isInvalidNumber(row.thickness)) {
      invalidFields.push('Thickness');
    }
    if (row?.density === undefined || row?.density === null || isInvalidNumber(row.density)) {
      invalidFields.push('Density');
    }
    if (row?.porosity === undefined || row?.porosity === null || isInvalidNumber(row.porosity)) {
      invalidFields.push('Porosity');
    }

    if (invalidFields.length === 0) {
      setInvalidRows(prev => ({ ...prev, [row._id]: false }));
      return validateCuttingDetails(row);
    } else {
      setInvalidRows(prev => ({ ...prev, [row._id]: true }));
      dispatch(
        enqueueSnackbar(
          `Please enter valid numeric values for: ${invalidFields.join(', ')}`,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return false;
    }
  };

  const saveProperty = (row) => {
    if (checkCuttingPropertyValues(row)) {
      const validationError = '';
      if (validationError === '') {
        const body = {
          diameter: Number(row.diameter),
          thickness: Number(row.thickness),
          density: Number(row.density),
          porosity: Number(row.porosity),
        };
        const cuttingDetailsBody = hydraulics?.details?.data[0];
        cuttingDetailsBody.drillStringsId = hydraulics?.drillDetails?.hydraulicsDrillDetails?.drillStrings.filter((item) => `${item?.name?.trim()} @ ${item?.MD}ft` === cuttingDetailsBody?.drillStringsId)[0]._id;
        cuttingDetailsBody.drillFluidsId = hydraulics?.drillDetails?.hydraulicsDrillDetails?.drillFluids.filter((item) => `${item?.name?.trim()} @ ${item?.MD}ft` === cuttingDetailsBody?.drillFluidsId)[0]._id;
        cuttingDetailsBody.cuttings_data = body;
        dispatch(updateHydraulicsDetails(hydraulics?.details?.data[0]?._id, cuttingDetailsBody));
        const updatedData = {
          ...data,
          _id: hydraulics?.details?.data[0]?._id,
          run_details: cuttingDetailsBody
        };
        dispatch(updateHydraulicProperty(updatedData));
      } else {
        if (validationError.length) {
          setInvalidRows(prev => ({ ...prev, [row._id]: true }));
        }
        dispatch(
          enqueueSnackbar(
            validationError,
            'error',
            new Date().getTime() + Math.random()
          )
        );
      }
    }
  };

  const removeCuttingProperty = () => {
    const hydraulicsRunDetails = hydraulics?.details?.data[0];
    hydraulicsRunDetails.drillStringsId = hydraulics?.drillDetails?.hydraulicsDrillDetails?.drillStrings.filter((item) => `${item?.name?.trim()} @ ${item?.MD}ft` === hydraulicsRunDetails?.drillStringsId)[0]._id;
    hydraulicsRunDetails.drillFluidsId = hydraulics?.drillDetails?.hydraulicsDrillDetails?.drillFluids.filter((item) => `${item?.name?.trim()} @ ${item?.MD}ft` === hydraulicsRunDetails?.drillFluidsId)[0]._id;
    hydraulicsRunDetails.cuttings_data = {
      diameter: 0.025,
      thickness: 0.025,
      density: 21.75,
      porosity: 0.22
    };
    dispatch(updateHydraulicsDetails(hydraulics?.details?.data[0]?._id, hydraulicsRunDetails));
    const updatedData = {
      ...data,
      _id: hydraulics?.details?.data[0]?._id,
      run_details: hydraulicsRunDetails
    };
    dispatch(updateHydraulicProperty(updatedData));
  };

  const getModalTitle = () => {
    return (
      <>
      <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
          {`${selectedJob?.Rig} - ${data?.name}` || ''}
          </Grid>
          <Grid item>
          <IconButton
              onClick={() => {
                onCloseClick();
              }}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const updateData = (id, field, value) => {
    setProperties(prevProperties => {
      const updatedProperties = prevProperties.map(item => {
        if (item._id === id) {
          return {
            ...item,
            [field]: Number(value)
          };
        }
        return item;
      });
      return updatedProperties;
    });
  };

  const renderValueCell = (params) => {
    return <SelectEditInputTextField numberFormatOnly {...params} updateData={updateData} />;
  };

  const renderActionCell = ({ row }) => {
    return (
      <Grid container justifyContent="center">
       <IconButton
          variant="solid"
          disabled={hydraulics.details.loading}
          onClick={() => {
            setTimeout(() => {
              saveProperty(row);
            }, 100);
          }}
        >
          <SaveRounded />
        </IconButton>
        <IconButton
          variant="solid"
          disabled={hydraulics.details.loading}
          onClick={() => {
            removeCuttingProperty();
          }}
        >
            <DeleteRounded />
        </IconButton>
      </Grid>
    );
  };

  return (
    <>
    <ModalDialog
    title={getModalTitle()}
    isOpen={isOpen}
    onClose={onCloseClick}
    dialogClass={classes.dialogModal}
  >
    <Grid container>
      <Grid item xs={12}>
        <Datagrid
          apiRef={apiRef}
          data={properties}
          sx={{
            marginLeft: '-6px',
            '.MuiDataGrid-cell': {
              fontSize: '1rem',
              fontWeight: 900
            },
            '.MuiDataGrid-columnHeader': {
              fontSize: '1.2rem',
              fontWeight: '900 !important'
            }
          }}
          loading={hydraulics?.details?.loading}
          columns={columns(renderValueCell, renderActionCell)}
          onRowClick={(params, evt) => {
            evt.preventDefault();
            evt.stopPropagation();
          }}
          disableStickyHeader={false}
          autoHeight={false}
          autoheight
          height={'50vh'}
          getRowClassName={(params) => {
            return invalidRows[params.id] ? classes.invalidRow : '';
          }}
          pageSize={100}
          editMode="cell"
          toolbar={{
            options: {
              columns: false,
              filters: false,
              density: false,
              export: false
            },
          }}
        />
      </Grid>
    </Grid>
  </ModalDialog>
  </>
  );
};
CuttingPropertyModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  selectedJob: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.any
};
CuttingPropertyModal.defaultProps = {
  selectedJob: {}
};

export default CuttingPropertyModal;
