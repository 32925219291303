import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../ModalDialog';
import Datagrid from '../Datagrid';
import { Box, CircularProgress, Grid, IconButton, Input, InputAdornment, Typography } from '@mui/material';
import { gridClasses } from '@mui/x-data-grid';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';
import CallMergeRoundedIcon from '@mui/icons-material/CallMergeRounded';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import { useAuth } from '../../../hooks/useAuth';
import {
  listOrderParts,
  postOrderParts,
  listOrders,
  downloadOrderPDF,
  addToGreenList,
  removeFromGreenList
} from '../../../store/actions/orders';
import { isFullArray } from '../../../utils/validators';
import { cartDialogSort } from '../../../utils/cartDialogSort';
import {
  DeleteRounded,
  ContentCopy,
  CancelPresentationRounded,
  ShoppingCartCheckoutRounded,
  PrintRounded,
  CallSplitRounded
} from '@mui/icons-material';
import { renderCell } from '../../ui/Datagrid/table_config';
import DescriptionImageModalDialog from '../DescriptionImageModalDialog';
import columns from './table_config';
import SliderOrderConfirmModalDialog from '../SliderOrderConfirmModalDialog';
import OrderProcessingModalDialog from '../OrderProcessingModalDialog';
import styles from './styles';
import { enqueueSnackbar } from '../../../store/actions/snackbar';
import SplitOrdersCart from './SplitOrders';
import { splitOrdersByMaxAmount } from '../../../utils/cartSplitOrders';
import { REDUX_ACTIONS } from '../../../shared/config/constants';

const OrdersCartModalDialog = ({
  isOpen,
  partIDs,
  orderData,
  onCloseClick,
  onActionClick,
  saveButtonText,
  maxOrderAmount,
  setMaxOrderAmount,
  splitOrder,
  setSplitOrder
}) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { loading, greenPartsDetails, orderPartsPostSuccess } = useSelector(
    (state) => state.orders
  );
  const [cart, setCart] = useState([]);
  const [cartPrice, setCartPrice] = useState(0);
  const apiRef = useGridApiRef([]);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [partImage, setPartImage] = useState({
    open: false,
    data: {}
  });
  const [dialogTitle, setDialogTitle] = useState(null);
  const orders = useSelector((state) => state.orders);
  const [sliderModal, setSliderModal] = useState(false);
  const [maxPrice, setMaxPrice] = useState('');
  const [groupedOrders, setGroupedOrders] = useState([]);
  const classes = styles();

  const handleDownloadOrderPDF = (orderId) => {
    dispatch(downloadOrderPDF(orderId));
  };

  useEffect(() => {
    if (orderData && Object.keys(orderData)?.length) {
      setDialogTitle(getDialougeTitleWithPrint(orderData));
    }
  }, [orders.printState]);

  const getDialougeTitleWithPrint = ({ _id, orderNumber, timestamp, quantity, price }) => {
    return (
      <>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {`Date: ${timestamp}, Order Id: ${orderNumber}, Quantity: ${quantity}, Total Price: $${price.toFixed(2)}`}
          </Grid>
          <Grid item>
            <IconButton
              disabled={orders.printState === 'loading'}
              onClick={() => {
                handleDownloadOrderPDF(orderNumber);
              }}
            >
              <PrintRounded fontSize='large' />
            </IconButton>
            <IconButton
              onClick={() => {
                handleOrderButtonClick();
              }}
              >
              <ShoppingCartCheckoutRounded fontSize='large'/>
            </IconButton>
            <IconButton
              onClick={() => {
                onCloseClick();
              }}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(listOrderParts(user.companyId, partIDs));
      if (saveButtonText) {
        setDialogTitle(getDialougeTitleWithPrint(orderData));
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (orderPartsPostSuccess) {
      setIsProcessing(false);
    }
  }, [orderPartsPostSuccess]);

  useEffect(() => {
    if (isFullArray(greenPartsDetails) && !loading) {
      const cartDetails = partIDs
        .map((id) => greenPartsDetails.find((part) => part._id === id))
        .filter((part) => !!part);

      setCart(cartDetails.sort(cartDialogSort));
      const highestPrice = Math.max(...cartDetails.map(product => parseFloat(product.Price)));
      setMaxPrice(highestPrice);
      const cartTotalPrice = cartDetails
        ?.map((i) => +i?.Price)
        .reduce((sum, num) => sum + num, 0);
      setCartPrice(cartTotalPrice.toFixed(2));
      const sortedProducts = cartDetails.sort(cartDialogSort).slice().sort((a, b) => parseFloat(b.Price) - parseFloat(a.Price));
      const groupedOrders = splitOrdersByMaxAmount(sortedProducts, maxOrderAmount);
      setGroupedOrders(groupedOrders);
    } else {
      setCart([]);
    }
  }, [greenPartsDetails, loading]);

  const handleOrderButtonClick = (e) => {
    setSliderModal(true);
    onActionClick(e);
  };

  const placeOrder = () => {
    dispatch(postOrderParts(user, cart));
    setSliderModal(false);
    setIsProcessing(true);
    setLoadingDialog(true);
  };

  const closeLoadingDialog = () => {
    setLoadingDialog(false);
    onCloseClick();
    dispatch(listOrders(user));
  };

  const splitOrders = () => {
    dispatch({
      type: REDUX_ACTIONS.SET_SHOPPING_CART_PREFERENCES,
      payload: { maxOrderAmount },
    });
    if (splitOrder) {
      setSplitOrder(!splitOrder);
      return;
    }
    if (maxOrderAmount < maxPrice) {
      dispatch(
        enqueueSnackbar(
          'Max Order Amount value is less than price of highest priced part, Could not split the orders',
          'error',
          new Date().getTime() + Math.random()
        )
      );
    } else {
      setSplitOrder(!splitOrder);
      const sortedProducts = cart.slice().sort((a, b) => parseFloat(b.Price) - parseFloat(a.Price));
      const groupedOrders = splitOrdersByMaxAmount(sortedProducts, maxOrderAmount);
      setGroupedOrders(groupedOrders);
    }
  };

  const getModalTitle = () => {
    return (
      <>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item className={classes.inputContainer}>
            <Grid item>
              <Input
              disabled={splitOrder}
              placeholder="Max Order Amount"
              value={maxOrderAmount}
              onChange={(e) => {
                setMaxOrderAmount(e.target.value);
              }}
              className={classes.inputFieldMaxOrder}
              type="number"
              startAdornment={<InputAdornment sx={{ fontSize: '16px' }} position="start"><AttachMoneyOutlinedIcon /></InputAdornment>}
              />
            </Grid>
            <Grid item>
            <IconButton
              disabled={!maxOrderAmount}
              onClick={() => {
                splitOrders();
              }}
            >
            {splitOrder ? <CallMergeRoundedIcon fontSize='large' /> : <CallSplitRounded fontSize='large' />}
            </IconButton>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                onCloseClick();
                setSplitOrder(false);
              }}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const createDialogTitle = (orderData, cart, cartPrice) => {
    return (
      <>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
          {`Date: ${orderData.timestamp}, Order Id: ${orderData.orderNumber}, Quantity: ${cart.length}, Total Price: $${cartPrice}`}
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                handleOrderButtonClick();
              }}
            >
              <ShoppingCartCheckoutRounded fontSize='large'/>
            </IconButton>
            <IconButton
              onClick={() => {
                onCloseClick();
              }}
            >
              <CancelPresentationRounded fontSize='large' />
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const removeRow = (row, id) => {
    apiRef.current.selectRow(id, true);
    const allRows = Array.from(apiRef.current.getRowModels());
    const currentCart = apiRef.current.getAllRowIds();
    const newList = [];

    for (const key of apiRef.current.getSelectedRows().keys()) {
      const deleteRow = currentCart.filter((row) => row === key);
      newList.push(deleteRow[0]);
    }

    const filtered = allRows.filter((x) => {
      return !newList.includes(x[0]);
    });

    const newCart = [];
    for (const row of filtered) {
      newCart.push(row[1]);
    }
    setCart(newCart);
    const cartTotalPrice = newCart
      ?.map((i) => +i?.Price)
      .reduce((sum, num) => sum + num, 0);
    const sortedCart = newCart.sort(cartDialogSort);
    const highestPrice = Math.max(...sortedCart.map(product => parseFloat(product.Price)));
    setMaxPrice(highestPrice);
    setCart(sortedCart);
    setCartPrice(cartTotalPrice.toFixed(2));
    dispatch(removeFromGreenList(row._id));
    if (saveButtonText) {
      setDialogTitle(createDialogTitle(orderData, newCart, cartTotalPrice.toFixed(2)));
    }
    if (sortedCart.length === 0) {
      onCloseClick();
    }
  };

  const duplicateRow = (row, id) => {
    apiRef.current.selectRow(id, true);
    const newCart = [...cart];
    for (const row of apiRef.current.getSelectedRows().values()) {
      newCart.push(row);
    }
    const cartTotalPrice = newCart
      ?.map((i) => +i?.Price)
      .reduce((sum, num) => sum + num, 0);
    const sortedCart = newCart.sort(cartDialogSort);
    setCart(sortedCart);
    setCartPrice(cartTotalPrice.toFixed(2));
    dispatch(addToGreenList(row._id));
    if (saveButtonText) {
      setDialogTitle(createDialogTitle(orderData, newCart, cartTotalPrice.toFixed(2)));
    }
  };

  const renderActions = ({
    id,
    row
  }) => {
    return (
      <Grid container justifyContent="center">
        <IconButton
        variant="solid"
        onClick={() => removeRow(row, id)}
      >
        <DeleteRounded/>
      </IconButton>
        <IconButton
          variant="solid"
          onClick={() => duplicateRow(row, id)}
        >
          <ContentCopy/>
        </IconButton>
      </Grid>
    );
  };

  const renderDescriptionCell = ({ value, row }) => (
    <Typography
      onClick={() => setPartImage({
        open: true, data: row
      })}
      style={{ fontSize: '14px', fontWeight: 'bold', textWrap: 'wrap', cursor: 'pointer' }}
    >{value}
    </Typography>
  );

  if (apiRef.current === null) apiRef.current = {};

  return (
      <>
        <ModalDialog
          title={dialogTitle || getModalTitle()}
          isOpen={isOpen}
          onClose={() => {
            onCloseClick();
          }}
        >
          {!orderData && !splitOrder && <Grid container alignItems="center" justifyContent="space-between">
          <Grid item className={classes.displayQuants}>
            {`Quantity: ${cart?.length}, Total Price: $${cartPrice}`}
          </Grid>
          <Grid item>
            <IconButton
              sx={{ mr: '24px' }}
              onClick={() => {
                handleOrderButtonClick();
              }}
            >
              <ShoppingCartCheckoutRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>}
          {!splitOrder && <Grid>
            <Datagrid
              loading={loading}
              disableStickyHeader={false}
              data={cart}
              columns={columns(
                renderCell,
                user.type,
                renderActions,
                renderDescriptionCell
              )}
              apiRef={apiRef}
              getRowId={(row) => `${Math.random(1000)}_${row._id}`}
              getRowHeight={() => 'auto'}
              sx={{
                '.MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold !important',
                },
                [`& .${gridClasses.cell}`]: {
                  py: 1,
                },
                '.cartModalCell': {
                  fontSize: '16px',
                  fontWeight: 'bold'
                }
              }}
              pageSize={100}
              autoHeight={false}
              height={'60vh'}
              toolbar={{
                options: {
                  columns: false,
                  filters: true,
                  density: false,
                  export: false,
                },
              }}
            />
          </Grid>}
          {splitOrder
            ? (
            <>
            {loading
              ? (
              <Box
               sx={{
                 width: '100%',
                 height: '300px',
                 display: 'flex',
                 justifyContent: 'center',
                 alignItems: 'center'
               }}>
                 <CircularProgress />
               </Box>
                )
              : (
              <>
              {groupedOrders.map((item, index) => {
                return (
                <SplitOrdersCart
                  key={index}
                  cartItems={item}
                  setSplitOrder={setSplitOrder}
                  setGroupedOrders={setGroupedOrders}
                  maxOrderAmount={maxOrderAmount}
                  lastOrder={groupedOrders.length === 1}
                  onCloseClick={() => {
                    onCloseClick();
                    setSplitOrder(false);
                  }}
                />
                );
              })}
              </>
                )}
            </>
              )
            : null}
        </ModalDialog>
        {partImage.open &&
          <DescriptionImageModalDialog
          data={partImage.data}
          image={partImage.data.Image}
          isOpen={partImage.open}
          onClose={() => setPartImage({
            open: false,
            data: {}
          })}
          />
        }
        <OrderProcessingModalDialog
          isOpen={loadingDialog}
          isProcessing={isProcessing}
          closeLoadingDialog={closeLoadingDialog}
          />
        {sliderModal && (
          <SliderOrderConfirmModalDialog
            title={'Order'}
            placeOrder={placeOrder}
            isOpen={sliderModal}
            setSliderModal={setSliderModal}
          />
        )}
      </>
  );
};

OrdersCartModalDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  partIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  orderData: PropTypes.any,
  onCloseClick: PropTypes.func.isRequired,
  onActionClick: PropTypes.func.isRequired,
  saveButtonText: PropTypes.string,
  maxOrderAmount: PropTypes.any,
  setMaxOrderAmount: PropTypes.func,
  splitOrder: PropTypes.bool,
  setSplitOrder: PropTypes.func
};

export default OrdersCartModalDialog;
