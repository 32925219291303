export const generateChartData = (data, datachartSettings, yAxisLabelsToHide) => {
  const nDataSource = [];
  const yAxis = [];
  data.forEach((item) =>
    Object.keys(item)
      .filter((key) => key !== 'timestamp')
      .forEach((key, i) => {
        const hideThisLabel = yAxisLabelsToHide.includes(key);
        let existingItem = nDataSource.find(
          (datapoint) => datapoint.name === key
        );
        if (!existingItem) {
          existingItem = {
            name: key,
            type: 'line',
            axisYIndex: i,
            showInLegend: false,
            color: `#${
                    datachartSettings.columnsColorDefinition[
                      key
                    ] ?? '000'
                  }`,
            dataPoints: [],
          };
          nDataSource.push(existingItem);
          const objYAxis = {
            labelFontColor: `#${
                    datachartSettings.columnsColorDefinition[
                      key
                    ] ?? '000'
                  }`,
            labelFontSize: 16,
            labelPlacement: 'outside',
            minimum: null,
            maximum: null,
            tickLength: hideThisLabel ? 0 : 8,
            reversed: false,
            labelFormatter: (e) => {
              if (hideThisLabel) {
                return '';
              }
              return e.value.toFixed(2);
            }
          };

          if (
            datachartSettings.columnsRange &&
                  datachartSettings.columnsRange[key]
          ) {
            if (
              datachartSettings.columnsRange[key].min
            ) {
              objYAxis.minimum =
                      datachartSettings.columnsRange[key].min;
            }
            if (
              datachartSettings.columnsRange[key].max
            ) {
              objYAxis.maximum =
                    datachartSettings.columnsRange[key].max;
            }
          }
          yAxis.push(objYAxis);
        }

        existingItem.dataPoints.push({
          y: (new Date(item.timestamp)).getTime(),
          x: item[key],
        });
      })
  );

  return { nDataSource, yAxis };
};
