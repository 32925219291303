import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGridApiRef, gridFilteredSortedRowEntriesSelector } from '@mui/x-data-grid-pro';
import pinGreen from '../../../assets/images/pin_green.png';
import pinRed from '../../../assets/images/pin_red.png';
import pinAmber from '../../../assets/images/pin_amber.png';
import pinBlue from '../../../assets/images/pin.png';
import Datagrid from '../../ui/Datagrid';
import { masterColumn } from './table_config';
import { Box, CircularProgress, Drawer, Grid, IconButton, TablePagination, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { ChevronRight } from '@mui/icons-material';
import { MAP_TYPES, REDUX_ACTIONS } from '../../../shared/config/constants';
import { fetchEnverusWellDetail, listEnverusWells } from '../../../store/actions/enverus/wells';
import ReactJson from 'react-json-view';
import styles from './styles';
import { Icon } from 'leaflet';
import Map from '../../map';

const pins = {
  blue: pinBlue,
  green: pinGreen,
  red: pinRed,
  amber: pinAmber,
};

// eslint-disable-next-line react/prop-types
function CustomPagination({ currentPage, limit, totalCount, handlePagination, loading }) {
  return (
    <TablePagination
      component="div"
      count={totalCount}
      page={currentPage}
      onPageChange={(event, page) => !loading && handlePagination(page)}
      rowsPerPage={limit}
      rowsPerPageOptions={[]}
    />
  );
}

const EnverusWells = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const wells = useSelector((state) => state.enverusWells);
  const user = useSelector((state) => state.currentUser);
  const mapRef = useRef(null);
  const [wellDetails, setWellDetails] = useState(false);
  const mapType = process.env.REACT_APP_ENVERUS_WELLS_PAGE_MAP;
  const [rowId, setRowId] = useState(null);
  const [gmapRowId, setGmapRowId] = useState(null);

  useEffect(() => {
    // this effect is used to update the map size when the user changes viewport to widescreen
    if (!mapRef?.current?.invalidateSize) return;
    mapRef.current.invalidateSize();
  }, [user.preferences.aspectRatioPage]);

  useEffect(() => {
    if (!wells.loading && wells.data.length === 0) {
      dispatch({
        type: REDUX_ACTIONS.ENVERUS_WELLS_LIST_LOADING,
      });
      dispatch(listEnverusWells());
    }
  }, []);

  const getMapPin = () => {
    const pinColor = 'red';
    const pinIcon = pinColor ? pins[pinColor] : pins.default;

    return (
      pinIcon &&
      new Icon({
        iconUrl: pinIcon,
        iconSize: [15, 25],
        popupAnchor: [0, -5],
      })
    );
  };

  const handleFetchWellDetails = (well) => {
    dispatch({
      type: REDUX_ACTIONS.ENVERUS_WELL_DETAILS_LOADING,
    });
    setWellDetails(true);
    dispatch(fetchEnverusWellDetail({ wellId: well?.WellID, completionId: well?.CompletionID }));
  };

  const handlePagination = (page) => {
    dispatch({
      type: REDUX_ACTIONS.ENVERUS_WELLS_SET_CURRENT_PAGE,
      payload: page
    });
    if (page > wells.currentPage) {
      dispatch(listEnverusWells({ nextPageLink: wells.nextPageLink }));
    } else {
      dispatch(listEnverusWells({ prevPageLink: wells.prevPageLink }));
    }
  };

  const getPinColor = (status) => {
    switch (status) {
      default:
        return 'red';
    }
  };

  const getGoogleMapPin = (status) => {
    const pinColor = getPinColor(status);
    const pinIcon = pinColor ? pins[pinColor] : pins.default;
    return {
      url: pinIcon,
      // eslint-disable-next-line no-undef
      scaledSize: new google.maps.Size(15, 25),
      // eslint-disable-next-line no-undef
      size: new google.maps.Size(15, 25)
    };
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
      <Map
        data={wells}
        mapType={mapType}
        mapData={wells.data}
        markerData={wells.data}
        latitudeField={'Latitude'}
        longitudeField={'Longitude'}
        mapPinField={'lastChangedStatus'}
        popUpLabel={['LeaseName']}
        getGoogleMapPin={getGoogleMapPin}
        getMapPin={getMapPin}
        gmapRowId={gmapRowId}
        rowId={rowId}
        referenceKey={'grid_id'}
      />
      </Grid>
      <Grid item xs={12}>
        <Datagrid
          apiRef={apiRef}
          data={wells.data}
          columns={masterColumn()}
          onCol
          loading={wells.loading}
          getRowId={(row) => row.grid_id}
          autoPageSize
          autoHeight={false}
          pagination={false}
          hideFooter
          onRowClick={({ row: well }) => {
            if (mapType === MAP_TYPES.GOOGLEMAP) {
              setGmapRowId(well._id);
            } else {
              setRowId(well);
            }
          }}
          onRowDoubleClick={({ row: well }) => {
            handleFetchWellDetails(well);
          }}
          experimentalFeatures={{ newEditingApi: true }}
          toolbar={{
            options: {
              columns: true,
              filters: true,
              export: false,
            },
          }}
          onFilterModelChange={() => {
            setTimeout(() => {
              const filterData = gridFilteredSortedRowEntriesSelector(apiRef.current.state, apiRef.current.instanceId).map(item => {
                return { ...item.model };
              });
              if (filterData) {
                dispatch({
                  type: REDUX_ACTIONS.ENVERUS_WELLS_MARKER_FILTER,
                  payload: {
                    data: filterData,
                  }
                });
              }
            }, 300);
          }}
        />
        <CustomPagination
          currentPage={wells.currentPage}
          limit={wells.limit}
          totalCount={wells.totalCount}
          handlePagination={handlePagination}
          loading={wells.loading}
        />
      </Grid>
      {wellDetails
        ? (
      <Drawer
      variant="temporary"
      open={wellDetails}
      onClose={() => setWellDetails(false)}
      anchor='right'
    >
      <Box>
        {wells.wellDetailsLoading
          ? (
          <Box className={classes.loadingContainer}>
            <CircularProgress />
          </Box>
            )
          : (
          <Box sx={{ marginLeft: 4 }}>
            <Typography variant='h6' sx={{ margin: '20px 0' }}>Well Details</Typography>
            <ReactJson
            src={wells.wellDetails}
            name={false}
            sortKeys={true}
            collapsed={false}
            displayDataTypes={false}
            quotesOnKeys={false}
            />
          <Box>
            <IconButton className={classes.menuTogglerBtn} onClick={() => setWellDetails(false)}>
              <ChevronRight sx={{ fontSize: '33px' }}/>
            </IconButton>
          </Box>
          </Box>
            )}
        </Box>
      </Drawer>
          )
        : null}
    </Grid>
  );
};

EnverusWells.propTypes = {
  row: PropTypes.object,
};

export default EnverusWells;
