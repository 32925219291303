const columns = (renderCell, renderJsonField) => [
  {
    field: 'lastUpdate',
    headerName: 'Timestamp',
    width: 250,
    hide: false,
    renderCell,
    headerAlign: 'center'
  },
  {
    field: 'ip',
    headerName: 'IP',
    width: 300,
    renderCell,
    headerAlign: 'center',
    align: 'center',
    valueGetter: params => params.row.payload.user?.ip || 'No data'
  },
  {
    field: 'caller',
    headerName: 'Caller',
    width: 300,
    renderCell: renderJsonField,
    headerAlign: 'center',
    align: 'left',
    sortable: false
  },
  {
    field: 'payload',
    headerName: 'Payload',
    width: 300,
    renderCell: renderJsonField,
    headerAlign: 'center',
    align: 'left',
    sortable: false
  },
];

export default columns;
