import { REDUX_ACTIONS } from '../../../shared/config/constants';

const initialState = {
  loading: false,
  deleting: false,
  data: [],
  errorMessage: '',
  loadingDelete: false,
  saved: false,
  paginatedElements: 0,
  executeSearch: false,
  selectedRows: [],
};

const epicorReleasesReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.EPICOR_SET_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: action.payload,
      };
    case REDUX_ACTIONS.EPICOR_RELEASES_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case REDUX_ACTIONS.EPICOR_RELEASES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        executeSearch: false,
        data: [...action.payload.epicorReleases],
        paginatedElements: action.payload.paginatedElements,
        errorMessage: '',
      };
    case REDUX_ACTIONS.EPICOR_RELEASES_LIST_ERROR:
      return {
        ...state,
        loading: false,
        executeSearch: false,
        errorMessage: action.payload.message,
        data: [],
      };
    case REDUX_ACTIONS.EPICOR_RELEASES_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default epicorReleasesReducer;
