import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  inputFieldMaxOrder: {
    fontSize: '16px',
    maxWidth: 200
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  displayQuants: {
    fontSize: '16px',
    fontWeight: 700,
  }
}));

export default styles;
