import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../ModalDialog';
import Datagrid from '../Datagrid';
import { Grid, IconButton, Typography } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import columns from './table_config';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../hooks/useAuth';
import { getUnassignedAssets } from '../../../store/actions/assets';
import { assignJobAssets, exportJobAssets, getJobAssets } from '../../../store/actions/jobs';
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
  CancelPresentationRounded,
  PrintRounded,
} from '@mui/icons-material';
import { UNASSIGNED_ASSET_JOB_ID, USER_TYPE } from '../../../utils/constants';
import styles from './styles';

const usersWithEditAccess = [USER_TYPE.PREMIUM_ADMIN, USER_TYPE.PREMIUM_REALTIME_MPD_ADMIN];

const AssignAssetsModalDialog = ({
  isOpen,
  title,
  selectedJob,
  onCloseClick,
}) => {
  const classes = styles();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const job = useSelector((state) => state.jobs.currentJob);
  const unassignedAssets = useSelector((state) => state.assets);
  const apiRef = useGridApiRef();
  const assignedJobsGridStateRef = useGridApiRef();
  const [assignDisabled, setAssignDisabled] = useState(true);
  const [unassignDisabled, setUnassignDisabled] = useState(true);
  const [isDirt, setIsDirt] = useState(false);

  useEffect(() => {
    if (isOpen && selectedJob._id) {
      dispatch(getJobAssets(selectedJob._id));
      dispatch(getUnassignedAssets());
    }
  }, [isOpen, selectedJob]);

  const handleDownloadCSV = () => {
    dispatch(exportJobAssets(selectedJob._id));
  };

  const getModalTitle = () => {
    return (
      <>
      <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {'Assets'}
          </Grid>
          <Grid item>
          <IconButton
              onClick={() => handleDownloadCSV()}
            >
              <PrintRounded fontSize='large'/>
            </IconButton>
          <IconButton
              onClick={onCloseClick}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const onDialogCloseClick = () => {
    const rowsIds = [];
    for (const key of apiRef.current.getSelectedRows().keys()) {
      rowsIds.push(key);
    }
    apiRef.current.selectRows(rowsIds, false);
    onCloseClick();
    setIsDirt(false);
  };

  const createDialogButtons = () => [
    {
      text: 'Done',
      placeholder: 'Done',
      'aria-label': 'Done',
      onClick: onDialogCloseClick,
    },
  ];

  const handleAssetsAssignations = () => {
    const assets = [];
    for (const asset of apiRef.current.getSelectedRows().values()) {
      assets.push(asset);
    }
    setIsDirt(true);
    dispatch(assignJobAssets(selectedJob._id, assets));
  };

  const handleAssetsUnassignations = () => {
    const assets = [];
    for (const asset of assignedJobsGridStateRef.current
      .getSelectedRows()
      .values()) {
      assets.push(asset);
    }
    setIsDirt(true);
    dispatch(assignJobAssets(UNASSIGNED_ASSET_JOB_ID, assets));
  };

  const validateUnassigment = () => {
    setUnassignDisabled(
      !usersWithEditAccess.includes(user.type) ||
      !assignedJobsGridStateRef.current?.getSelectedRows ||
        assignedJobsGridStateRef.current.getSelectedRows().size === 0
    );
  };

  const validateAssigment = () => {
    setAssignDisabled(
      !usersWithEditAccess.includes(user.type) ||
      !apiRef.current?.getSelectedRows ||
        apiRef.current.getSelectedRows().size === 0
    );
  };

  return (
    <ModalDialog
      title={title || getModalTitle()}
      isOpen={isOpen}
      onClose={onDialogCloseClick}
      buttons={isDirt ? createDialogButtons() : null}
    >
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        <Grid item xs={5.5} className={classes.subtitleContainer}>
          <Typography variant="body2">Unassigned</Typography>
        </Grid>
        <Grid item xs={0.5} />
        <Grid item xs={5.5} className={classes.subtitleContainer}>
          <Typography variant="body2">Assigned</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        <Grid item xs={5.5}>
          <Datagrid
            data={unassignedAssets.data}
            loading={unassignedAssets.loading}
            columns={columns}
            checkboxSelection
            disableRowSelectionOnClick
            apiRef={apiRef}
            onSelectionModelChange={validateAssigment}
          />
        </Grid>
        <Grid item xs={0.5} sx={{ display: 'flex', flexDirection: 'column' }}>
          <IconButton
            variant="contained"
            onClick={handleAssetsAssignations}
            disabled={assignDisabled}
          >
            <ArrowForwardIosRounded />
          </IconButton>
          <IconButton
            variant="contained"
            onClick={handleAssetsUnassignations}
            disabled={unassignDisabled}
          >
            <ArrowBackIosRounded />
          </IconButton>
        </Grid>
        <Grid item xs={5.5}>
          <Datagrid
            data={job.assets}
            columns={columns}
            checkboxSelection
            disableRowSelectionOnClick
            apiRef={assignedJobsGridStateRef}
            onSelectionModelChange={validateUnassigment}
          />
        </Grid>
      </Grid>
    </ModalDialog>
  );
};

// AssignAssetsModalDialog.defaultProps = {
//   selectedJob: {},
// };

AssignAssetsModalDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onCloseClick: PropTypes.func.isRequired,
  onActionClick: PropTypes.func.isRequired,
  selectedJob: PropTypes.object,
};

export default AssignAssetsModalDialog;
