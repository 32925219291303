import { REDUX_ACTIONS } from '../../../shared/config/constants';

const initialState = {
  loading: false,
  deleting: false,
  data: [],
  errorMessage: '',
  loadingDelete: false,
  saved: false,
  paginatedElements: 0,
  executeSearch: false,
};

const epicorOrderDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.EPICOR_ORDER_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case REDUX_ACTIONS.EPICOR_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        executeSearch: false,
        data: [...action.payload.epicorOrderDetails],
        paginatedElements: action.payload.paginatedElements,
        errorMessage: '',
      };
    case REDUX_ACTIONS.EPICOR_ORDER_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        executeSearch: false,
        errorMessage: action.payload.message,
        data: [],
      };
    case REDUX_ACTIONS.EPICOR_ORDER_DETAILS_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default epicorOrderDetailsReducer;
