import { makeStyles } from '@mui/styles';

const styles = makeStyles(({ spacing }) => ({
  printButton: {
    position: 'relative',
  },

  completeButton: {
    padding: `${spacing(1)} !important`,
    minWidth: '120px !important',
    fontSize: 20,
    backgroundColor: 'hsla(0, 0%, 50%, 1) !important',
  },

  sigCanvas: {
    backgroundColor: '#fff',
    width: '100%',
    border: 'solid thin #ccc',
  },

  refreshButton: {
    position: 'relative',
    marginTop: 25,
  },

  ticketsContainer: {
    height: '88vh',
    flexDirection: 'column',
    overflow: 'auto',
  },

  logo: {
    width: '18vh',
    border: 'none',
  },

  printedName: {
    backgroundColor: 'transparent',
    width: '100%',
    '&.MuiFilledInput-root:hover': {
      backgroundColor: 'transparent',
    },
    '&.MuiInputBase-root :hover': {
      backgroundColor: 'transparent',
    },
    fontSize: 'larger',
  },
}));

export default styles;
