const columns = (renderCell, renderActions, renderValueCell) => [
  {
    field: 'number',
    headerName: 'Number',
    width: 220,
    renderCell,
    editable: false,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'size',
    headerName: 'Size (in/32)',
    width: 220,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'actions',
    headerName: 'Actions',
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  },
];
export default columns;
