import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  addWrapper: {
    background: '#fff',
    paddingLeft: 10,
  },
  addButton: {
    fontSize: 'medium',
    fontWeight: 'bold',
    padding: 10
  }
}));

export default styles;
