import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => {
  return {
    tabsMenu: {
      color: '#000',
    },
    tabsMenuItem: {
      fontSize: '1.3rem',
      lineHeight: '32px',
    },
    scrollButtons: {
      '& svg': {
        transform: 'scale(1.8)',
      },
    }
  };
});

export default styles;
