const columns = () => [
  {
    field: '_id',
    headerName: '_id',
    hide: true,
  },
  {
    field: 'status',
    headerName: 'Status',
  },
  {
    field: 'message',
    headerName: 'Message',
    width: 800,
  }
];

export default columns;
