import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { REDUX_ACTIONS } from '../../../shared/config/constants';
import { useAuth } from '../../../hooks/useAuth';
import {
  listParts,
  listPartsSellerCompany,
} from '../../../store/actions/parts';
import { Box, Grid } from '@mui/material';
import { LIVE_HELP_USER_TYPES, USER_TYPE } from '../../../utils/constants';
import { useSearchParams } from 'react-router-dom';
import { PartReviewCard } from '../../ui';
import { isMobile } from '../../../utils/isMobile';
import InfiniteScrollList from '../ui/InfiniteScrollList';
import usePagination from '../../../hooks/usePagination';
import ScrollToTopButton from '../../ui/ScrollToTopButton';
import { stringifyDeepEqual } from '../../../utils/objectHelpers';
import { updateUserPreferences } from '../../../store/actions/authorization';
import LiveHelpButton from '../../ui/LiveHelp';

const PartsMobile = () => {
  const { user } = useAuth();
  const { searchTerm } = useSelector((state) => state.searchBox);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const category = searchParams.get('cat');
  const parts = useSelector((state) => state.parts);
  const [paginatedParts, setPaginatedParts] = useState([]);
  const [loader, setLoader] = useState(false);
  const { pageNumber, changePage, pageData, pageCount } = usePagination(parts.data, 10);
  const mobile = isMobile();

  const partsMounted = React.useRef(null);
  const storedShoppingCartsData = JSON.parse(localStorage.getItem('shoppingCarts'));

  useEffect(() => {
    let getPreferences;
    if (parts.loading && storedShoppingCartsData) {
      dispatch({
        type: REDUX_ACTIONS.ORDERS_SET_GREEN_LIST,
        payload: storedShoppingCartsData?.orders || [],
      });
      dispatch({
        type: REDUX_ACTIONS.ORDERS_SET_GRAY_LIST,
        payload: storedShoppingCartsData?.quotes || [],
      });
      return;
    }
    if (!parts.loading && (!stringifyDeepEqual(storedShoppingCartsData, user.preferences.shoppingCarts))) {
      getPreferences = setTimeout(() => {
        localStorage.setItem('shoppingCarts', JSON.stringify(user.preferences.shoppingCarts));
        if (partsMounted) {
          dispatch(updateUserPreferences());
        }
        partsMounted.current = true;
      }, 500);
    }
    return () => {
      clearTimeout(getPreferences);
      partsMounted.current = false;
    };
  }, [user.preferences.shoppingCarts, parts.loading]);

  useEffect(() => {
    if (parts.showFavorites && parts.partsFavorites.length === 0) {
      dispatch({
        type: REDUX_ACTIONS.PARTS_FAVORITES_FILTER_RESET,
      });
      dispatch({
        type: REDUX_ACTIONS.PARTS_LIST_FILTER,
      });
    }
  }, [parts.partsFavorites.length]);

  useEffect(() => {
    if (!parts.loading) {
      const companyId = searchParams.get('companyId');
      const vendorId = searchParams.get('vendorId');
      const customerName = searchParams.get('name');
      const customerRigUserId = searchParams.get('customerRigUser');
      if (
        (user.type === USER_TYPE.SELLER_MARKETPLACE_ADMIN || user.type === USER_TYPE.PREMIUM_ADMIN || user.type === USER_TYPE.PREMIUM_MARKETPLACE_ADMIN || user.type === USER_TYPE.CUSTOMER_USER || user.type === USER_TYPE.CUSTOMER_MANAGER || user.type === USER_TYPE.CUSTOMER_ADMIN || user.type === USER_TYPE.CUSTOMER_RIG) &&
        companyId &&
        vendorId
      ) {
        dispatch(listPartsSellerCompany(companyId, vendorId));
        dispatch({
          type: REDUX_ACTIONS.MENU_SET_TITLE,
          payload: customerName,
        });
      } else {
        dispatch({
          type: REDUX_ACTIONS.MENU_SET_TITLE,
          payload: customerName,
        });
        if (customerRigUserId) {
          dispatch(listParts(customerRigUserId));
        } else {
          dispatch(listParts());
        }
      }
    }
  }, []);

  useEffect(() => {
    if (!parts.loading && pageNumber !== 0) {
      setPaginatedParts((prev) => [...prev, ...pageData(pageNumber)]);
    }
  }, [pageNumber]);

  useEffect(() => {
    dispatch({
      type: REDUX_ACTIONS.PARTS_LIST_MOBILE_FILTER,
      payload: category,
    });
  }, [category, parts.loading]);

  useEffect(() => {
    if (!parts.loading && category && !parts.searchTerm) {
      setPaginatedParts(parts?.data?.filter((value) => value.Category === category).slice(0, 10));
      resetPageToStart();
    } else if (!parts.loading && (!category || parts.searchTerm)) {
      resetPageToStart();
      setPaginatedParts(parts.data.slice(0, 10));
    }
  }, [category, parts.loading, parts.data.length]);

  useEffect(() => {
    if (!parts.loading && searchTerm.length) {
      const newCategories = parts.data.map(item => item.Category).filter((value, index, self) => self.indexOf(value) === index);
      dispatch({
        type: REDUX_ACTIONS.PART_CATEGORIES_GET_LOADING,
      });
      dispatch({
        type: REDUX_ACTIONS.PART_CATEGORIES_GET_SUCCESS,
        payload: newCategories.sort(),
      });
    }
  }, [parts.data]);

  const resetPageToStart = () => {
    setLoader(true);
    changePage(0);
    goToTop();
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };

  const loadNextParts = () => {
    if (!loader && paginatedParts.length > 1 && pageNumber < pageCount) {
      setLoader(true);
      changePage(pageNumber + 1);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  };

  const goToTop = () => {
    const anchor = document.querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
        behavior: 'instant'
      });
    }
  };

  const setMobileClassification = (partId, newClassification) => {
    setPaginatedParts((prev) => [...prev.map((item) =>
      item._id === partId
        ? { ...item, Classification: newClassification }
        : { ...item }
    )]);
  };

  return (
  <Grid container sx={{ padding: '160px 0' }}>
     {parts.loading
       ? (
      <Box display='flex' justifyContent='center' alignItems='center' sx={{ height: '40vh', width: '100%' }}>
        <CircularProgress />
      </Box>
         )
       : null}
     <InfiniteScrollList
      currentPage={pageNumber}
      loadMore={loadNextParts}
      hasMore={!loader && paginatedParts.length > 1 && pageNumber < pageCount}
     >
    <Box id="back-to-top-anchor" />
     {paginatedParts?.map((item, index) => (
       <Box key={`part-card-${index}-${item._id}`} sx={{ marginTop: '14px', width: '100%', display: 'flex', justifyContent: 'center', listStyleType: 'none' }}>
        <PartReviewCard
          myimage={item.Image}
          pn={item.PN}
          description={item.Description}
          category={item.Category}
          name={item.Name}
          classification={item.Classification?.toString()}
          price={item.Price}
          itemId={item._id}
          isMobile={mobile}
          setMobileClassification={setMobileClassification}
          isFavorite={JSON.parse(item?.isFavorite)}
        />
       </Box>
     ))}
      </InfiniteScrollList>
      <ScrollToTopButton />
      {LIVE_HELP_USER_TYPES.includes(user?.type) && <LiveHelpButton type={user.type} isMobile={mobile} />}
    </Grid>
  );
};

PartsMobile.propTypes = {
  partIDs: PropTypes.arrayOf(PropTypes.string),
};

PartsMobile.defaultProps = {
  partIDs: [],
};

export default PartsMobile;
