/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Datagrid from '../ui/Datagrid';
import styles from './styles';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { masterColumns, detailColumns } from './table_config';
import { Box, Grid, Typography, IconButton } from '@mui/material';
import { listQuoteParts, downloadQuotePDF } from '../../store/actions/quotes';
import { isFullArray } from '../../utils/validators';
import ModalDialog from './../ui/ModalDialog/index';
import { useAuth } from './../../hooks/useAuth';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import { renderCell } from '../ui/Datagrid/table_config';
import DescriptionImageModalDialog from '../ui/DescriptionImageModalDialog';
import { CancelPresentationRounded } from '@mui/icons-material';
import { USER_TYPE } from '../../utils/constants';
import RenderStatusField from '../ui/RenderStatusField';
import ConfirmStatusUpdateDialog from '../ui/ConfirmStatusUpdateDialog';
import { updateOrderList } from '../../store/actions/orders';

const UsersQuotes = ({
  isOpen,
  title,
  data,
  onCloseClick,
  companyId,
  onModalCloseClick,
  userQuote
}) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { loading, grayPartsDetails, quotePartsPostSuccess } = useSelector(
    (state) => state.quotes
  );
  const [selectedRowId, setSelectedRowId] = useState(null);

  const quotes = data;
  const [partIDs, setPartIds] = useState([]);
  const [cart, setCart] = useState([]);
  const [counter, setCounter] = useState(0);
  const classes = styles();
  const apiRef = useGridApiRef();
  const [selectionModel, setSelectionModel] = useState(null);
  const quotesRedux = useSelector((state) => state.quotes);
  const [partImage, setPartImage] = useState({
    open: false,
    data: {}
  });
  const [rowData, setRowData] = useState({});
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);

  useEffect(() => {
    let QuoteCompanyId = companyId;
    if ((user.type === USER_TYPE.PREMIUM_ADMIN || user.type === USER_TYPE.CUSTOMER_MANAGER || user.type === USER_TYPE.CUSTOMER_ADMIN || user.type === USER_TYPE.PREMIUM_MARKETPLACE_ADMIN || user.type === USER_TYPE.SELLER_MARKETPLACE_ADMIN) && (userQuote.type === USER_TYPE.CUSTOMER_RIG)) {
      QuoteCompanyId = userQuote._id;
    }
    dispatch(listQuoteParts(QuoteCompanyId, partIDs));
  }, [partIDs, counter]);

  useEffect(() => {
    if (quotePartsPostSuccess) {
      dispatch({
        type: REDUX_ACTIONS.QUOTES_RESET,
      });
      onCloseClick();
    }
  }, [quotePartsPostSuccess]);

  useEffect(() => {
    setPartIds(quotes[0].partIDs);
    setRowData(quotes[0]);
    setSelectionModel(quotes[0]._id);
    setSelectedRowId(quotes[0]._id);
  }, []);

  useEffect(() => {
    if (isFullArray(grayPartsDetails)) {
      const cartDetails = partIDs
        .map((id) => {
          const part = grayPartsDetails.find((part) => part._id === id);
          if (part) {
            return {
              ...part,
              vendorName: quotes.find(quotes => quotes.partIDs.includes(id)).vendorName
            };
          }
          return null;
        })
        .filter((part) => !!part);
      setCart(cartDetails);
    }
  }, [grayPartsDetails]);

  const handleDownloadQoutePDF = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (selectedRowId !== null) {
      dispatch(downloadQuotePDF(rowData.orderNumber));
    }
  };

  const getModalTitle = () => {
    return (
      <>
       <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {title}
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                onCloseClick();
                onModalCloseClick();
              }}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderDescriptionCell = ({ value, row }) => (
    <Typography
      onClick={() => setPartImage({
        open: true, data: row
      })}
      style={{ fontSize: '12px', fontWeight: 'normal', textWrap: 'wrap', cursor: 'pointer' }}
    >{value}
    </Typography>
  );

  const handleConfirmStatus = (orderNumber, status, id) => {
    setSelectedQuote({ orderNumber, status, id });
    setConfirmDialog(true);
  };

  const handleConfirm = () => {
    dispatch(updateOrderList(quotes, selectedQuote, setSelectedQuote, 'quotes'));
  };

  return (
    <>
    <ModalDialog
      title={getModalTitle()}
      isOpen={isOpen}
      onClose={() => {
        onCloseClick();
        onModalCloseClick();
      }}
      dialogClass={classes.dialogModal}
    >
      <Grid container>
        <Grid item xs={12}>
          <Datagrid
            apiRef={apiRef}
            data={quotes}
            columns={masterColumns(
              ({ id, row }) => <RenderStatusField disabled={user?.type !== 'CUSTOMER_MANAGER'} id={id} row={row} handleStatus={handleConfirmStatus} />
            )}
            onSelectionModelChange={(e) => {
              setSelectionModel(e);
            }}
            selectionModel={selectionModel}
            className={classes.qoutesDatagrid}
            onRowClick={(params, evt) => {
              evt.preventDefault();
              evt.stopPropagation();
              setRowData(params.row);
              if (selectedRowId !== params.id) {
                setCart([]);
                setPartIds(params.row.partIDs);
                setSelectedRowId(params.id);
                setCounter((state) => state + 1);
              }
            }}
            disableStickyHeader={false}
            autoHeight={false}
            autoheight
            height={'30vh'}
            pageSize={100}
            toolbar={{
              options: {
                columns: false,
                filters: true,
                density: false,
                export: false,
              },
            }}
          />
          <Box className={classes.quoteTitleBox}>
            <Grid item xs={6}>
              <Typography textAlign="left" variant="h6">
                Quote Details
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.quoteBoxWrapper}>
                { selectedRowId !== null
                  ? <IconButton
                    edge="end"
                    onClick={handleDownloadQoutePDF}
                    disabled={quotesRedux.printState === 'loading'}
                  >
                    <PrintOutlinedIcon fontSize='large' />
                  </IconButton>
                  : null
                }
            </Grid>
          </Box>
          <Grid item xs={12}>
            <Datagrid
              loading={loading}
              data={cart}
              className={classes.selectedQuoteDatagrid}
              columns={detailColumns(renderCell, user.type, renderDescriptionCell)}
              onRowClick={(_, evt) => {
                evt.stopPropagation();
              }}
              getRowId={(row) => `${Math.random(1000)}_${row._id}`}
              disableStickyHeader={false}
              autoHeight={false}
              autoheight
              height={'35vh'}
              pageSize={100}
              getRowHeight={() => 'auto'}
              toolbar={{
                options: {
                  columns: false,
                  filters: true,
                  density: false,
                  export: false,
                },
              }}
              sx={{
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </ModalDialog>
    {partImage.open &&
      <DescriptionImageModalDialog
        data={partImage.data}
        image={partImage.data.Image}
        isOpen={partImage.open}
        onClose={() => setPartImage({
          open: false,
          data: {}
        })}
      />
    }
    {confirmDialog
      ? (
        <ConfirmStatusUpdateDialog
          open={confirmDialog}
          handleClose={() => {
            setConfirmDialog(false);
            setSelectedQuote(null);
          }}
          selectedData={selectedQuote}
          handleConfirm={handleConfirm}
        />
        )
      : null}
    </>
  );
};

export default React.memo(UsersQuotes);
