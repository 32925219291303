import { makeStyles } from '@mui/styles';
const styles = makeStyles(() => ({
  chartContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'safe center',
    overflowX: 'scroll !important',
    width: '100%',
    flex: 1,
    '&::-webkit-scrollbar': {
      width: '12px',
      backgroundColor: '#F5F5F5'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
      backgroundColor: '#F5F5F5'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#CCC',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
    }
  }
}));

export default styles;
