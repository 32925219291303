import React from 'react';
import { Typography } from '@mui/material';
import CurrencyFormat from 'react-currency-format';
import PropTypes from 'prop-types';

const Price = ({ mount, fixedDecimalScale }) => {
  return (
    <Typography align="right" variant="h5" color="text.secondary">
      <CurrencyFormat
        value={mount}
        decimalScale={fixedDecimalScale ? 2 : 0}
        fixedDecimalScale={fixedDecimalScale}
        displayType={'text'}
        thousandSeparator={true}
        prefix={'$'}
      />
    </Typography>
  );
};

Price.propTypes = {
  mount: PropTypes.string.isRequired,
  fixedDecimalScale: PropTypes.bool,
};

export default Price;
