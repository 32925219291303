import * as React from 'react';
import styles from './styles';
import {
  AppBar,
  Box,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import Premium from '../../../../assets/images/PRO logo PMS green only.svg';
import SearchIcon from '@mui/icons-material/Search';
import SearchScreen from '../../../marketplace/search';
import { MARKETPLACE_URL } from '../../../../shared/config/config';

export default function MarketplaceNavbar() {
  const classes = styles();
  const [showSearch, setShowSearch] = React.useState(false);

  return (
      <>
          <Box className={classes.fixedAppbar} sx={{ background: '#000' }}>
              <AppBar elevation={0} position="static" className={classes.toolBar}>
                  <Grid container justifyContent='space-between' alignItems='center'>
                      <Box
                          component="img"
                          alt="Logo"
                          src={Premium}
                          className={classes.logo}
                      />
                      <Box display='flex' alignItems='center'>
                          <Typography>
                            <a href={MARKETPLACE_URL} className={classes.text}>Shop</a>
                          </Typography>
                          <IconButton onClick={() => setShowSearch(true)}>
                              <SearchIcon className={classes.icon} />
                          </IconButton>
                      </Box>
                  </Grid>
              </AppBar>
          </Box>
          {showSearch && (
              <SearchScreen
                  open={true}
                  handleClose={() => setShowSearch(false)}
              />
          )}
      </>
  );
}
