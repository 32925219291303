import { useState } from 'react';

function usePagination(items, pageLimit) {
  const [pageNumber, setPageNumber] = useState(0);
  const pageCount = Math.ceil(items.length / pageLimit);

  const changePage = (pN) => {
    setPageNumber(pN);
  };

  const pageData = () => {
    const s = pageNumber * pageLimit;
    const e = s + pageLimit;
    return items.slice(s, e);
  };

  return {
    pageNumber,
    pageCount,
    changePage,
    pageData,
  };
}

export default usePagination;
