import { makeStyles } from '@mui/styles';
const styles = makeStyles(() => ({
  customTool: {
    marginLeft: '13px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    cursor: 'pointer',
    marginTop: '10px'
  },
  dialogTitle: {
    lineHeight: '2 !important',
    padding: '8px 10px !important',
  },
  dialogModal: {
    '& .MuiPaper-root': {
      marginLeft: '10px',
      '& .makeStyles-dialogTitle-37': {
        padding: '16px 12px !important',
      },
    },
    '& .MuiTypography-subtitle1': {
      fontSize: '1.3rem',
      fontWeight: 'bold',
    },
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
    fontSize: '18px',
    cursor: 'pointer'
  },
  inputField: {
    width: '100%'
  },
  headerCell: {
    border: '1px solid grey',
    backgroundColor: 'white',
    color: 'black',
    fontWeight: 'bold',
    padding: '4px',
    height: '20px'
  },
  rowCell: {
    border: '1px solid grey',
    backgroundColor: 'white',
    color: 'black',
    padding: '4px',
    height: '20px'
  },
  tableContainer: {
    maxWidth: 600,
    height: 'auto',
    maxHeight: 730
  },
  menuTogglerBtn: {
    position: 'absolute',
    bottom: '4vh',
    zIndex: 2000,
    right: '-20px',
    backgroundColor: '#e0e0d1',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: '#c3c3c3',
    },
  },
  menuTogglerTableBtn: {
    position: 'absolute',
    bottom: '4vh',
    zIndex: 2000,
    right: '32%',
    backgroundColor: '#e0e0d1',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: '#c3c3c3',
    },
  },
}));
export default styles;
