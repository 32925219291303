import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Datagrid from '../ui/Datagrid';
import { gridClasses } from '@mui/x-data-grid';
import columns from './table_config';
import { listAlarmHistory } from '../../store/actions/alarmHistory';
import { Grid, TablePagination } from '@mui/material';
import { renderCell } from '../ui/Datagrid/table_config';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { REDUX_ACTIONS } from '../../shared/config/constants';

// eslint-disable-next-line react/prop-types
function CustomPagination({ currentPage, limit, totalCount, handlePagination, loading }) {
  return (
    <TablePagination
      component="div"
      count={totalCount}
      page={currentPage}
      onPageChange={(event, page) => !loading && handlePagination(page)}
      rowsPerPage={limit}
      rowsPerPageOptions={[]}
    />
  );
}

const AlarmHistory = () => {
  const alarmHistory = useSelector((state) => state.alarmHistory);
  const apiRef = useGridApiRef();
  const [filter, setFilter] = useState([]);
  const dispatch = useDispatch();

  const handlePagination = (page) => {
    if (page < alarmHistory.currentPage) {
      dispatch(listAlarmHistory({ page: alarmHistory.currentPage - 1 }));
      dispatch({
        type: REDUX_ACTIONS.ALARM_HISTORY_SET_CURRENT_PAGE,
        payload: alarmHistory.currentPage - 1
      });
    } else {
      dispatch(listAlarmHistory({ page: alarmHistory.currentPage + 1 }));
      dispatch({
        type: REDUX_ACTIONS.ALARM_HISTORY_SET_CURRENT_PAGE,
        payload: page + 1
      });
    }
    apiRef.current.setFilterModel({
      items: filter
    });
    apiRef.current.scrollToIndexes({
      rowIndex: 0,
      colIndex: 0,
    });
  };

  useEffect(() => {
    if (filter.length === 0 && alarmHistory.data.length) {
      dispatch(listAlarmHistory());
    }
  }, [filter]);

  const renderJsonField = ({ value }) => {
    let data = {};
    if (typeof value === 'object' || (Array.isArray(value) && value !== null)) {
      data = value;
    }
    return (
      <ReactJson src={data} name={false} collapsed={true} sortKeys={true} />
    );
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Datagrid
          apiRef={apiRef}
          data={alarmHistory?.data}
          autoHeight={false}
          columns={columns(renderCell, renderJsonField)}
          loading={alarmHistory.loading}
          disableStickyHeader={false}
          onRowClick={() => {}}
          height={'90vh'}
          getRowId={(row) => row.grid_id}
          getRowHeight={() => 'auto'}
          autoPageSize
          pagination={false}
          hideFooter
          experimentalFeatures={{ newEditingApi: true }}
          filterMode="server"
          onFilterModelChange={ (model) => {
            setFilter(model.items);
            const items = model.items.map(filter => {
              if (filter.columnField === 'rig' || filter.columnField === 'message' || filter.columnField === 'details') {
                return { ...filter, columnField: 'details.' + filter.columnField };
              } else {
                return filter;
              }
            });
            const filterModels = { ...model, items };
            dispatch({
              type: REDUX_ACTIONS.ALARM_HISTORY_SET_FILTER,
              payload: filterModels.items.length ? filterModels : {}
            });
          }}
          sortingMode="server"
          onSortModelChange={(sortModel) => {
            const sort = sortModel.reduce((result, item) => {
              if (item.field === 'rig' || item.field === 'message' || item.field === 'details') {
                result['details.' + item.field] = item.sort;
              } else {
                result[item.field] = item.sort;
              }
              return result;
            }, {});
            dispatch({
              type: REDUX_ACTIONS.ALARM_HISTORY_SET_SORT,
              payload: sort
            });
            dispatch({
              type: REDUX_ACTIONS.ALARM_HISTORY_SET_CURRENT_PAGE,
              payload: 1
            });
            dispatch(listAlarmHistory());
          }}
          sx={{
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold !important',
            },
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
          }}
          toolbar={{
            options: {
              columns: true,
              filters: true,
              export: false,
            },
          }}
        />
         <CustomPagination
          currentPage={alarmHistory.currentPage - 1}
          limit={alarmHistory.limit}
          totalCount={alarmHistory.totalCount}
          handlePagination={handlePagination}
          loading={alarmHistory.loading}
        />
      </Grid>
    </Grid>
  );
};

AlarmHistory.propTypes = {
  row: PropTypes.object,
};

export default AlarmHistory;
