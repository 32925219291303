import { makeStyles } from '@mui/styles';

const styles = makeStyles(({ breakpoints, spacing }) => ({
  media: {},

  card: {
    position: 'relative',
  },
  cardHeader: {
    fontSize: '1.3rem',
  },
  root: {
    margin: '0 auto',
    width: 500,
  },
  overlay: {
    top: '150px',
    color: 'black',
    position: 'absolute',
    backgroundColor: 'white',
    width: '100%',
    padding: spacing(2),
    opacity: 0.8,
    fontSize: 14,
    fontWeight: 'bold',
  },
  cardActionsMain: {
    padding: 0,
    marginBottom: spacing(1),
  },
  cardActionsB: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: spacing(1),
  },
  shoppingButon: {
    [breakpoints.down('sm')]: {
      padding: `${spacing(1.4)} !important`,
      margin: '0 4px'
    },
    paddingLeft: `${spacing(1)} !important`,
    paddingRight: `${spacing(1)} !important`,
    minWidth: 'unset !important',
    backgroundColor: 'hsla(0, 0%, 50%, 1) !Important',
    '&:disabled': {
      backgroundColor: 'hsla(0, 0%, 75%, 0.5) !Important',
    },
  },
  shareButon: {
    paddingLeft: `${spacing(1)} !important`,
    paddingRight: `${spacing(1)} !important`,
    minWidth: 'unset !important',
  },
  classificationIcon: {
    margin: spacing(1),
  },
}));

export default styles;
