import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import proGeoBlueIcon from '../../assets/images/pro_geo_blue.png';
import pinRed from '../../assets/images/pin_red.png';
import pinGreen from '../../assets/images/pin_green.png';
import pinAmber from '../../assets/images/pin_amber.png';
import pistonAmber from '../../assets/images/piston_amber.png';
import pistonGreen from '../../assets/images/piston_green.png';
import pistonRed from '../../assets/images/piston_red.png';
import pinBlue from '../../assets/images/pin.png';
import proGeoGreen from '../../assets/images/pro_geo_green.png';
import proGeoAmber from '../../assets/images/pro_geo_amber.png';
import proGeoRed from '../../assets/images/pro_geo_red.png';

import Datagrid from '../ui/Datagrid';
import { masterColumn } from './table_config';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { MAP_TYPES, REDUX_ACTIONS } from '../../shared/config/constants';
import { Icon } from 'leaflet';
import Map from '../map';
import styles from './styles';
import { listDbwVsTarget } from '../../store/actions/dbwVsTarget';

const pins = {
  checkInsIcon: proGeoBlueIcon,
  rigIcon: pinRed
};

const mpeDbwPins = {
  green: pistonGreen,
  red: pistonRed,
  amber: pistonAmber,
};

const nonMpeDbwPins = {
  green: pinGreen,
  red: pinRed,
  amber: pinAmber,
};

const dbwPins = {
  green: proGeoGreen,
  red: proGeoRed,
  amber: proGeoAmber,
};

const targetPin = pinBlue;

const DbwVsTarget = () => {
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const checkIns = useSelector((state) => state.checkIns);
  const user = useSelector((state) => state.currentUser);
  const { data: dbwVsTarget, loading, marker_data: dbwVsTargetMarkerData } = useSelector((state) => state.dbwVsTarget);
  const mapRef = useRef(null);
  const mapType = process.env.REACT_APP_DBWVSTARGET_PAGE_MAP;
  const [rowId, setRowId] = useState(null);
  const [gmapRowId, setGmapRowId] = useState(null);
  const [iconPinSize, setIconPinSize] = useState([15, 25]);
  const classes = styles();
  const { showDbwPins } = useSelector((state) => state.dbwVsTarget);
  const pageSize = 10;

  const resetCheckinsData = () => {
    dispatch({
      type: REDUX_ACTIONS.CHECKINS_RESET
    });
  };

  useEffect(() => {
    resetCheckinsData();
    return () => {
      resetCheckinsData();
    };
  }, []);

  useEffect(() => {
    // this effect is used to update the map size when the user changes viewport to widescreen
    if (!mapRef?.current?.invalidateSize) return;
    mapRef.current.invalidateSize();
  }, [user.preferences.aspectRatioPage]);

  useEffect(() => {
    if (!loading) {
      dispatch({
        type: REDUX_ACTIONS.DBW_VS_TARGET_LIST_LOADING,
      });
      dispatch(listDbwVsTarget());
    }
  }, []);

  useEffect(() => {
    const filterModel = Object.entries(showDbwPins)
      .filter(([, isVisible]) => isVisible)
      .map(([className], index) => ({
        columnField: 'class',
        operatorValue: 'equals',
        value: className,
        id: `filter-${index}`,
      }));

    apiRef.current.setFilterModel({
      items: filterModel,
      linkOperator: 'or',
    });
  }, [showDbwPins]);

  const onFilterModelChange = (model) => {
    const newShowDbwPins = {
      'MPE DBW': false,
      'NON-MPE DBW': false,
      DBW: false,
      TARGET: false,
    };

    model.items.forEach((item) => {
      if (item.columnField === 'class' && item.operatorValue === 'equals') {
        newShowDbwPins[item.value] = true;
      }
    });
    if (JSON.stringify(newShowDbwPins) !== JSON.stringify(showDbwPins)) {
      dispatch({
        type: REDUX_ACTIONS.DBW_VS_TARGET_MARKER_DATA,
        payload: newShowDbwPins,
      });
    }
  };

  const getMapPin = () => {
    const pinColor = 'checkInsIcon';
    const pinIcon = pinColor ? pins[pinColor] : pins.default;

    return (
      pinIcon &&
      new Icon({
        iconUrl: pinIcon,
        iconSize: iconPinSize,
        popupAnchor: [0, -5],
      })
    );
  };

  const getColorFromLastCheckIn = (lastCheckedStatus) => {
    if (lastCheckedStatus && lastCheckedStatus === 1) {
      return 'green';
    } else if (lastCheckedStatus && lastCheckedStatus === 2) {
      return 'amber';
    } else if (lastCheckedStatus && lastCheckedStatus === 3) {
      return 'red';
    }
  };

  const getMapPin2 = (classType, lastCheckedStatus) => {
    let pinIcon;
    let pinColor;

    if (classType === 'MPE DBW') {
      pinColor = lastCheckedStatus ? getColorFromLastCheckIn(lastCheckedStatus) : 'red';
      pinIcon = mpeDbwPins[pinColor];
    } else if (classType === 'NON-MPE DBW') {
      pinColor = lastCheckedStatus ? getColorFromLastCheckIn(lastCheckedStatus) : 'red';
      pinIcon = nonMpeDbwPins[pinColor];
    } else if (classType === 'DBW') {
      pinColor = lastCheckedStatus ? getColorFromLastCheckIn(lastCheckedStatus) : 'red';
      pinIcon = dbwPins[pinColor];
    } else if (classType === 'TARGET') {
      pinIcon = targetPin;
    } else {
      pinIcon = pins.rigIcon;
    }

    return (
      pinIcon &&
      new Icon({
        iconUrl: pinIcon,
        iconSize: iconPinSize,
        popupAnchor: [0, -5],
        className: classType === 'TARGET' ? classes.targetIconPopup : classes.highlightIcon,
      })
    );
  };

  const getPinColor = (status) => {
    switch (status) {
      default:
        return 'red';
    }
  };

  const getGoogleMapPin = (status) => {
    const pinColor = getPinColor(status);
    const pinIcon = pinColor ? pins[pinColor] : pins.default;
    return {
      url: pinIcon,
      // eslint-disable-next-line no-undef
      scaledSize: new google.maps.Size(iconPinSize[0], iconPinSize[1]),
      // eslint-disable-next-line no-undef
      size: new google.maps.Size(iconPinSize[0] + 10, iconPinSize[1] + 10)
    };
  };

  const handleMarkerClick = (data) => {
    setRowId(null);
    const row = dbwVsTarget.find((row) => row.grid_id === data.grid_id);
    if (row) {
      const rowIndex = dbwVsTarget.findIndex((r) => r.grid_id === data.grid_id);
      const pageIndex = Math.floor(rowIndex / pageSize);
      if (rowIndex !== -1) {
        apiRef.current.scrollToIndexes({
          rowIndex,
          colIndex: 0,
        });
        apiRef.current.setPage(pageIndex);
      }
      setRowId(row);
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
      <Map
        data={dbwVsTarget}
        mapType={mapType}
        mapData={dbwVsTarget}
        mapPinField1={'class'}
        mapPinField2={'lastCheckedStatus'}
        mapPinField3={'grid_id'}
        markerData={dbwVsTargetMarkerData}
        latitudeField={'lat'}
        longitudeField={'long'}
        popUpLabel={['RigName_Number', 'MPESpend', 'NonMPESpend', 'class', 'LastCheckIn']}
        excludePopupLabel={true}
        mapData3={checkIns.data}
        markerData3={checkIns.data}
        latitudeField3={'lat'}
        longitudeField3={'long'}
        tertiaryPopup={['BinNum', 'UserEmail', 'comment']}
        getGoogleMapPin={getGoogleMapPin}
        getMapPin={getMapPin2}
        getMapPin3={getMapPin}
        gmapRowId={gmapRowId}
        rowId={rowId}
        referenceKey={'grid_id'}
        setIconPinSize={setIconPinSize}
        onMarkerClick={handleMarkerClick}
        showCheckinsToggleButton={true}
        showDbwTargetToggleButtons={true}
      />
      </Grid>
      <Grid item xs={12}>
        <Datagrid
          apiRef={apiRef}
          data={dbwVsTarget}
          columns={masterColumn()}
          loading={loading}
          getRowId={(row) => row.grid_id}
          pageSize={pageSize}
          autoHeight={false}
          onRowClick={({ row: well }) => {
            if (mapType === MAP_TYPES.GOOGLEMAP) {
              setGmapRowId(well._id);
            } else {
              setRowId(well);
            }
          }}
          sx={{
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold !important',
            },
            '.MuiDataGrid-cell': {
              fontSize: 'medium',
              fontWeight: 'bold !important'
            },
          }}
          experimentalFeatures={{ newEditingApi: true }}
          toolbar={{
            options: {
              columns: true,
              filters: true,
              export: {
                enabled: true
              },
            },
          }}
          disableSelectionOnClick
          getRowClassName={(params) => (params.id === rowId?.grid_id ? classes.highlightRow : '')}
          onFilterModelChange={onFilterModelChange}
          />
      </Grid>
    </Grid>
  );
};

DbwVsTarget.propTypes = {
  row: PropTypes.object,
};

export default DbwVsTarget;
