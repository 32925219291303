import React, { useEffect, useRef } from 'react';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { CancelPresentationRounded } from '@mui/icons-material';
import { Box, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CanvasJSReact from '../canvasjs/canvasjs.react';
import { SPEND_CHART_FILTER } from '../../../utils/constants';
import { getSpendChartData } from '../../../store/actions/spend';
import ModalDialog from '../ModalDialog';
import styles from './styles';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const chartDefaults = {
  exportEnabled: true,
  animationEnabled: true,
  theme: 'light2',
};

const SpendChartDialog = ({ open, handleClose, rowData, type }) => {
  const { data, loading, filterType, average, total } = useSelector((state) => state.spend.spendChartData);
  const dispatch = useDispatch();
  const chartRef = useRef();
  const classes = styles();

  useEffect(() => {
    dispatch(getSpendChartData(type, rowData?._id, 'year'));
  }, []);

  const getModalTitle = () => {
    return (
      <>
        <Grid className={classes.flexAlign} justifyContent="space-between">
          <Grid item className={classes.flexAlign}>
            <Grid>
            <Typography
                  variant='body1'
                  className={classes.text}>
                      {rowData.name || rowData.firstName}
                  </Typography>
            </Grid>
            <Grid className={classes.flexAlign} style={{ display: !data.length && 'none' }}>
                 <Typography
                  variant='body1'
                  className={classes.text}>
                    Total: ${total}
                  </Typography>
                  <Typography
                  variant='body1'
                  className={classes.text}>
                    Average: ${average}
                  </Typography>
                 </Grid>
          </Grid>
          <Grid item className={classes.flexAlign}>
            <Grid sx={{ mr: 1 }}>
              <FormControl
                fullWidth
                sx={{ bgcolor: 'transparent', minWidth: '100px', p: 0, m: 0 }}
              >
                <InputLabel id={'filter-input-label'}>Filter</InputLabel>
                <Select
                  labelId={'filter-select-id'}
                  id={'filter-id'}
                  value={filterType}
                  label='Filter'
                  onChange={(e) => {
                    dispatch(getSpendChartData(type, rowData?._id, e.target.value));
                  }}
                  disabled={loading}
                  sx={{
                    backgroundColor: 'unset',
                  }}
                >
                  {SPEND_CHART_FILTER?.map((item, idItem) => (
                    <MenuItem key={`item_${item.value}_${idItem}`} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <IconButton
              onClick={handleClose}
            >
              <CancelPresentationRounded fontSize='large' />
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <React.Fragment>
      <ModalDialog
        title={getModalTitle()}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        isOpen={open}
        fullWidth
        maxWidth='lg'
      >
        <DialogContent>
        {loading
          ? (
          <Grid container justifyContent={'center'} alignItems={'center'} sx={{ height: '400px' }}>
            <CircularProgress />
          </Grid>
            )
          : (
          <>
            {data?.length
              ? (
              <>
                <Box sx={{ flex: 1, marginTop: '10px' }}>
                  <CanvasJSChart
                  ref={chartRef}
                  options={{
                    ...chartDefaults,
                    dataPointWidth: 50,
                    height: 400,
                    axisX: {
                      interval: data.length > 1 && 1,
                      ...(filterType === 'year'
                        ? {
                            intervalType: 'month',
                            valueFormatString: 'MMMM'
                          }
                        : {
                            labelAngle: -50,
                            intervalType: 'day',
                            valueFormatString: 'D MMM Y'
                          })
                    },
                    axisY: {
                      title: 'Spend',
                      includeZero: true,
                    },
                    data: [{
                      type: filterType === 'year' ? 'column' : 'line',
                      dataPoints: data,
                      indexLabelPlacement: 'outside',
                      indexLabelFontColor: '#000',
                      indexLabelFontWeight: 'bold',
                      indexLabelFontSize: 12,
                      // eslint-disable-next-line no-template-curly-in-string
                      toolTipContent: '{x}: ${y}'
                    },
                    ]
                  }} />
                </Box>
              </>
                )
              : (
              <Typography variant='h6' sx={{ display: 'grid', placeItems: 'center', height: '400px' }}>
                No data to show for this {filterType}
              </Typography>
                )}
          </>
            )}
        </DialogContent>
      </ModalDialog>
    </React.Fragment>
  );
};

SpendChartDialog.propTypes = {
  open: PropTypes.bool,
  rowData: PropTypes.object,
  handleClose: PropTypes.func,
  type: PropTypes.string
};

export default SpendChartDialog;
