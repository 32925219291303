import { REDUX_ACTIONS } from '../../shared/config/constants';

const initialState = {
  loading: false,
  errorMessage: '',
  data: [],
  marker_data: [],
  paginatedElements: 0,
  searchTerm: '',
  initialData: [],
  syncResponseMessage: '',
  checkInsDetails: {},
  checkInsDetailsLoading: false,
  checkInsDetailsError: '',
  nextPageLink: '',
  prevPageLink: '',
  limit: 100,
  currentPage: 1,
  totalCount: 0,
  searchKey: null,
  startDate: null,
  endDate: null,
  filters: [],
  sort: null,
  checkInsEmailKPIsLoading: false,
  checkInsEmailKPIs: [],
  checkInsDivisionKPIsLoading: false,
  checkInsDivisionKPIs: [],
  division: 'ALL'
};

const checkInsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.CHECKINS_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };

    case REDUX_ACTIONS.CHECKINS_DETAILS_LOADING:
      return {
        ...state,
        checkInsDetails: {},
        checkInsDetailsLoading: true,
        checkInsDetailsError: '',
      };

    case REDUX_ACTIONS.CHECKINS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...action.payload.data],
        marker_data: [...action.payload.data],
        initialData: [...action.payload.data],
        totalCount: action.payload.totalCount,
        paginatedElements: action.payload.paginatedElements,
        errorMessage: '',
      };

    case REDUX_ACTIONS.CHECKINS_DETAILS_SUCCESS:
      return {
        ...state,
        checkInsDetails: action.payload,
        checkInsDetailsLoading: false,
        checkInsDetailsError: '',
      };

    case REDUX_ACTIONS.CHECKINS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: [],
        marker_data: []
      };

    case REDUX_ACTIONS.CHECKINS_DETAILS_ERROR:
      return {
        ...state,
        checkInsDetails: {},
        checkInsDetailsLoading: false,
        checkInsDetailsError: action.payload.message,
      };

    case REDUX_ACTIONS.CHECKINS_SEARCH_CHANGE:
      return {
        ...state,
        searchTerm: action.payload.value,
      };

    case REDUX_ACTIONS.CHECKINS_FILTER:
      return {
        ...state,
        data: [
          ...state.initialData.filter((item) =>
            item?.ENVBasin?.toUpperCase().includes(state.searchTerm.toUpperCase())
          ),
        ],
        marker_data: [
          ...state.initialData.filter((item) =>
            item?.ENVBasin?.toUpperCase().includes(state.searchTerm.toUpperCase())
          ),
        ],
      };

    case REDUX_ACTIONS.CHECKINS_DIVISION_FILTER:
      return {
        ...state,
        data: action.payload === 'ALL'
          ? [...state.initialData]
          : [
              ...state.initialData.filter((item) =>
                item?.division?.toUpperCase().includes(action.payload.toUpperCase())
              ),
            ],
        marker_data: action.payload === 'ALL'
          ? [...state.initialData]
          : [
              ...state.initialData.filter((item) =>
                item?.division?.toUpperCase().includes(action.payload.toUpperCase())
              ),
            ],
      };

    case REDUX_ACTIONS.CHECKINS_MARKER_FILTER:
      return {
        ...state,
        marker_data: [
          ...action.payload.data
        ],
      };

    case REDUX_ACTIONS.CHECKINS_RESET:
      return {
        ...initialState,
      };

    case REDUX_ACTIONS.CHECKINS_SYNC_LOADING:
      return {
        ...state,
        loadingCheckInsSync: true,
        syncResponseMessage: action.payload.message,
      };
    case REDUX_ACTIONS.CHECKINS_SEARCH_CONTROL:
      return {
        ...state,
        searchKey: action.payload.searchKey,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        division: action.payload.division
      };
    case REDUX_ACTIONS.CHECKINS_SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case REDUX_ACTIONS.CHECKINS_SET_FILTER:
      return {
        ...state,
        filters: action.payload
      };
    case REDUX_ACTIONS.CHECKINS_SET_SORT:
      return {
        ...state,
        sort: action.payload
      };
    case REDUX_ACTIONS.CHECKINS_SYNC_IDLE:
      return {
        ...state,
        loadingCheckInsSync: false,
        syncResponseMessage: action.payload.message,
      };

    case REDUX_ACTIONS.CHECKINS_SYNC_ERROR:
      return {
        ...state,
        loadingCheckInsSync: false,
        syncResponseMessage: action.payload.message,
      };

    case REDUX_ACTIONS.CHECK_INS_EMAIL_KPIS_LOADING:
      return {
        ...state,
        checkInsEmailKPIsLoading: true,
        errorMessage: '',
      };
    case REDUX_ACTIONS.CHECK_INS_EMAIL_KPIS_SUCCESS:
      return {
        ...state,
        checkInsEmailKPIs: action.payload,
        errorMessage: '',
        checkInsEmailKPIsLoading: false,
      };
    case REDUX_ACTIONS.CHECK_INS_DIVISION_KPIS_SUCCESS:
      return {
        ...state,
        checkInsDivisionKPIs: action.payload,
        errorMessage: '',
        checkInsDivisionKPIsLoading: false,
      };

    default:
      return state;
  }
};

export default checkInsReducer;
