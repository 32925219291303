import { makeStyles } from '@mui/styles';

const styles = makeStyles(({ spacing }) => ({
  pressed: {
    border: 'solid 1px gray',
    color: 'gray',
    backgroundColor: 'lightgray !important',
    borderRadius: spacing(0.5),
    padding: 0,
    marginRight: '10px'
  },
  iconSpace: {
    padding: 0,
    marginRight: '10px'
  }
}));

export default styles;
