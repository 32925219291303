const columns = (renderCell, renderProfileImage) => [
  {
    field: 'profileImage',
    headerName: '',
    width: 100,
    renderCell: renderProfileImage,
    editable: false,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'firstName',
    headerName: 'Name',
    width: 250,
    renderCell,
    editable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'emailAddress',
    headerName: 'Email Address',
    width: 320,
    renderCell,
    editable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'phone',
    headerName: 'Phone',
    width: 250,
    renderCell,
    editable: false,
    headerAlign: 'center',
    align: 'center',
  },
];
export default columns;
