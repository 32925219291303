import { AspectRatioRounded, ControlPointOutlined } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useAspectRatio from '../../../../hooks/useAspectRatio';
import { addNewAssetType } from '../../../../store/actions/assetTypes';

const AssetTypesControls = () => {
  const dispatch = useDispatch();
  const handleAspectRatio = useAspectRatio();
  const { preferences } = useSelector((state) => state.currentUser);

  const handleAddToAssetTypes = () => {
    dispatch(addNewAssetType());
  };

  return (
    <Box style={{ display: 'flex' }}>
      <Box>
      <IconButton
      edge="end"
      onClick={handleAddToAssetTypes}
    >
      <ControlPointOutlined />
      </IconButton>
      </Box>
      <Box>
      <IconButton
        edge="end"
        onClick={() => {
          handleAspectRatio(!preferences.aspectChanged);
        }}
        sx={{ marginLeft: 2 }}
      >
        <AspectRatioRounded />
      </IconButton>
      </Box>
    </Box>
  );
};

export default AssetTypesControls;
