import { makeStyles } from '@mui/styles';
const styles = makeStyles(() => ({

  titleFont: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '17px',
    fontWeight: '600'
  },

  faultItem: {
    background: 'red',
    color: 'white',
    marginBottom: '8px'
  },

  warningItem: {
    background: ' #FFBF00;',
    color: 'black',
    marginBottom: '8px',
  },
  listContainerFont: {
    '& > span': {
      fontSize: '12px',
      fontWeight: '600',
    },
  },
}));

export default styles;
