export function findProductsWithinBudget(products, maxOrderAmount) {
  const selectedProducts = [];
  const remainingProducts = [];
  let totalPrice = 0;
  for (const product of products) {
    const price = parseFloat(product.Price);
    if (totalPrice + price <= maxOrderAmount) {
      selectedProducts.push(product);
      totalPrice += price;
    } else {
      remainingProducts.push(product);
    }
  }
  return { selectedProducts, remainingProducts };
}

export function splitOrdersByMaxAmount(orders, maxPrice) {
  const groupedOrders = [[]];

  orders.forEach(order => {
    let added = false;
    for (let i = 0; i < groupedOrders.length; i++) {
      if (groupedOrders[i].reduce((acc, curr) => Number(acc) + Number(curr.Price), 0) + Number(order.Price) <= Number(maxPrice)) {
        groupedOrders[i].push(order);
        added = true;
        break;
      }
    }
    if (!added) {
      groupedOrders.push([order]);
    }
  });

  return groupedOrders;
}
