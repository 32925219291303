import { makeStyles } from '@mui/styles';

const styles = makeStyles((theme) => ({
  searchBar: {
    backgroundColor: 'transparent',
    width: '25vh',
    marginRight: '1vh',
    '&.MuiFilledInput-root:hover': {
      backgroundColor: 'transparent',
    },
    '&.MuiInputBase-root :hover': {
      backgroundColor: 'transparent',
    },
    fontSize: 'larger',
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  runActionContainer: {
    marginLeft: '-8px'
  },
  aspectRatioContainer: {
    marginLeft: '-10px',
  },
  piechartContainer: {
    marginLeft: '-17px',
  },
  dateTimePicker: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    alignSelf: 'flex-start',
  },
  divisionContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '16px'
  },
  divisionText: {
    color: '#000',
    fontWeight: 'bold',
    marginRight: '14px'
  },
  logo: {
    width: '70%',
    margin: 'auto',
  }
}));

export default styles;
