import { makeStyles } from '@mui/styles';

const styles = makeStyles((theme) => {
  return {
    toolBar: {
      background: '#FFF',
      fontSize: '12px',
      maxWidth: '1280px',
      margin: '0 auto',
      padding: '10px 0'
    },
    fixedAppbar: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      maxWidth: '100%',
      zIndex: 1200,
      background: '#FFF !important',
      flexGrow: 1,
      boxShadow: '0 5px 5px rgba(0,0,0,0.1), 0 10px 20px rgba(0,0,0,0.1)'
    },
    logo: {
      height: 75,
      width: '125px',
      border: 'none',
      [theme.breakpoints.down(500)]: {
        height: 55
      }
    },
    text: {
      color: '#757575',
      fontSize: '20px',
      fontWeight: 'bold',
      marginRight: '14px',
      cursor: 'pointer',
      '&:hover': {
        color: '#000000',
        textDecoration: 'underline #000000',
        textUnderlineOffset: '8px'
      },
      textDecoration: 'none',
      [theme.breakpoints.down(500)]: {
        fontSize: '16px',
        marginRight: '10px',
      }
    },
    icon: {
      color: '#000',
      fontSize: '26px',
      [theme.breakpoints.down(500)]: {
        fontSize: '20px',
      }
    }
  };
});

export default styles;
