import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  actionButton: {
    position: 'relative',
    bottom: 8,
  },
  customTextArea: {
    resize: 'none',
  },
}));

export default styles;
