import { useEffect, useState } from 'react';
import { DirectionsRunRounded, AspectRatioRounded } from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import styles from './styles';
import useAspectRatio from '../../../../hooks/useAspectRatio';
import { useDispatch, useSelector } from 'react-redux';
import { CALENDER_PROPS } from '../../../../utils/constants';
import { REDUX_ACTIONS } from '../../../../shared/config/constants';
import { listMarketplaceLogs } from '../../../../store/actions/marketplaceLogs';

const MarketplaceLogControls = () => {
  const dispatch = useDispatch();
  const { preferences } = useSelector((state) => state.currentUser);
  const classes = styles();
  const [selectedStartDateTime, setSelectedStartDateTime] = useState(null);
  const [selectedEndDateTime, setSelectedEnDateTime] = useState(null);

  const handleAspectRatio = useAspectRatio();

  const handleStartTime = (date) => {
    setSelectedStartDateTime(date);
  };

  const handleEndTime = (date) => {
    setSelectedEnDateTime(date);
  };

  const handleSearch = () => {
    const body = {
      startDate: selectedStartDateTime,
      endDate: selectedEndDateTime
    };
    dispatch({
      type: REDUX_ACTIONS.MARKETPLACE_LOGS_SEARCH_CONTROL,
      payload: body,
    });
    dispatch({
      type: REDUX_ACTIONS.MARKETPLACE_LOGS_SET_CURRENT_PAGE,
      payload: 1
    });
    dispatch(listMarketplaceLogs(body));
  };

  useEffect(() => {
    const start = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    const end = new Date();
    const body = {
      startDate: start,
      endDate: end
    };
    setSelectedStartDateTime(start);
    setSelectedEnDateTime(end);
    dispatch(listMarketplaceLogs(body));
  }, []);

  return (
    <Grid alignItems="flex-end" justifyContent="flex-end" sx={{ marginLeft: 'auto' }}>
      <Box className={classes.container}>
        <Box className={classes.dateContainer}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            {...CALENDER_PROPS}
            label="Start Date"
            value={selectedStartDateTime}
            onChange={handleStartTime}
            className={classes.dateTimePicker}
            classes={{
              switchHeader: classes.datePickerHeader,
            }}
            disableFuture
            textField={(params) => <TextField {...params} />}
          />
          <DateTimePicker
            {...CALENDER_PROPS}
            label="End Date"
            value={selectedEndDateTime}
            onChange={handleEndTime}
            className={classes.dateTimePicker}
            disabled={!selectedStartDateTime}
            disableFuture
            minDate={selectedStartDateTime}
            textField={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        </Box>
        <Box className={classes.runActionContainer}>
        <IconButton
            size="large"
            aria-label="orders"
            onClick={handleSearch}
          >
            <DirectionsRunRounded />
          </IconButton>
        </Box>
        <Box className={classes.aspectRatioContainer}>
          <IconButton
            edge="end"
            onClick={() => {
              handleAspectRatio(!preferences.aspectChanged);
            }}
          >
            <AspectRatioRounded />
          </IconButton>
        </Box>
      </Box>
    </Grid>
  );
};

export default MarketplaceLogControls;
