import React from 'react';
import ReactJson from 'react-json-view';
import PropTypes from 'prop-types';

const RenderJsonField = ({ value }) => {
  let data = {};
  if (typeof value === 'object' || (Array.isArray(value) && value !== null)) {
    data = value;
  }
  return (
    <ReactJson src={data} name={false} collapsed={true} sortKeys={true} />
  );
};

RenderJsonField.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ])
};

export default RenderJsonField;
