import { AspectRatioRounded } from '@mui/icons-material';
import { Box, Grid, IconButton } from '@mui/material';
import useAspectRatio from '../../../../hooks/useAspectRatio';
import { useSelector } from 'react-redux';

const TransactionHistoryKPIControls = () => {
  const handleAspectRatio = useAspectRatio();
  const { preferences } = useSelector((state) => state.currentUser);

  return (
        <Grid alignItems="flex-end" justifyContent="flex-end">
            <Box>
                <IconButton
                    edge="end"
                    onClick={() => {
                      handleAspectRatio(!preferences.aspectChanged);
                    }}
                >
                    <AspectRatioRounded />
                </IconButton>
            </Box>
        </Grid>
  );
};

export default TransactionHistoryKPIControls;
