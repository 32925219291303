import { SpeedRounded, SsidChartRounded, TableRowsOutlined, AlignVerticalBottomRounded } from '@mui/icons-material';
import { Box, IconButton, InputAdornment } from '@mui/material';
import { REDUX_ACTIONS } from '../../../../shared/config/constants';
import { useDispatch, useSelector } from 'react-redux';

const SpeedChart = () => {
  const dispatch = useDispatch();
  const { preferences } = useSelector((state) => state.currentUser);

  const handleChartView = () => {
    dispatch({
      type: REDUX_ACTIONS.SET_CHART_VIEW_MODE,
      payload: (preferences.chartViewMode + 1) % 4,
    });
  };

  return (
    <>
      <Box sx={{ marginLeft: 'auto' }}>
        <InputAdornment position="end">
          <IconButton
            edge="end"
            onClick={handleChartView}
          >
            {preferences.chartViewMode === 0 ? <TableRowsOutlined /> : preferences.chartViewMode === 1 ? <SsidChartRounded /> : preferences.chartViewMode === 2 ? <SpeedRounded /> : <AlignVerticalBottomRounded />}
          </IconButton>
        </InputAdornment>
      </Box>
    </>
  );
};

export default SpeedChart;
