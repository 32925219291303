import React, { useEffect, useReducer, useRef } from 'react';
import Datagrid from '../../../ui/Datagrid';
import {
  BarChartRounded,
  DeleteRounded,
  SendRounded,
} from '@mui/icons-material';
import { renderCell } from '../../../ui/Datagrid/table_config';
import columns from './table_config';
import { Grid, IconButton, Typography } from '@mui/material';
import styles from './styles';

const initialState = {
  loading: false,
  data: [
    { _id: 1, name: '2R TECH MANUFACTURING' },
    { _id: 2, name: '4K TURBINES' }
  ],
};

function reducer(state, action) {
  switch (action.type) {
    case 'DELETE_ITEM':
      return {
        ...state,
        data: state.data.filter((item) => item._id !== action.id),
      };
    case 'ADD_ITEM':{
      const newItem = {
        _id: Math.floor(Math.random() * 100),
        name: '',
      };
      return {
        ...state,
        data: [...state.data, newItem],
      };
    }
    default:
      return state;
  }
}

function Ramps() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const newFieldRef = useRef(null);
  const classes = styles();

  useEffect(() => {
    if (state.data[state.data.length - 1]?.name === '') {
      newFieldRef.current?.focus();
    }
  }, [state.data]);

  const handleAdd = () => {
    dispatch({ type: 'ADD_ITEM' });
  };

  const renderPumpRamp = () => {
    return (
      <Grid container justifyContent="center">
        <IconButton
          variant="solid"
          onClick={() => {
          }}
        >
          <BarChartRounded />
        </IconButton>
      </Grid>
    );
  };

  const renderActions = ({
    id
  }) => {
    return (
      <Grid container justifyContent="center">
        <IconButton
        variant="solid"
        onClick={() => {
          dispatch({ type: 'DELETE_ITEM', id });
        }}
      >
        <DeleteRounded />
      </IconButton>
        <IconButton
          variant="solid"
          onClick={() => {
          }}
        >
          <SendRounded />
        </IconButton>
      </Grid>
    );
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} className={classes.addWrapper}>
        <Typography
          className={classes.addButton}
          onClick={handleAdd}
        >
          + Add
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Datagrid
          data={state.data}
          autoHeight={false}
          getRowId={(row) => row._id}
          columns={columns(
            renderCell,
            renderPumpRamp,
            renderActions,
          )}
          loading={state.loading}
          pageSize={100}
          disableStickyHeader={false}
          onRowClick={() => {
          }}
          height={'85vh'}
        />
      </Grid>
    </Grid>
  );
}

export default Ramps;
