import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Datagrid from '../ui/Datagrid';
import columns from './table_config';
import OrdersCartModalDialog from '../ui/OrdersCartModalDialog';
import { Grid, IconButton } from '@mui/material';
import { downloadProofOfDelivery, listOrders, updateOrderList } from '../../store/actions/orders';
import { useAuth } from '../../hooks/useAuth';
import RenderStatusField from '../ui/RenderStatusField';
import { FileUploadRounded, ReceiptRounded } from '@mui/icons-material';
import ConfirmStatusUpdateDialog from '../ui/ConfirmStatusUpdateDialog';
import { USER_TYPE } from '../../utils/constants';

const Order = () => {
  const orders = useSelector((state) => state.orders);
  const [partIDs, setPartIds] = useState([]);
  const [orderData, setOrderData] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);

  const { user } = useAuth();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!orders.loading) {
      dispatch(listOrders(user));
    }
  }, []);

  const onOrderClick = (e) => {
    e?.preventDefault();
  };

  const onCloseClick = (e) => {
    e?.preventDefault();
    setIsDialogOpen(false);
  };

  const onRowDoubleClick = (props, evt) => {
    evt.preventDefault();
    evt.defaultMuiPrevented = true;
    // eslint-disable-next-line react/prop-types
    setPartIds(props.row.partIDs);
    // eslint-disable-next-line react/prop-types
    setOrderData(props.row);
    setIsDialogOpen(true);
  };

  const handleConfirmStatus = (orderNumber, status, id) => {
    setSelectedOrder({ orderNumber, status, id });
    setConfirmDialog(true);
  };

  const handleDownloadPOD = (order) => {
    dispatch(downloadProofOfDelivery(order.orderNumber));
  };

  const handleConfirm = () => {
    dispatch(updateOrderList(orders?.data, selectedOrder, setSelectedOrder, 'orders'));
  };
  const renderProofOfDelivery = ({ row }) => {
    return (
      <Grid item container justifyContent="center">
        {
          row?.POD
            ? <IconButton
                  onClick={() => {
                    handleDownloadPOD(row);
                  }}
                  disabled={orders.printState === 'loading'}
                >
                  <ReceiptRounded/>
                </IconButton>
            : <IconButton
                 disabled={!(user.type === USER_TYPE.PREMIUM_MARKETPLACE_ADMIN || user.type === USER_TYPE.PREMIUM_ADMIN)}
               >
                 <FileUploadRounded/>
               </IconButton>
          }
      </Grid>
    );
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Datagrid
            data={orders.data}
            autoHeight={false}
            columns={columns(
              ({ id, row }) => <RenderStatusField disabled={user?.type !== 'CUSTOMER_MANAGER'} id={id} row={row} handleStatus={handleConfirmStatus} />,
              renderProofOfDelivery
            )}
            onRowDoubleClick={onRowDoubleClick}
            loading={orders.loading}
            pageSize={100}
            autoheight
            height={'90vh'}
            style={{ fontWeight: 'bold' }}
            toolbar={{
              options: {
                columns: true,
                filters: true,
                export: false,
              },
            }}
          />
        </Grid>
      </Grid>
      <OrdersCartModalDialog
        isOpen={isDialogOpen}
        partIDs={partIDs}
        orderData={orderData}
        onCloseClick={onCloseClick}
        onActionClick={onOrderClick}
        saveButtonText={'Order Again'}
      />
      {confirmDialog
        ? (
        <ConfirmStatusUpdateDialog
          open={confirmDialog}
          handleClose={() => {
            setConfirmDialog(false);
            setSelectedOrder(null);
          }}
          selectedData={selectedOrder}
          handleConfirm={handleConfirm}
        />
          )
        : null}
    </>
  );
};

export default Order;
