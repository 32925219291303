import { Grid, Typography } from '@mui/material';
import React from 'react';

const LandscapeMessage = () => {
  return (
    <Grid container justifyContent="center" justifyItems="center" display="flex" flexDirection="column" position="fixed" top="50%">
      <Grid item xs={12} textAlign="center">
        <Typography variant="h6" fontWeight="bolder">For better experience, Please turn your device to portrait view.</Typography>
      </Grid>
    </Grid>
  );
};

export default LandscapeMessage;
