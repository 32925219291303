import { AspectRatioRounded, Search } from '@mui/icons-material';
import { Box, FilledInput, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useAspectRatio from '../../../../hooks/useAspectRatio';
import { setSelectedDivision } from '../../../../store/actions/epicor/epicorBins';
import styles from './styles';
import { REDUX_ACTIONS } from '../../../../shared/config/constants';

const FieldboxesKPIControls = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const handleAspectRatio = useAspectRatio();
  const { preferences } = useSelector((state) => state.currentUser);
  const { divisions, selectedDivision, loading, searchTerm } = useSelector((state) => state.epicorBins.kpis);

  const handleChange = (e) => {
    dispatch(setSelectedDivision(e.target.value));
  };

  const handleSearchChange = (e) => {
    dispatch({
      type: REDUX_ACTIONS.FIELDBOXES_KPIS_LIST_SEARCH_CHANGE,
      payload: e.target.value
    });
  };

  const handleSearch = () => {
    dispatch({
      type: REDUX_ACTIONS.FIELDBOXES_KPIS_LIST_FILTER
    });
  };

  return (
        <Grid sx={{ display: 'flex' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='body2' sx={{ color: '#000', fontWeight: 'bold', marginRight: '14px' }}>Division</Typography>
                <FormControl variant='outlined' sx={{ minWidth: '120px' }}>
                    <Select
                        labelId="simple-select-standard-label"
                        id="simple-select-standard"
                        value={selectedDivision}
                        onChange={handleChange}
                        disabled={loading}
                        size='small'
                        sx={{
                          '& .MuiSelect-select': {
                            padding: '14px',
                          },
                        }}
                    >
                        <MenuItem key='ALL' value="ALL">ALL</MenuItem>
                        {divisions?.sort().map((item) => (
                            <MenuItem
                                key={`item_${item}`}
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Box sx={{ marginLeft: 3 }}>
              <FormControl>
                <InputLabel
                  htmlFor="outlined-adornment-search"
                  inputprops={{ classes: { root: classes.searchBar } }}
                >
                  Search
                </InputLabel>
                <FilledInput
                  className={classes.searchBar}
                  id="outlined-adornment-search"
                  type="text"
                  label="Search"
                  value={searchTerm || ''}
                  onChange={handleSearchChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={handleSearch}
                      >
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
            <IconButton
                edge="end"
                onClick={() => {
                  handleAspectRatio(!preferences.aspectChanged);
                }}
                sx={{ marginLeft: 2 }}
            >
                <AspectRatioRounded />
            </IconButton>
        </Grid>
  );
};

export default FieldboxesKPIControls;
