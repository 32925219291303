// returns a list of visible columns to be use in the grid view
export const extractVisibleFields = (objectArray) => {
  if (!Array.isArray(objectArray) || objectArray.length === 0) {
    return [];
  }

  const visibleFields = objectArray
    .filter((item) => item.hide === false)
    .map((item) => item.field);

  return visibleFields;
};
