import React from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from './shared/config/theme';
import AppRoutes from './App.routes';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './store/store';
import { SnackbarProvider } from 'notistack';
import { LicenseInfo } from '@mui/x-license-pro';
import { MUI_LICENSEKEY } from './shared/config/constants';

LicenseInfo.setLicenseKey(MUI_LICENSEKEY);

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <SnackbarProvider maxSnack={3}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppRoutes />
          </ThemeProvider>
        </SnackbarProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
