export function CustomCsvExport(data, selectedColumnKeys) {
  const transformInputField = (input) => {
    const transformObject = (obj, indent = 2, level = 0) => {
      let result = '';
      const padding = ' '.repeat(indent);
      const entries = Object.entries(obj);
      entries.forEach(([key, value], index) => {
        const isLast = index === entries.length - 1;
        if (typeof value === 'object' && !Array.isArray(value)) {
          result += `${padding}${key}: ${transformObject(value, indent + 2, level + 1)}${isLast ? '' : ','}\n`;
        } else if (Array.isArray(value)) {
          result += `${padding}${key}: [\n`;
          value.forEach((item, idx) => {
            const itemPrefix = `${' '.repeat(indent + 2)}${idx + 1}) `;
            result += `${itemPrefix}${transformObject(item, indent + 4, level + 1)}${idx === value.length - 1 ? '' : ','}\n`;
          });
          result += `${padding}]${isLast ? '' : ','}\n`;
        } else {
          result += `${padding}${key}: ${value}${isLast ? '' : ','}\n`;
        }
      });
      return result.trim();
    };

    try {
      const parsedInput = JSON.parse(input);
      return transformObject(parsedInput);
    } catch (e) {
      return input;
    }
  };

  const customData = data.map(row => {
    const filteredRow = {};
    selectedColumnKeys.forEach(key => {
      if (key === 'input') {
        filteredRow[key] = transformInputField(row[key]) || '  -';
      } else {
        filteredRow[key] = row[key] || '  -';
      }
    });
    return filteredRow;
  });

  let csvContent = 'data:text/csv;charset=utf-8,';

  // Add header row from selected columns
  csvContent += selectedColumnKeys.join(',') + '\n';

  // Add data rows
  customData.forEach(row => {
    const rowContent = selectedColumnKeys.map(key => {
      let val = row[key] || ''; // Handle undefined values
      if (typeof val === 'string' && (val.includes(',') || val.includes('\n') || val.includes('"'))) {
        val = `"${val.replace(/"/g, '""')}"`;
      }
      return val;
    }).join(',');

    csvContent += rowContent + '\n';
  });

  // Encode and trigger download
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'Premium Oilfield Technologies.csv');
  document.body.appendChild(link); // Required for Firefox
  link.click();
  document.body.removeChild(link);
}
