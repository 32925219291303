import React, { useEffect, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles';
import { listEpicorBins, syncEpicoreBin, updateCoordinates } from '../../store/actions/epicor/epicorBins';
import pistonAmber from '../../assets/images/piston_amber.png';
import pistonGreen from '../../assets/images/piston_green.png';
import pistonRed from '../../assets/images/piston_red.png';
import screenAmber from '../../assets/images/screen_amber.png';
import screenGreen from '../../assets/images/screen_green.png';
import screenRed from '../../assets/images/screen_red.png';
import statusGreen from '../../assets/images/pin_green.png';
import statusAmber from '../../assets/images/pin_amber.png';
import statusRed from '../../assets/images/pin_red.png';
import { Icon } from 'leaflet';
import Datagrid from '../ui/Datagrid';
import columns from './table_config';
import { CardMedia, Grid, IconButton, Typography } from '@mui/material';
import { renderCell } from '../ui/Datagrid/table_config';
import { WidgetsRounded, ReceiptRounded, SyncRounded } from '@mui/icons-material';
import { FIELDBOX_LASTCHANGED } from '../../utils/constants';
import BinPartsModalDialog from './../ui/BinPartsModalDialog/index';
import PropTypes from 'prop-types';
import SyncMobileDevicesModal from './../ui/ControlPanel/SyncMobileDevices/SyncMobileDevicesModal/index';
import Premium from '../../assets/images/PRO logo PMS green only.svg';
import { MAP_TYPES, REDUX_ACTIONS } from '../../shared/config/constants';
import Map from '../map';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { listRigs } from '../../store/actions/rigs';
import pinBlue from '../../assets/images/pin.png';
import { listCheckIns } from '../../store/actions/checkins';
import { getLastTwoWeeksDateRange } from '../../utils/dateInfo';
import proGeoBlueIcon from '../../assets/images/pro_geo_blue.png';

const fieldBoxPins = {
  green: pistonGreen,
  red: pistonRed,
  amber: pistonAmber,
};

const screenBoxPins = {
  green: screenGreen,
  red: screenRed,
  amber: screenAmber,
};

const lastChangedStatus = {
  green: statusGreen,
  amber: statusAmber,
  red: statusRed
};

const pins = {
  fieldBox: fieldBoxPins,
  screenBox: screenBoxPins,
  rigIcon: pinBlue,
  checkInsIcon: proGeoBlueIcon,
};

const Rigs = () => {
  const classes = styles();
  const bins = useSelector((state) => state.epicorBins);
  const [isBinPartsDialogOpen, setIsBinPartsDialogOpen] = useState(false);
  const [currentBin, setCurrentBin] = useState({});
  const [ticketView, setTicketView] = useState(false);
  const [maxTicketAmount, setMaxTicketAmount] = useState('');
  const [transferBoxValue, setTransferBoxValue] = useState('');
  const [poValue, setPoValue] = useState('');
  const [salesRepValue, setSalesRepValue] = useState('');
  const [salesManagerValue, setSalesManagerValue] = useState('');
  const [AFEValue, setAFEValue] = useState('');
  const [leaseWellValue, setLeaseWellValue] = useState('');
  const [validationFields, setValidationFields] = useState([
    { name: 'PO', filled: false, message: '' },
    { name: 'SalesRep', filled: false, message: '' },
    { name: 'SalesManager', filled: false, message: '' },
  ]);
  const [open, setOpen] = useState(false);
  const [binNumber, setBinNumber] = useState(null);
  const [rowId, setRowId] = useState(null);
  const [gmapRowId, setGmapRowId] = useState(null);
  const [iconPinSize, setIconPinSize] = useState([15, 25]);
  const mapType = process.env.REACT_APP_FIELD_BOXES_PAGE_MAP;
  const rigs = useSelector((state) => state.rigs);
  const checkIns = useSelector((state) => state.checkIns);

  const dispatch = useDispatch();
  const apiRef = useGridApiRef();

  useEffect(() => {
    if (!bins.loading && bins.data.length === 0) {
      dispatch(listEpicorBins());
    }
    if (!rigs.loading && rigs.data.length === 0) {
      dispatch({
        type: REDUX_ACTIONS.RIGS_LIST_LOADING,
      });
      dispatch(listRigs());
    }
    if (!checkIns.loading) {
      dispatch({
        type: REDUX_ACTIONS.CHECKINS_LIST_LOADING,
      });
      dispatch(listCheckIns(getLastTwoWeeksDateRange()));
    }
  }, []);

  useEffect(() => {
    if (bins.data.length) {
      localStorage.setItem('fieldboxes', JSON.stringify(bins.data));
    }
  }, [bins.data]);

  const showBinPartsDialog = (bin) => {
    setCurrentBin(bin);
    setIsBinPartsDialogOpen(true);
  };

  const getGoogleMapPin = (status, colorType) => {
    const pinColor = getPinColor(status);
    const pinSet = colorType.toLowerCase() === 'field box' ? pins.fieldBox : pins.screenBox;
    const pinIcon = pinColor ? pinSet[pinColor] : pinSet.red;

    return {
      url: pinIcon,
      // eslint-disable-next-line no-undef
      scaledSize: new google.maps.Size(iconPinSize[0], iconPinSize[1]),
      // eslint-disable-next-line no-undef
      size: new google.maps.Size(iconPinSize[0] + 10, iconPinSize[1] + 10)
    };
  };

  const handleUpdateCooridinates = (params) => {
    const value = apiRef.current.getRow(params.id);
    if (value[params.field] !== params.value) {
      dispatch(updateCoordinates(value, params));
    }
  };

  const renderPartsCell = (props) => (
    <Grid container justifyContent="center">
      <IconButton
        variant="solid"
        onClick={(_evt) => {
          // eslint-disable-next-line react/prop-types
          showBinPartsDialog(props.row);
        }}
      >
        <WidgetsRounded />
      </IconButton>
    </Grid>
  );

  const renderTicketsCell = (props) => {
    return (
      <Grid container justifyContent="center">
        <IconButton
          variant="solid"
          onClick={(_evt) => {
            window.open(`/FieldTickets?BinNum=${props.row.BinNum}`, '_blank');
          }}
        >
          <ReceiptRounded />
        </IconButton>
      </Grid>
    );
  };

  const syncSingleBin = () => {
    dispatch(syncEpicoreBin(binNumber));
  };

  const syncSigleBinCloseModal = () => {
    setOpen(false);
    dispatch({
      type: REDUX_ACTIONS.EPICOR_SYNC_BIN_ERROR,
      payload: {
        loadingBin: false,
      },
    });
  };

  const renderSyncCell = (props) => {
    return (
      <Grid container justifyContent="center">
        <IconButton
          variant="solid"
          disabled={bins.loadingBin}
          onClick={() => {
            setOpen(true);
            setBinNumber(props.row.BinNum);
          }}
        >
          <SyncRounded />
        </IconButton>
      </Grid>
    );
  };

  const renderPinsCell = (props) => {
    const pinColor = getPinColor(props?.row?.lastChangedStatus);
    const pinIcon = pinColor ? lastChangedStatus[pinColor] : lastChangedStatus.red;
    return (
      <>
      <IconButton>
      <img
        src={pinIcon}
        alt="pin icon"
        style={{ width: '20px', height: '30px' }}
      />
    </IconButton>
      </>
    );
  };

  const getPinColor = (status) => {
    switch (status) {
      case FIELDBOX_LASTCHANGED.STATUS_ONE:
        return 'green';
      case FIELDBOX_LASTCHANGED.STATUS_TWO:
        return 'amber';
      case FIELDBOX_LASTCHANGED.STATUS_THREE:
        return 'red';
      default:
        return 'red';
    }
  };

  const getMapPin = (status, colorType) => {
    const pinColor = getPinColor(status);
    const pinSet = colorType.toLowerCase() === 'field box' ? pins.fieldBox : pins.screenBox;
    const pinIcon = pinColor ? pinSet[pinColor] : pinSet.red;

    return (
      pinIcon &&
      new Icon({
        iconUrl: pinIcon,
        iconSize: iconPinSize,
        popupAnchor: [0, -5],
      })
    );
  };

  const getMapPin2 = () => {
    const pinColor = 'rigIcon';
    const pinIcon = pinColor ? pins[pinColor] : pins.default;

    return (
      pinIcon &&
      new Icon({
        iconUrl: pinIcon,
        iconSize: iconPinSize,
        popupAnchor: [0, -5],
        className: classes.rigIconPopup,
      })
    );
  };

  const getMapPin3 = () => {
    const pinColor = 'checkInsIcon';
    const pinIcon = pinColor ? pins[pinColor] : pins.default;

    return (
      pinIcon &&
      new Icon({
        iconUrl: pinIcon,
        iconSize: iconPinSize,
        popupAnchor: [0, -5],
      })
    );
  };
  // comment out until we have correct data to display */}
  // const getAssetTypesLabel = (assets) => assets.map((a) => a.type).join(', ');
  return (
    <Grid container justifyContent="center">
      <Map
        mapType={mapType}
        data={bins}
        mapData={bins.data}
        markerData={bins.data}
        latitudeField={'Latitude'}
        longitudeField={'Longitude'}
        mapPinField1={'lastChangedStatus'}
        mapPinField2={'BinType_c'}
        popUpLabel={['BinNum']}
        getGoogleMapPin={getGoogleMapPin}
        showMaxDate={['LastCheckDate_c', 'LastTransactionDate']}
        getMapPin={getMapPin}
        gmapRowId={gmapRowId}
        rowId={rowId}
        setIconPinSize={setIconPinSize}
        excludePopupLabel={true}
        showCheckinsToggleButton={!checkIns.loading}
        showRigsToggleButton={!rigs.loading}
        showRigPinsByDefault={false}
        mapData2={rigs.marker_data}
        markerData2={rigs.marker_data}
        secondaryPopup={'RigName_Number'}
        getMapPin2={getMapPin2}
        latitudeField2={'RigLatitudeWGS84'}
        longitudeField2={'RigLongitudeWGS84'}
        mapData3={checkIns.data}
        markerData3={checkIns.data}
        mapPinField3={'lastUpdate'}
        tertiaryPopup={['BinNum', 'UserEmail', 'comment']}
        getMapPin3={getMapPin3}
        latitudeField3={'lat'}
        longitudeField3={'long'}
      />
      <Grid item xs={12}>
        <Datagrid
          data={bins.data}
          apiRef={apiRef}
          columns={columns(renderCell, renderPartsCell, renderTicketsCell, renderSyncCell, renderPinsCell)}
          loading={bins.loading}
          onRowClick={({ row: bin }) => {
            if (mapType === MAP_TYPES.GOOGLEMAP) {
              setGmapRowId(bin._id);
            } else {
              setRowId(bin);
            }
            setCurrentBin(bin);
          }}
          toolbar={{
            options: {
              columns: true,
              filters: true,
              export: false,
            },
          }}
          onCellEditCommit={(params) => {
            if ((params.field === 'Latitude' || params.field === 'Longitude')) {
              handleUpdateCooridinates(params);
            }
          }}
        />
      </Grid>
      <BinPartsModalDialog
        isOpen={isBinPartsDialogOpen}
        onActionClick={() => {}}
        onCloseClick={() => {
          // TODO reset diaog content if required
          setIsBinPartsDialogOpen(false);
          setTicketView(false);
          setMaxTicketAmount('');
          setTransferBoxValue('');
          setPoValue('');
          setSalesManagerValue('');
          setSalesRepValue('');
          setAFEValue('');
          setLeaseWellValue('');
          setValidationFields([
            { name: 'PO', filled: false, message: '' },
            { name: 'SalesRep', filled: false, message: '' },
            { name: 'SalesManager', filled: false, message: '' },
          ]);
        }}
        bin={currentBin}
        ticketView={ticketView}
        setTicketView={setTicketView}
        maxTicketAmount={maxTicketAmount}
        setMaxTicketAmount={setMaxTicketAmount}
        transferBoxValue={transferBoxValue}
        setTransferBoxValue={setTransferBoxValue}
        poValue={poValue}
        setPoValue={setPoValue}
        salesRepValue={salesRepValue}
        setSalesRepValue={setSalesRepValue}
        AFEValue={AFEValue}
        setAFEValue={setAFEValue}
        leaseWellValue={leaseWellValue}
        setLeaseWellValue={setLeaseWellValue}
        salesManagerValue={salesManagerValue}
        setSalesManagerValue={setSalesManagerValue}
        validationFields={validationFields}
        setValidationFields={setValidationFields}
      />

      <SyncMobileDevicesModal
        open={open}
        onClose={syncSigleBinCloseModal}
        handleClose={syncSigleBinCloseModal}
        cta1Label={'Close'}
        cta1Action={syncSigleBinCloseModal}
        cta2Label={'Sync'}
        cta2Disabled={bins.loadingBin}
        cta2Action={syncSingleBin}
        showCta2={true}
        showCta1={true}
        showDateTimeRange={false}
      >
        <CardMedia
          component="img"
          image={Premium}
          className={classes.logo}
        />

        <Typography variant="h3" gutterBottom component="div" className={classes.title}>
          {'Sync ' + binNumber + ' ?'}
        </Typography>

        { bins.syncResponseMessage !== ''
          ? <Typography variant="h5" gutterBottom component="div" className={classes.statusMessage}>
            {bins.syncResponseMessage}
          </Typography>
          : <div style={{
            height: 40,
          }}></div>
        }
      </SyncMobileDevicesModal>
    </Grid>
  );
};

Rigs.propTypes = {
  row: PropTypes.object,
};

export default Rigs;
