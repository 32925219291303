import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => {
  return {
    small: {
      padding: '8px 12px',
      fontSize: 14,
    },
    medium: {
      padding: '10px 16px',
      fontSize: 15,
    },
    large: {
      padding: '12px 18px',
      fontSize: 16,
    },
    extraLarge: {
      padding: '14px 50px',
      fontSize: 18,
    },
    primary: {
      fontWeight: 'bold',
      cursor: 'pointer',
      borderRadius: '10px',
      letterSpacing: 1.5,
      backgroundColor: '#303030 !important',
      color: '#FFF',
      '&:hover': {
        backgroundColor: '#000 !important',
        color: '#FFF',
      },
      '&:disabled': {
        background: '#cfcfcf !important'
      }
    },
  };
});

export default styles;
