import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Datagrid from '../ui/Datagrid';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { columns } from './table_config';
import { gridColumnGenerator } from '../../utils/gridColumnGenerator';

const OrderDetail = () => {
  const apiRef = useGridApiRef();
  const epicorOrderDetails = useSelector((state) => state.epicorOrderDetails);
  const [columnsOrderDetails, setColumnsOrderDetails] = useState([]);
  const renderCell = (params) => <>{params.value}</>;

  useEffect(() => {
    if (
      !epicorOrderDetails.loading &&
      epicorOrderDetails.data.length > 0 &&
      columnsOrderDetails.length === 0
    ) {
      const columnsList = gridColumnGenerator(
        columns,
        epicorOrderDetails.data,
        renderCell
      );
      setColumnsOrderDetails(columnsList);
      apiRef.current.updateColumns(columnsList);
    }
  }, [epicorOrderDetails.data]);

  return (
    <Datagrid
      apiRef={apiRef}
      data={epicorOrderDetails.data}
      columns={columnsOrderDetails}
      loading={epicorOrderDetails.loading}
      disableStickyHeader={false}
      autoHeight={false}
      pageSize={100}
      height={'45vh'}
      hideFooter={false}
      toolbar={epicorOrderDetails.data.length > 0 && {
        options: {
          columns: true,
          filters: true,
          density: true,
          export: false,
        },
      }}
    />
  );
};

export default OrderDetail;
