import { LockPersonRounded } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import React from 'react';

const Unauthorized = () => {
  return (
    <Grid container justifyContent="center" justifyItems="center">
      <Grid item xs={3} textAlign="center">
        <Typography variant="h6" fontWeight="bolder">
          Unauthorized
        </Typography>
        <LockPersonRounded fontSize="large" />
      </Grid>
    </Grid>
  );
};

export default Unauthorized;
