import { makeStyles } from '@mui/styles';

const styles = makeStyles(({ spacing }) => ({
  subtitleContainer: {
    paddingTop: spacing(1),
    paddingLeft: spacing(1),
  },
}));

export default styles;
