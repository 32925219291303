import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { useSelector } from 'react-redux';
import useNotifier from '../../../hooks/useNotifier';
import { PAGES_DATA, USER_TYPE } from '../../../utils/constants';
import MenuAppBar from '../../ui/Appbar';
import DataLoadingModalDialog from '../../ui/DataLoadingModalDialog';
import MobileNavbar from '../../mobile/ui/Navbar';
import { isMobile } from '../../../utils/isMobile';

const Layout = () => {
  const { user } = useAuth();
  const location = useLocation();
  const { loading } = useSelector((state) => state.dataLoading);
  const mobile = isMobile();
  useNotifier();

  if (!user?.logged) {
    return <Navigate to="/Login" replace state={{ from: location }} />;
  }
  if (location.pathname === '/') {
    if (
      user.type === USER_TYPE.CUSTOMER_MANAGER ||
      user.type === USER_TYPE.CUSTOMER_ADMIN ||
      user.type === USER_TYPE.CUSTOMER_USER ||
      user.type === USER_TYPE.PREMIUM_MARKETPLACE_ADMIN ||
      user.type === USER_TYPE.SELLER_MARKETPLACE_ADMIN ||
      user.type === USER_TYPE.CUSTOMER_RIG
    ) {
      return <Navigate to="/Shop" />;
    } else {
      if (
        user.type === USER_TYPE.PREMIUM_FIELD_TICKETING_ADMIN ||
        user.type === USER_TYPE.PREMIUM_FIELD_TICKETING_USER ||
        user.type === USER_TYPE.PREMIUM_FIELD_TICKETING_MANAGER
      ) {
        return <Navigate to="/FieldBoxes" />;
      } else {
        return <Navigate to="/Jobs" />;
      }
    }
  }
  if (
    PAGES_DATA.findIndex((page) =>
      page.link.localeCompare(location.pathname.replace('/', ''))
    ) === -1
  ) {
    return <Navigate to="/404" replace state={{ from: location }} />;
  }
  if (
    PAGES_DATA.findIndex(
      (page) =>
        page.link === location.pathname &&
        !page.roles?.includes('*') &&
        !page.roles?.includes(user.type)
    ) >= 0
  ) {
    return <Navigate to="/Unauthorized" replace state={{ from: location }} />;
  }

  return (
    <>
      {mobile ? <MobileNavbar /> : <MenuAppBar />}
      <Outlet />
      <DataLoadingModalDialog isOpen={loading} />
    </>
  );
};

export default Layout;
