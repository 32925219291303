import { Autocomplete, Box, Grid, IconButton, InputBase, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch } from 'react-redux';
import { Icon } from 'leaflet';
import { searchMKTLocations, searchMKTRigs, updateDistanceToRigFromLocation } from '../../../store/actions/marketplace';
import Map from '../../../components/map';
import smallLogo from '../../../assets/images/premium_blue_pin.png';
import styles from './styles';
import { isMobile } from 'react-device-detect';
import { MAP_PIN_SIZE } from '../../../utils/constants';

// eslint-disable-next-line react/prop-types
const LocationSearchbar = ({ setSelectedRig }) => {
  const classes = styles();
  const dispatch = useDispatch();
  const rigSearchRef = useRef();
  const [searchText, setSearchText] = useState('');
  const [autoComplete, setAutoComplete] = useState({
    visible: false,
    data: []
  });

  const handleSearch = () => {
    dispatch(searchMKTRigs(searchText, (data) => {
      setAutoComplete({
        visible: true,
        data
      });
    }));
  };

  return (
    <>
     {autoComplete.visible
       ? (
          <Autocomplete
            open
            options={autoComplete.data}
            getOptionLabel={(option) => option?.RigName_Number}
            className={classes.searchBar}
            fullWidth
            onChange={(event, newValue) => {
              setSearchText('');
              setSelectedRig(newValue);
              setAutoComplete({
                visible: false,
                data: []
              });
            }}
            slotProps={{
              paper: {
                sx: {
                  '& .MuiAutocomplete-listbox': {
                    maxHeight: '300px',
                  },
                },
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  '& fieldset': { border: 'none' },
                }}
                onFocus={() => {
                  setAutoComplete({
                    visible: false,
                    data: []
                  });
                  setTimeout(() => {
                    rigSearchRef.current.focus();
                  }, 100);
                }}
                label={searchText}
              />
            )}
          />
         )
       : (
          <Grid className={classes.searchBar} container alignItems='center'>
            <Box sx={{ flex: 1 }}>
              <InputBase
                inputRef={rigSearchRef}
                fullWidth
                className={classes.inputBase}
                placeholder="Rig"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.currentTarget.value);
                }}
                inputProps={{ 'aria-hidden': false }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') { handleSearch(); }
                }}
              />
            </Box>
            <IconButton onClick={handleSearch}>
              <SearchIcon className={classes.icon} sx={{ mr: 2 }} />
            </IconButton>
          </Grid>
         )}
    </>
  );
};

function PremiumLocation() {
  const classes = styles();
  const dispatch = useDispatch();
  const [selectedRig, setSelectedRig] = useState({});
  const [selectedLocation, setSelectedLocation] = useState({});
  const [marketplaceLocations, setMarketplaceLocations] = useState([]);
  const [distance, setDistance] = useState({
    miles: 0,
    polylineData: {}
  });
  const mapType = process.env.REACT_APP_MARKETPLACE_PAGE_MAP;
  const [iconPinSize, setIconPinSize] = useState(MAP_PIN_SIZE.desktop);

  useEffect(() => {
    if (isMobile) {
      setIconPinSize(MAP_PIN_SIZE.mobile);
    } else {
      setIconPinSize(MAP_PIN_SIZE.desktop);
    }
  }, []);

  useEffect(() => {
    dispatch(searchMKTLocations((data) => {
      setMarketplaceLocations(data);
    }));
  }, []);

  useEffect(() => {
    if (selectedLocation?._id && selectedRig?.RigName_Number) {
      dispatch(updateDistanceToRigFromLocation(selectedLocation, selectedRig, setDistance));
    }
  }, [selectedRig, selectedLocation]);

  const getPlantsGoogleMapPin = () => {
    const pinIcon = smallLogo;
    return {
      url: pinIcon,
      // eslint-disable-next-line no-undef
      scaledSize: new google.maps.Size(25, 35),
      // eslint-disable-next-line no-undef
      size: new google.maps.Size(25, 35),
    };
  };

  const getPlantPartsMapPin = () => {
    return new Icon({
      iconUrl: smallLogo,
      iconSize: iconPinSize,
      popupAnchor: [0, -5],
    });
  };

  return (
    <Grid className={classes.locationWrapper}>
      <Typography variant='h3' className={classes.locationTitle}>Find a Premium Location Near You</Typography>
      <LocationSearchbar setSelectedRig={setSelectedRig} />
      <Grid className={classes.mapContainer}>
        <Map
          mapType={mapType}
          mapData={marketplaceLocations}
          markerData={marketplaceLocations}
          latitudeField={'latitude'}
          longitudeField={'longitude'}
          mapPinField={'Plant1'}
          getMapPin={getPlantPartsMapPin}
          getGoogleMapPin={getPlantsGoogleMapPin}
          popUpLabel={['Plant1']}
          rigData={selectedRig}
          polylinePositions={distance}
          selectedPlant={selectedLocation}
          referenceKey={'Plant1'}
          onMarkerClick={(plant) => {
            if (plant?.Plant1 !== selectedLocation?.Plant1) {
              setDistance({
                miles: 0,
                polylineData: {}
              });
              setSelectedLocation(plant);
            }
          }}
          setIconPinSize={setIconPinSize}
        />
      </Grid>
    </Grid>
  );
}

export default PremiumLocation;
