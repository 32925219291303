/* eslint-disable no-case-declarations */
import { REDUX_ACTIONS } from '../../shared/config/constants';
const initialState = {
  loading: false,
  data: [],
  errorMessage: '',
  paginatedElements: 0,
  initialData: [],
  marker_data: [],
  startDate: '',
  endDate: '',
  showDbwPins: {
    'MPE DBW': true,
    'NON-MPE DBW': true,
    DBW: true,
    TARGET: true
  },
  dbWDivisions: [],
  division: 'ALL',
  dbwSyncStatus: ''
};
const dbwVsTargetReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.DBW_VS_TARGET_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: ''
      };
    case REDUX_ACTIONS.DBW_VS_TARGET_LIST_SUCCESS:
      const divisions = [...new Set(action.payload.data.map(item => item.division))];
      return {
        ...state,
        loading: false,
        data: [...action.payload.data],
        initialData: [...action.payload.data],
        marker_data: [...action.payload.data],
        dbWDivisions: divisions,
        paginatedElements: action.payload.paginatedElements,
        errorMessage: ''
      };
    case REDUX_ACTIONS.DBW_VS_TARGET_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: []
      };
    case REDUX_ACTIONS.DBW_VS_TARGET_SEARCH_CHANGE:
      return {
        ...state,
        searchTerm: action.payload.value,
      };

    case REDUX_ACTIONS.DBW_VS_TARGET_FILTER:
      const activePinsForFilter = Object.keys(state.showDbwPins).filter(pin => state.showDbwPins[pin]);
      const filteredData = state.initialData.filter(item =>
        item?.RigName_Number?.toUpperCase().includes(state.searchTerm.toUpperCase()) &&
          activePinsForFilter.includes(item?.class)
      );

      return {
        ...state,
        data: filteredData.length > 0 ? filteredData : [],
        marker_data: filteredData.length > 0 ? filteredData : [],
      };

    case REDUX_ACTIONS.DBW_VS_TARGET_SET_DATE:
      return {
        ...state,
        startDate: action.payload.selectedStartDateTime,
        endDate: action.payload.selectedEndDateTime
      };
    case REDUX_ACTIONS.DBW_VS_TARGET_MARKER_DATA:
      const activePins = Object.keys(action.payload).filter(pin => action.payload[pin]);
      const selectedDivision = state.division;

      return {
        ...state,
        showDbwPins: { ...action.payload },
        marker_data: state.initialData.filter(item =>
          item?.class &&
          activePins.includes(item.class) &&
          (selectedDivision === 'ALL' || item?.division === selectedDivision)
        ),
        data: state.initialData.filter(item =>
          item?.class &&
          activePins.includes(item.class) &&
          (selectedDivision === 'ALL' || item?.division === selectedDivision)
        ),
      };

    case REDUX_ACTIONS.DBW_VS_TARGET_DIVISION_DATA:
      const activeDivisionPins = Object.keys(state.showDbwPins).filter(pin => state.showDbwPins[pin]);
      return {
        ...state,
        division: action.payload,
        marker_data: state.initialData.filter(item =>
          (action.payload === 'ALL' || item?.division === action.payload) &&
          activeDivisionPins.includes(item.class)
        ),
        data: state.initialData.filter(item =>
          (action.payload === 'ALL' || item?.division === action.payload) &&
          activeDivisionPins.includes(item.class)
        ),
      };

    case REDUX_ACTIONS.DBW_VS_TARGET_DIVISION_RESET:
      return {
        ...state,
        division: 'ALL',
      };

    case REDUX_ACTIONS.DBW_SYNC_LOADING:
      return {
        ...state,
        dbwSyncStatus: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
export default dbwVsTargetReducer;
