import { makeStyles } from '@mui/styles';
const styles = makeStyles(() => ({
  customTool: {
    marginLeft: '20px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    cursor: 'pointer',
    padding: '10px 0'
  },
  importLabel: {
    fontWeight: 700,
    fontSize: '0.875rem',
    cursor: 'pointer'
  },
  dialogModal: {
    '& .MuiPaper-root': {
      '& .makeStyles-dialogTitle-27': {
        padding: '4px !important',
      }
    },
    '& .MuiTypography-subtitle1': {
      fontSize: '1.3rem',
      fontWeight: 'bold',
    }
  },
}));
export default styles;
