import { makeStyles } from '@mui/styles';

const styles = makeStyles(({ breakpoints }) => {
  return {
    footerWrapper: {
      width: '100%',
      textAlign: 'center',
      // backgroundColor: '#f0f0f0',
      backgroundColor: '#fff',
      color: '#303030',
      padding: '50px 0 0 0',
      borderTop: '1px solid #CCC',
      [breakpoints.down(500)]: {
        padding: '20px 0 0 0',
      }
    },
    text: {
      fontSize: '14px',
      [breakpoints.down(600)]: {
        fontSize: '13px',
      }
    },
    sectionContainer: {
      maxWidth: '1280px',
      margin: '0 auto',
      padding: 0,
      width: '100%',
      textAlign: 'start',
      [breakpoints.down(960)]: {
        textAlign: 'center',
      }
    },
    logo: {
      height: 130
    },
    certlogo: {
      width: '90%',
      [breakpoints.down(600)]: {
        width: '150px',
      }
    },
    abouttext: {
      fontSize: '14px',
      maxWidth: '365px',
      textAlign: 'justify',
      [breakpoints.down(960)]: {
        textAlign: 'center',
        maxWidth: '100%'
      },
      [breakpoints.down(600)]: {
        fontSize: '13px',
      }
    },
    sectionItem: {
      padding: '10px'
    },
    socialIcon: {
      color: '#005499',
      marginRight: '10px'
    },
    socialsContainer: {
      [breakpoints.down(600)]: {
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px'
      }
    },
    termsContainer: {
      padding: '10px',
      maxWidth: '1280px',
      width: '100%',
      display: 'flex',
      margin: '0 auto',
      justifyContent: 'space-between',
      [breakpoints.down(600)]: {
        display: 'block'
      }
    }
  };
});

export default styles;
