import { Box, Card, Grid, Typography } from '@mui/material';
import React from 'react';
import styles from './styles';
import RigManagementIcon from '../../../assets/images/rig_management.png';
import RealTimeAlertIcon from '../../../assets/images/realtime_alerts.png';
import InventoryControlIcon from '../../../assets/images/inventory_control_management.png';
import BusinessIcon from '../../../assets/images/business_to_business.png';

const SeamlessShopping = () => {
  const classes = styles();

  const items = [
    { title: 'Rig Spend Management', icon: RigManagementIcon },
    { title: 'Inventory Control Management', icon: InventoryControlIcon },
    { title: (<span>Real Time Alerts &<br /> On Demand Analytics</span>), icon: RealTimeAlertIcon },
    { title: 'Business-to-Business Capable', icon: BusinessIcon },
  ];

  return (
      <Grid className={classes.seamlessContainer}>
        <Typography variant='h3' className={classes.shoppingHeader}>
          Seamless Shopping through Premium MKT
        </Typography>
       <Box className={classes.cardContainer}>
       <Grid container spacing={3} justifyContent='center'>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card className={classes.card}>
               <img src={item.icon} alt={item.title} className={classes.icon} />
                  <Typography variant='h5' fontWeight='600'>{item.title}</Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
       </Box>
      </Grid>
  );
};
export default SeamlessShopping;
