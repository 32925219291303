import { REDUX_ACTIONS } from '../../../shared/config/constants';
import { parseError } from '../../../utils/fetchErrorParser';
import fetch from '../../../api/fetch';
import { enqueueSnackbar } from '../snackbar';

export const listEnverusWells = (body) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.ENVERUS_WELLS_LIST_LOADING,
  });

  return fetch
    .post('enverus/wells/fetch-light', {
      filters: body?.filters || [],
      prevPageLink: body?.prevPageLink || '',
      nextPageLink: body?.nextPageLink || '',
      limit: 100,
    })
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.ENVERUS_WELLS_LIST_SUCCESS,
        payload: {
          data: data.data,
          nextPageLink: data.nextPageLink,
          prevPageLink: data.prevPageLink,
          paginatedElements: data.data.length,
          totalCount: Number(data.count)
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.ENVERUS_WELLS_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};

export const syncEnverusWells = () => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.ENVERUS_WELLS_SYNC_LOADING,
    payload: {
      message: 'Please wait...',
    },
  });

  return fetch
    .post('enverus/syncWells')
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(async (data) => {
      await dispatch(listEnverusWells());
      dispatch({
        type: REDUX_ACTIONS.ENVERUS_WELLS_SYNC_IDLE,
        payload: {
          message: data.message,
        },
      });
    })
    .catch(async (error) => {
      dispatch(
        enqueueSnackbar(
          error.message || 'Error syncing wells',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.ENVERUS_WELLS_SYNC_ERROR,
        payload: {
          message: error.message || 'Error syncing wells',
        },
      });
    });
};

export const fetchEnverusWellDetail = (body) => (dispatch) => {
  return fetch
    .post('enverus/wells/fetch-one', { input: body })
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.ENVERUS_WELL_DETAILS_SUCCESS,
        payload: data,
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.ENVERUS_WELL_DETAILS_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};
