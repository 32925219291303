import { useEffect, useState } from 'react';
import { DirectionsRunRounded, AspectRatioRounded, PrintOutlined, PieChartOutlineRounded } from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  TextField,
  FormControl,
  FilledInput,
  InputLabel,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import styles from './styles';
import useAspectRatio from '../../../../hooks/useAspectRatio';
import { listTransactionHistory } from '../../../../store/actions/transactionHistory';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { createEpicorReportInventoryPDF } from '../../../../store/actions/epicor/epicorReports';
import { REDUX_ACTIONS } from '../../../../shared/config/constants';
import { enqueueSnackbar } from '../../../../store/actions/snackbar';
import { CALENDER_PROPS } from '../../../../utils/constants';

const transactionKPIUsers = ['PREMIUM_FIELD_TICKETING_ADMIN', 'PREMIUM_ADMIN', 'PREMIUM_FIELD_TICKETING_MANAGER'];

const TransactionControls = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.currentUser);
  const { preferences } = useSelector((state) => state.currentUser);
  const transactionHistory = useSelector((state) => state.transactionHistory);

  const [userEmailTerm, setUserEmailTerm] = useState(null);
  const [selectedStartDateTime, setSelectedStartDateTime] = useState(null);
  const [selectedEndDateTime, setSelectedEnDateTime] = useState(null);

  const handleUserEmailTerm = (e) => {
    setUserEmailTerm(e.target.value);
  };
  const handleStartTime = (date) => {
    setSelectedStartDateTime(date);
  };
  const handleEndTime = (date) => {
    setSelectedEnDateTime(date);
  };

  const handleSearch = () => {
    dispatch({
      type: REDUX_ACTIONS.TRANSACTION_HISTORY_SEARCH_CONTROL,
      payload: {
        searchKey: userEmailTerm,
        startDate: selectedStartDateTime,
        endDate: selectedEndDateTime
      },
    });
    dispatch({
      type: REDUX_ACTIONS.TRANSACTION_HISTORY_SET_CURRENT_PAGE,
      payload: 1
    });
    dispatch(
      listTransactionHistory({
        userEmailTerm,
        selectedStartDateTime,
        selectedEndDateTime
      }
      )
    );
  };

  const handleDownloadOrderNum = () => {
    dispatch({
      type: REDUX_ACTIONS.TRANSACTION_HISTORY_PRINT_STATE,
      payload: {
        printState: true,
      },
    });
    if (transactionHistory?.downloadTransactionOrderNum?.length) {
      transactionHistory?.downloadTransactionOrderNum?.forEach((orderNum) => {
        dispatch(createEpicorReportInventoryPDF(orderNum));
      });
    } else {
      dispatch(
        enqueueSnackbar(
          'Error in downloading file',
          'error',
          new Date().getTime() + Math.random()
        )
      );
    }
    dispatch({
      type: REDUX_ACTIONS.TRANSACTION_HISTORY_PRINT_STATE,
      payload: {
        printState: false,
      },
    });
  };

  const handleAspectRatio = useAspectRatio();

  useEffect(() => {
    setUserEmailTerm(null);
    setSelectedStartDateTime(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
    setSelectedEnDateTime(new Date());
  }, [location.pathname]);

  return (
    <Grid alignItems="flex-end" justifyContent="flex-end" sx={{ marginLeft: 'auto' }}>
      <Box className={classes.container}>
        <Box className={classes.searchContainer}>
        <FormControl>
          <InputLabel
            htmlFor="outlined-adornment-search"
            inputprops={{ classes: { root: classes.searchBar } }}
          >
            Search
          </InputLabel>
          <FilledInput
            className={classes.searchBar}
            id="outlined-adornment-search"
            type="text"
            label="Search"
            value={userEmailTerm || ''}
            onChange={handleUserEmailTerm}
          />
        </FormControl>
        </Box>
        <Box className={classes.dateContainer}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            {...CALENDER_PROPS}
            label="Start Date"
            value={selectedStartDateTime}
            onChange={handleStartTime}
            className={classes.dateTimePicker}
            classes={{
              switchHeader: classes.datePickerHeader,
            }}
            disableFuture
            textField={(params) => <TextField {...params} />}
          />
          <DateTimePicker
            {...CALENDER_PROPS}
            label="End Date"
            value={selectedEndDateTime}
            onChange={handleEndTime}
            className={classes.dateTimePicker}
            disabled={!selectedStartDateTime}
            disableFuture
            minDate={selectedStartDateTime}
            textField={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        </Box>
        <Box className={classes.runActionContainer}>
        <IconButton
            size="large"
            aria-label="orders"
            onClick={() => {
              handleSearch();
            }}
          >
            <DirectionsRunRounded />
          </IconButton>
        </Box>
        <Box className={classes.runActionContainer}>
        <IconButton
            size="large"
            aria-label="orders"
            onClick={handleDownloadOrderNum}
          >
            <PrintOutlined />
        </IconButton>
        </Box>
        <Box sx={{ marginLeft: !transactionKPIUsers.includes(user.type) ? '13px' : 0 }} className={''}>
        <IconButton
            disabled={selectedEndDateTime < selectedStartDateTime}
            sx={{ display: !transactionKPIUsers.includes(user.type) ? 'none' : '' }}
            size="large"
            aria-label="orders"
            onClick={() => {
              window.open(
                `/TransactionHistory/KPIs?startDate=${selectedStartDateTime.toISOString()}&endDate=${selectedEndDateTime.toISOString()}`,
                '_blank'
              );
            }}
          >
            <PieChartOutlineRounded />
          </IconButton>
        </Box>
        <Box className={classes.aspectRatioContainer}>
        <IconButton
            edge="end"
            onClick={() => {
              handleAspectRatio(!preferences.aspectChanged);
            }}
          >
            <AspectRatioRounded />
          </IconButton>
        </Box>
      </Box>
    </Grid>
  );
};

export default TransactionControls;
