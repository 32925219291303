const columns = (renderCell, RenderJsonField, renderActions, renderValueCell, renderValueTextCell) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 220,
    renderEditCell: renderValueTextCell,
    editable: true,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'MD',
    headerName: 'MD (ft)',
    width: 200,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'drillstring_details',
    headerName: 'Drillstring',
    headerAlign: 'center',
    align: 'center',
    width: 300,
    renderCell: (data) => {
      const filteredData = data?.value?.length
        ? data.value.map((item) => ({
          name: item.name,
          id: item.id,
          od: item.od,
          tj_od: item.tj_od,
          len: item.len,
          tj_id: item.tj_id,
          tj_len: item['tj_len%'],
        }))
        : {};

      return <RenderJsonField value={filteredData} />;
    }
  },
  {
    field: 'bit_details',
    headerName: 'Bit',
    headerAlign: 'center',
    align: 'center',
    width: 300,
    renderCell: (data) => {
      return <RenderJsonField value={data?.value ? data?.value : {}} />;
    }
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 200,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  },
];
export default columns;
