/* eslint-disable camelcase */
import fetch from '../../api/fetch';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { parseError } from '../../utils/fetchErrorParser';
import store from '../store';
import { enqueueSnackbar } from './snackbar';

function generateRandom() {
  const length = 24;
  const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

export const addNewHydraulicsRow = () => (dispatch) => {
  const { hydraulics } = store.getState();
  const newHydraulics = [...hydraulics.data];
  const nextId = newHydraulics.length - 1;
  const newRow = {
    name: '',
    details: [],
    grid_id: nextId + 1,
    _id: generateRandom(),
    isNewRow: true,
  };
  newHydraulics.unshift(newRow);
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_LIST_SUCCESS,
    payload: {
      data: newHydraulics,
      paginatedElements: newHydraulics.length,
    },
  });
};

export const copyHydraulicsRow = (row) => (dispatch) => {
  const { hydraulics } = store.getState();
  const newHydraulics = [...hydraulics.data];
  const nextId = newHydraulics.length - 1;
  const newRow = {
    name: '',
    run_details: row?.run_details,
    grid_id: nextId + 1,
    _id: generateRandom(),
    isNewRow: true,
  };
  newHydraulics.unshift(newRow);
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_LIST_SUCCESS,
    payload: {
      data: newHydraulics,
      paginatedElements: newHydraulics.length,
    },
  });
};

export const addNewHydraulicsDetailsRow = () => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_DETAILS_LIST_LOADING,
  });
  const { hydraulics } = store.getState();
  const newHydraulicsDetails = [...hydraulics.details.data];
  const nextId = newHydraulicsDetails.length - 1;
  const newRow = {
    hole_depth: '',
    bit_depth_offset: 0.0,
    rop: '',
    flow_in: '',
    drillStringsId: '',
    drillFluidsId: '',
    cuttings_data: {
      diameter: 0.025,
      thickness: 0.025,
      density: 21.75,
      porosity: 0.22
    },
    grid_id: nextId + 1,
    _id: generateRandom(),
    isNewRow: true,
  };
  newHydraulicsDetails.unshift(newRow);
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_DETAILS_LIST_SUCCESS,
    payload: {
      data: newHydraulicsDetails,
      paginatedElements: newHydraulicsDetails.length,
    },
  });
};

export const listHydraulics = (query) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_LIST_LOADING,
  });
  const body = {
    query
  };
  return fetch
    .post('hydraulicsRuns/fetch', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.HYDRAULICS_LIST_SUCCESS,
        payload: {
          data: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.HYDRAULICS_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};

export const createHydraulics = (body, jobId, wellsInfoId, setId, wellsPage) => (dispatch) => {
  const { name, run_details } = body;
  delete body._id;
  const input = {
    input: {
      name,
      run_details: run_details || {},
      wellsInfoId,
      jobId: (jobId || '-1')
    }
  };
  const query = wellsPage
    ? {
        wellsInfoId
      }
    : {
        jobId
      };
  return fetch
    .post('hydraulicsRuns/create', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      if (setId) {
        dispatch({
          type: REDUX_ACTIONS.HYDRAULICS_SET_ID,
          payload: {
            data: data.data._id,
          },
        });
      }
      dispatch(
        enqueueSnackbar(
          'Hydraulics Created Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listHydraulics(query));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listHydraulics(query));
    });
};
export const updateHydraulics = (body, wellsPage) => (dispatch) => {
  const input = {
    query: {
      _id: body?._id
    },
    input: {
      ...body
    }
  };
  const query = wellsPage
    ? {
        wellsInfoId: body?.wellsInfoId
      }
    : {
        jobId: body?.jobId
      };
  return fetch
    .put('hydraulicsRuns', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Hydraulics Updated Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listHydraulics(query));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listHydraulics(query));
    });
};
export const deleteHydraulics = (id, query) => (dispatch) => {
  const body = {
    query: {
      _id: id
    }
  };
  return fetch
    .deleteAction('hydraulicsRuns', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Hydraulics deleted successfully',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listHydraulics(query));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listHydraulics(query));
    });
};

export const listHydraulicsDetails = (runsId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_DETAILS_LIST_LOADING,
  });
  const body = {
    query: {
      runsId
    }
  };
  return fetch
    .post('hydraulicsRuns/details/fetch', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.HYDRAULICS_DETAILS_LIST_SUCCESS,
        payload: {
          data: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.HYDRAULICS_DETAILS_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};

export const updateHydraulicProperty = (data) => (dispatch) => {
  const { hydraulics } = store.getState();
  const currentHydraulics = [...hydraulics.data];
  const index = currentHydraulics.findIndex(hydraulic => hydraulic._id === data._id);

  if (Array.isArray(data.run_details) && data.run_details.length === 1) {
    data.run_details = data.run_details[0];
  }
  currentHydraulics[index] = data;
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_LIST_SUCCESS,
    payload: {
      data: currentHydraulics,
      paginatedElements: currentHydraulics.length,
    },
  });
};

export const createHydraulicsDetails = (runsId, body) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_DETAILS_LIST_LOADING,
  });
  delete body._id;
  const input = {
    query: {
      runsId
    },
    input: body
  };
  return fetch
    .post('hydraulicsRuns/details/create', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Hydraulics Details Created Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listHydraulicsDetails(runsId));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listHydraulicsDetails(runsId));
    });
};
export const updateHydraulicsDetails = (runsId, body) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_DETAILS_LIST_LOADING,
  });
  const input = {
    query: {
      runsId,
    },
    input: body
  };
  return fetch
    .put('hydraulicsRuns/details', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Hydraulics Details Updated Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listHydraulicsDetails(runsId));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listHydraulicsDetails(runsId));
    });
};
export const deleteHydraulicsDetails = (runsId) => (dispatch) => {
  const input = {
    query: {
      runsId
    },
  };
  return fetch
    .deleteAction('hydraulicsRuns/details', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Hydraulics Details Deleted Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listHydraulicsDetails(runsId));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
    });
};
export const runHydraulics = (body, onClose) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_LIST_LOADING,
  });
  if (onClose) {
    dispatch({
      type: REDUX_ACTIONS.HYDRAULICS_DETAILS_LIST_LOADING,
    });
  }
  const { runId, query } = body;
  const input = {
    input: {
      url: `/runs/${runId}`,
      method: 'POST',
    }
  };
  return fetch
    .post('premiumHydraulics/proxy', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Hydraulics Simulation Completed Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      if (onClose) {
        dispatch({
          type: REDUX_ACTIONS.HYDRAULICS_DETAILS_LIST_SET_FALSE,
        });
        onClose();
      }
      dispatch(listHydraulics(query));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      if (onClose) {
        dispatch({
          type: REDUX_ACTIONS.HYDRAULICS_DETAILS_LIST_SET_FALSE,
        });
      }
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listHydraulics(query));
    });
};

export const fetchHydraulicsDrillstringDrillFluid = (wellsInfoId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_DRILL_DETAILS_LOADING,
  });
  const body = {
    query: {
      wellsInfoId
    }
  };
  return fetch
    .post('hydraulicsRuns/selections/fetch', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.HYDRAULICS_DRILL_DETAILS_SUCCESS,
        payload: {
          data,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.HYDRAULICS_DRILL_DETAILS_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};

export const listMudPumps = (wellsInfoId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_MUD_PUMPS_LIST_LOADING,
  });
  const body = {
    query: {
      wellsInfoId
    }
  };
  return fetch
    .post('hydraulicsMudPumps/fetch', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.HYDRAULICS_MUD_PUMPS_LIST_SUCCESS,
        payload: {
          data: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.HYDRAULICS_MUD_PUMPS_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};
export const createMudPumps = (body, wellsInfoId, jobId) => (dispatch) => {
  delete body.isNewRow;
  delete body._id;
  const input = {
    input: {
      ...body,
      wellsInfoId,
      jobId
    }
  };
  return fetch
    .post('hydraulicsMudPumps/create', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Mud Pump Created Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listMudPumps(wellsInfoId));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listMudPumps(wellsInfoId));
    });
};
export const updateMudPumps = (body) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_MUD_PUMPS_LIST_LOADING
  });
  const input = {
    query: {
      _id: body?._id
    },
    input: {
      pumpType: body.pumpType,
      liner_size: Number(body.liner_size),
      stroke_length: Number(body.stroke_length),
      efficiency: Number(body.efficiency),
      bbl_stk: Number(body.bbl_stk),
      grid_id: body.grid_id,
      wellsInfoId: body.wellsInfoId,
      jobId: body.jobId
    }
  };
  return fetch
    .put('hydraulicsMudPumps', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Mud Pump Updated Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listMudPumps(body?.wellsInfoId));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listMudPumps(body?.wellsInfoId));
    });
};
export const deleteMudPumps = (_id, wellsInfoId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.HYDRAULICS_MUD_PUMPS_LIST_LOADING
  });
  const body = {
    query: {
      _id,
    }
  };
  return fetch
    .deleteAction('hydraulicsMudPumps', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Mud Pump deleted successfully',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listMudPumps(wellsInfoId));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listMudPumps(wellsInfoId));
    });
};
