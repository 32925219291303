export const getOptimalInterval = (value1, value2, desiredTicks) => {
  // Determine the minimum and maximum values
  const minValue = Math.min(value1, value2);
  const maxValue = Math.max(value1, value2);

  if (minValue === maxValue) {
    return 1;
  }

  // Calculate the range
  const range = maxValue - minValue;

  // Calculate an approximate interval
  const interval = range / desiredTicks;

  // Round the interval to a more human-readable number
  const exponent = Math.floor(Math.log10(interval));
  const factor = Math.pow(10, exponent);

  // Adjust the interval to a more suitable value
  const adjustedInterval = Math.ceil(interval / factor) * factor;

  const optimalInterval = Math.max(adjustedInterval, 0.2);

  return optimalInterval;
};
