import { Typography, Grid, Box, Divider, CircularProgress } from '@mui/material';
import styles from './styles';
import Premium from '../../assets/images/PRO logo PMS green only.svg';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ORDER_STATUS_TYPES } from '../../shared/config/constants';
import { postOrderStatus } from '../../store/actions/orders';
import { useDispatch } from 'react-redux';
import { useAuth } from '../../hooks/useAuth';

export default function OrderStatus() {
  const classes = styles();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { onLogout } = useAuth();
  const [data, setData] = useState({});
  const [status, setStatus] = useState({
    loading: true,
    error: false
  });

  const verifyData = (data) => {
    const isVerified = Object.values(data).every(item => item);
    return isVerified;
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const data = {
      userId: searchParams.get('userId'),
      type: searchParams.get('type'),
      status: searchParams.get('orderStatus'),
      key: searchParams.get('key'),
      approverEmail: searchParams.get('approverEmail'),
      orderNumber: searchParams.get('orderNumber')
    };

    if (!verifyData(data) || !/^[0-9A-Fa-f]+$/.test(data?.userId)) {
      navigate('/Login');
    } else {
      const body = { input: { ...data } };
      dispatch(postOrderStatus(body, data.key, setStatus));
      setData(data);
    }
  }, [location.search]);

  return (
    <Grid sx={{ height: '100vh' }} container justifyContent="center">
      <Grid item container xs={12} justifyContent="center">
        <Box
          component="img"
          alt="Logo"
          src={Premium}
          className={classes.logo}
        />
        <Divider className={classes.divider}/>
      </Grid>
      {status?.loading
        ? (
        <Grid sx={{ height: '65vh' }} item container xs={12} justifyContent="center" alignItems="center" flex flexDirection={'column'}>
          <CircularProgress />
        </Grid>
          )
        : (
        <Grid sx={{ height: '65vh' }} item container xs={12} justifyContent="center" alignItems="center" flex flexDirection={'column'}>
        <Typography variant={status?.error ? 'h4' : 'h2'} className={classes.text}>
            {status?.error ? status?.errorMessage : `Thank You for ${ORDER_STATUS_TYPES[data?.status]} ${data?.orderNumber}`}
        </Typography>
        <Typography onClick={onLogout} variant="h3" className={classes.link}>
            Sign Into Marketplace
        </Typography>
      </Grid>
          )}
    </Grid>
  );
}
