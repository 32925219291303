import { Box, IconButton, InputAdornment } from '@mui/material';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import { useSelector, useDispatch } from 'react-redux';
import { createEpicorReportInventoryPDF } from '../../../../store/actions/epicor/epicorReports';

export const DownloadOrderPDF = () => {
  const dispatch = useDispatch();
  const selectedRows = useSelector((state) => state.epicorReleases.selectedRows);

  const hanldeDownload = () => {
    if (selectedRows[0].OrderNum !== null && selectedRows[0].OrderNum !== undefined && selectedRows[0].OrderNum !== '') {
      dispatch(
        createEpicorReportInventoryPDF(selectedRows[0].OrderNum)
      );
    }
  };

  if (selectedRows.length <= 0) {
    return null;
  }

  return (
    <>
      <Box sx={{ marginLeft: 'auto' }}>
        <InputAdornment position="end">
          <IconButton
            edge="end"
            onClick={() => hanldeDownload()}
          >
            <PrintOutlinedIcon />
          </IconButton>
        </InputAdornment>
      </Box>
    </>
  );
};

export default DownloadOrderPDF;
