import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  map: { height: '50vh', width: '100%', border: '5px solid white' },
  noActionPin: {
    cursor: 'initial',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  popupBox: {
    fontSize: '12px',
    maxHeight: '80px',
    minWidth: '250px',
    wordBreak: 'break-word',
    overflowY: 'auto'
  },
  switchButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    zIndex: 1000,
    backgroundColor: 'white',
    padding: '2px',
    borderRadius: '5px',
    width: 'auto',
    maxWidth: '90%',
    left: 10,
    flexDirection: 'column',
  },
  dbwCheckinsButton: {
    marginLeft: '6px'
  },
  dbwCheckinsSwitchButton: {
    marginLeft: '33px'
  }
}));

export default styles;
