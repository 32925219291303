import { REDUX_ACTIONS } from '../../shared/config/constants';

const initialState = {
  loading: false,
  deleting: false,
  data: [],
  errorMessage: '',
  loadingDelete: false,
  saved: false,
  paginatedElements: 0,
};

const assetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.ASSETS_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case REDUX_ACTIONS.ASSETS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...action.payload.data],
        paginatedElements: action.payload.paginatedElements,
        errorMessage: '',
      };
    case REDUX_ACTIONS.ASSETS_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: [],
      };
    case REDUX_ACTIONS.ASSETS_ERROR_NOTIFIED:
      return {
        ...state,
        errorMessage: '',
        data: [],
      };
    case REDUX_ACTIONS.ASSETS_SAVE_JOB_ASSET_SUCCESS:
      return {
        ...state,
        data: [
          ...state.data.filter(
            (asset) => !action.payload.data.includes(asset._id)
          ),
        ],
      };
    case REDUX_ACTIONS.ASSETS_REMOVE_JOB_ASSET_SUCCESS:
      return {
        ...state,
        data: [...state.data, ...action.payload.data],
      };
    case REDUX_ACTIONS.ASSETS_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default assetsReducer;
