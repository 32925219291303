import React from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../ModalDialog';
import { Box, Grid, Typography } from '@mui/material';

import Premium from '../../../assets/images/PRO logo PMS green only.svg';
import styles from './styles';
import classNames from 'classnames';
import ButtonComponent from '../../Button';

const SalesOrderProcessingModalDialog = ({
  isOpen,
  isProcessing,
  postResult,
  closeLoadingDialog,
  listOrderCreated,
  postMessage,
  listPacksCreated
}) => {
  const classes = styles();

  return (
    <ModalDialog isOpen={isOpen} isFullScreen={false}>
      <Grid container>
        <Grid item xs={12}>
          <Box
            component="img"
            alt="Logo"
            src={Premium}
            className={classes.logo}
          />
        </Grid>
        <Grid item xs={12}>
          <Box style={{ margin: '70px 10px 120px 10px' }} textAlign="center">
            {isProcessing
              ? (
              <Typography variant="h6" style={{ fontSize: '35px' }}>
                Processing
              </Typography>
                )
              : (
              <>
                <Typography
                  variant="h6"
                  style={{ fontWeight: 'bold', fontSize: '35px' }}
                >
                  {postResult ? 'All Done!' : 'Could not process order'}
                </Typography>
                <Typography variant="h6">
                  {!postResult && (
                    <>
                      <br />
                      {postMessage}
                    </>
                  )}
                </Typography>
                <br />

                {postResult && (
                  <div style={{ width: 500, margin: '0 auto' }}>
                    <Typography variant="h6" style={{ fontSize: '25px' }}>
                      Sales Order(s) Created: {listOrderCreated}
                    </Typography>
                    <Typography variant="h6" style={{ fontSize: '25px' }}>
                      Pack ID(s) Created: {listPacksCreated}
                    </Typography>
                  </div>
                )}
              </>
                )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box style={{ marginBottom: '20px' }} textAlign="center">
            <ButtonComponent
              label='Close'
              autoFocus
              variant={'contained'}
              size={'small'}
              className={classNames(classes.actionButton)}
              disabled={isProcessing}
              onClick={() => {
                closeLoadingDialog(postResult);
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </ModalDialog>
  );
};

SalesOrderProcessingModalDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool,
  postResult: PropTypes.bool,
  title: PropTypes.string,
  postMessage: PropTypes.string,
  closeLoadingDialog: PropTypes.func,
  listOrderCreated: PropTypes.any,
  listPacksCreated: PropTypes.any
};

export default SalesOrderProcessingModalDialog;
