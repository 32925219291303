import { REDUX_ACTIONS } from '../../shared/config/constants';

const initialState = {
  loading: false,
  errorMessage: '',
  data: [],
  marker_data: [],
  paginatedElements: 0,
  searchTerm: '',
  initialData: [],
};

const wellsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.WELLS_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };

    case REDUX_ACTIONS.WELLS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...action.payload.data],
        marker_data: [...action.payload.data],
        initialData: [...action.payload.data],
        paginatedElements: action.payload.paginatedElements,
        errorMessage: '',
      };

    case REDUX_ACTIONS.WELLS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: [],
        marker_data: []
      };

    case REDUX_ACTIONS.WELLS_SEARCH_CHANGE:
      return {
        ...state,
        searchTerm: action.payload.value,
      };
    case REDUX_ACTIONS.WELLS_SAVE_DETAILS:
      return {
        ...state,
        saveWellsDetails: action.payload,
      };
    case REDUX_ACTIONS.WELLS_FILTER: {
      const searchTerm = state.searchTerm.toUpperCase();

      return {
        ...state,
        data: state.initialData.filter(item =>
          [item?.name, item?.rig, item?.operator].some(prop =>
            prop?.toUpperCase().includes(searchTerm)
          )
        ),
        marker_data: state.initialData.filter(item =>
          [item?.name, item?.rig, item?.operator].some(prop =>
            prop?.toUpperCase().includes(searchTerm)
          )
        ),
      };
    }

    case REDUX_ACTIONS.WELLS_MARKER_FILTER:
      return {
        ...state,
        marker_data: [
          ...action.payload.data
        ],
      };

    case REDUX_ACTIONS.WELLS_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default wellsReducer;
