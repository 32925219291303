import { makeStyles } from '@mui/styles';

const styles = makeStyles(({ spacing }) => ({
  carouselContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: 0,
    paddingRight: 0,
    gap: spacing(2),
  },
  media: {
  },

  card: {
    position: 'relative',
    width: '100%',
  },
  cardHeader: {
    fontSize: '1.3rem',
  },
  overlay: {
    top: '150px',
    color: 'black',
    position: 'absolute',
    backgroundColor: 'white',
    width: '100%',
    padding: spacing(2),
    opacity: 0.7,
  },
  footer: {
    height: '30%',
  },
  cardHeaderConatiner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

export default styles;
