const columns = (renderValueCell, renderActions) => [
  {
    field: 'diameter',
    headerName: 'Diameter (inches)',
    width: 185,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'thickness',
    headerName: 'Thickness (inches)',
    width: 185,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'density',
    headerName: 'Density (ppg)',
    width: 180,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'porosity',
    headerName: 'Porosity (fraction)',
    width: 185,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 150,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  }
];
export default columns;
