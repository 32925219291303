import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Datagrid from '../ui/Datagrid';
import columns from './table_config';
import QuotesCartModalDialog from '../ui/QuotesCartModalDialog';
import { Grid } from '@mui/material';
import { listQuotes } from '../../store/actions/quotes';
import { useAuth } from '../../hooks/useAuth';
import RenderStatusField from '../ui/RenderStatusField';
import { updateOrderList } from '../../store/actions/orders';
import ConfirmStatusUpdateDialog from '../ui/ConfirmStatusUpdateDialog';

const Quote = () => {
  const quotes = useSelector((state) => state.quotes);
  const [partIDs, setPartIDs] = useState([]);
  const [quoteData, setQuoteData] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);

  const { user } = useAuth();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!quotes.loading) {
      dispatch(listQuotes(user));
    }
  }, []);

  const onQuoteClick = () => {
    return null;
  };

  const onCloseClick = (e) => {
    e?.preventDefault();
    setIsDialogOpen(false);
  };

  const onRowDoubleClick = (props, evt) => {
    evt.preventDefault();
    evt.defaultMuiPrevented = true;
    // eslint-disable-next-line react/prop-types
    setPartIDs(props.row.partIDs);
    // eslint-disable-next-line react/prop-types
    setQuoteData(props.row);
    setIsDialogOpen(true);
  };

  const handleConfirmStatus = (orderNumber, status, id) => {
    setSelectedQuote({ orderNumber, status, id });
    setConfirmDialog(true);
  };

  const handleConfirm = () => {
    dispatch(updateOrderList(quotes?.data, selectedQuote, setSelectedQuote, 'quotes'));
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Datagrid
            data={quotes.data}
            autoHeight={false}
            columns={columns(
              ({ id, row }) => <RenderStatusField disabled={user?.type !== 'CUSTOMER_MANAGER' || user?.type !== 'CUSTOMER_ADMIN'} id={id} row={row} handleStatus={handleConfirmStatus} />
            )}
            onRowDoubleClick={onRowDoubleClick}
            loading={quotes.loading}
            pageSize={100}
            autoheight
            height={'90vh'}
            style={{ fontWeight: 'bold' }}
            toolbar={{
              options: {
                columns: true,
                filters: true,
                export: false,
              },
            }}
          />
        </Grid>
      </Grid>
      <QuotesCartModalDialog
        isOpen={isDialogOpen}
        partIDs={partIDs}
        quoteData={quoteData}
        onCloseClick={onCloseClick}
        onActionClick={onQuoteClick}
        saveButtonText={'Quote Again'}
      />
      {confirmDialog
        ? (
        <ConfirmStatusUpdateDialog
          open={confirmDialog}
          handleClose={() => {
            setConfirmDialog(false);
            setSelectedQuote(null);
          }}
          selectedData={selectedQuote}
          handleConfirm={handleConfirm}
        />
          )
        : null}
    </>
  );
};

export default Quote;
