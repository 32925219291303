import { DEFAULT_DRILLING_FILTERS, GRID_COLUMNS_TO_EXCLUDE } from '../../../utils/constants';

function findLowestVariant(data, prefix) {
  let lowestVariant = null;
  const prefixLength = prefix.length;
  for (const key in data) {
    if (key.startsWith(prefix) && !isNaN(parseInt(key.substring(prefixLength), 10))) {
      const numPart = parseInt(key.substring(prefixLength), 10);
      if (!isNaN(numPart) && (lowestVariant === null || numPart < lowestVariant)) {
        lowestVariant = numPart;
      }
    }
  }
  return lowestVariant;
}

export const generateDefaultDrillingColumns = (dataObject) => {
  const filteredDataObject = {};
  const columnsInitialState = {};
  const columnsColorDefinition = {};

  // Filter out excluded fields from dataObject
  Object.keys(dataObject).forEach(key => {
    if (!GRID_COLUMNS_TO_EXCLUDE.includes(key)) {
      filteredDataObject[key] = dataObject[key];
    }
  });

  // create template filtered object with all keys set to false except timestamp
  Object.keys(filteredDataObject).forEach(key => {
    if (key !== 'timestamp') {
      columnsInitialState[key] = false;
    }
  });
  const defaultCols = DEFAULT_DRILLING_FILTERS[0].fields;

  // set the default keys to show as true
  defaultCols.forEach(item => {
    if (item.number) {
      const lowestDrillingDataNumber = findLowestVariant(columnsInitialState, item.column);
      const fullKey = `${item.column}${lowestDrillingDataNumber}`;
      // eslint-disable-next-line no-prototype-builtins
      if (filteredDataObject.hasOwnProperty(fullKey)) {
        columnsInitialState[fullKey] = true;
      }
    } else {
      const fullKey = `${item.column}`;
      // eslint-disable-next-line no-prototype-builtins
      if (filteredDataObject.hasOwnProperty(fullKey)) {
        columnsInitialState[fullKey] = true;
      }
    }
  });

  const datakeys = Object.keys(columnsInitialState).filter(key => key !== 'timestamp' && columnsInitialState[key]);
  defaultCols.forEach(item => {
    const lowestDrillingDataNumber = findLowestVariant(columnsInitialState, item.column);
    const key = Object.keys(columnsInitialState).find(key => (columnsInitialState[key] && key === item.column) || (columnsInitialState[key] && key === `${item.column}${lowestDrillingDataNumber}`));
    if ((key && key.replace(/\d+$/, '') === item.column)) {
      columnsColorDefinition[key] = item.color;
    }
  });

  return { columnsInitialState, datakeys, columnsColorDefinition };
};
