import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => {
  return {
    subMenuItem: {
      textDecoration: 'none',
      width: '200px',
    },
    subMenuItemText: {
      textDecoration: 'none',
      color: '#000000',
      fontWeight: 'bold',
      fontSize: '14px',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
  };
});

export default styles;
