import fetch from '../../api/fetch';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { parseError } from '../../utils/fetchErrorParser';
import { enqueueSnackbar } from './snackbar';
import { listCompanies } from './companies';
import { listUsers } from './users';

export const listSellers = () => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.SELLERS_LIST_LOADING,
  });

  const body = { collectionName: 'Companies' };

  return fetch
    .post('companies/sellers', body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.SELLERS_LIST_SUCCESS,
        payload: {
          sellers: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);

      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.SELLERS_LIST_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};

export const assignCompanySellers = (companyId, sellers) => (dispatch) => {
  const body = { companyId, sellers };

  return fetch
    .put('companies/sellers', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      if (result.status === 204) {
        return Promise.resolve();
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Seller Details Updated Successfully.',
          'success',
          new Date().getTime() + Math.random()
        )
      );
      dispatch(listCompanies());
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};

export const assignUserSellers = (userId, sellers) => (dispatch) => {
  const body = { userId, sellers };

  return fetch
    .put('companies/customerRigSellers', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      if (result.status === 204) {
        return Promise.resolve();
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Seller Details Updated Successfully.',
          'success',
          new Date().getTime() + Math.random()
        )
      );
      dispatch(listUsers());
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};
