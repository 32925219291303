import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  dataGrid: {
    width: '100%',
    backgroundColor: '#f0f0f0',
  },
}));

export default styles;
