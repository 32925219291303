/* eslint-disable react/prop-types */
import React from 'react';
import styles from './styles';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import Carousel from 'react-material-ui-carousel';
import { Box, Grid } from '@mui/material';
import { useTheme } from '@mui/styles';
const FilterCarousel = ({ children, carrouselDataSource, handleCardItemClick }) => {
  const classes = styles();
  const theme = useTheme();

  return (
    <Grid container justifyContent="center" className={classes.main}>
      <Grid item xs={11} sx={{ mb: theme.spacing(3) }}>
        <Carousel
          slidesToShow={3}
          NextIcon={<NavigateNext />}
          PrevIcon={<NavigateBefore />}
          autoPlay={false}
          className={classes.carousel}
          index={0}
          fullHeightHover={false}
          selectedItemId={0}
          indicators={false}
          navButtonsAlwaysInvisible={true}
          sx={{ overflow: 'unset', marginTop: theme.spacing(2) }}
        >
          <Box component="ul" className={classes.carouselContainer}>
          {carrouselDataSource.map((item, index) => {
            return React.cloneElement(children, {
              key: `carousel-item-${index}`,
              item,
              onClick: handleCardItemClick,
            });
          })}
          </Box>
        </Carousel>
      </Grid>
    </Grid>
  );
};

export default FilterCarousel;
