import { makeStyles } from '@mui/styles';

const styles = makeStyles(({ spacing, palette }) => {
  return {
    mainContainer: { height: '100vh' },
    loginContainer: {
      padding: spacing(1),
      borderRadius: spacing(1),
      background: `linear-gradient(to top, ${palette.graypart.main}, #f0f0f0)`,
      gap: spacing(3),
    },
    logo: {
      height: 233,
      width: '50vh',
      border: 'none',
    },
    formContainer: {
      marginTop: spacing(4),
      backgroundColor: '#f0f0f0',
      borderRadius: spacing(1),
      maxWidth: '90%',
    },
    passwordInput: {
      backgroundColor: '#f0f0f0 !Important',
      borderRadius: '0px 0px 8px 8px',
      '& :hover': {
        backgroundColor: '#f0f0f0',
      },
    },
    small_banner: { fontSize: '12px', color: '#f0f0f0' },
    emailInput: {
      backgroundColor: 'transparent',
      borderRadius: spacing(1),
    },
    button: {
      marginTop: `${spacing(4)} !important`,
      marginBottom: `${spacing(4)} !important`,
    },
    actionLink: {
      color: 'white',
      textDecoration: 'none',
      margin: '10px',
    },
    resetPasswordLinkVisited: {
      color: 'white',
      textDecoration: 'none',
    },
    linksContainer: {
      display: 'inline-block',
      margin: '0 10px',
    },
    loginButton: {
      position: 'absolute',
      bottom: '10%',
      width: '100%',
    },
    '@media (max-width: 768px)': {
      mainContainer: {
        height: '100vh',
        padding: spacing(1),
        borderRadius: spacing(1),
        background: `linear-gradient(to top, ${palette.graypart.main}, #f0f0f0)`,
        gap: spacing(3),
      },
      loginContainer: {
        padding: '20px',
        borderRadius: '10px',
        maxWidth: '100%',
        height: '100vh',
      },
      logo: {
        height: 'auto',
        maxWidth: '100%',
        border: 'none',
      },
      formContainer: {
        marginTop: '20px',
        maxWidth: '100%'
      },
      passwordInput: {
        backgroundColor: '#f0f0f0 !Important',
        borderRadius: '4px',
        '& :hover': {
          backgroundColor: '#f0f0f0',
        },
      },
      small_banner: {
        fontSize: '10px'
      },
      emailInput: {
        borderRadius: '8px',
        backgroundColor: '#f0f0f0',
      },
      button: {
        fontSize: '14px',
        marginTop: '10px',
        marginBottom: '10px',
        borderRadius: '5px',
      },
      linksContainer: {
        display: 'block',
        margin: '10px 0'
      }
    }

  };
});

export default styles;
