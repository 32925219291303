import { AspectRatioRounded, DirectionsRunRounded } from '@mui/icons-material';
import { Box, FilledInput, FormControl, IconButton, InputLabel, TextField, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useAspectRatio from '../../../../hooks/useAspectRatio';
import { useState } from 'react';
import styles from './styles';
import { listEnverusWells } from '../../../../store/actions/enverus/wells';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { REDUX_ACTIONS } from '../../../../shared/config/constants';
import { CALENDER_PROPS } from '../../../../utils/constants';

const EnverusWellsControls = () => {
  const dispatch = useDispatch();
  const handleAspectRatio = useAspectRatio();
  const { preferences } = useSelector((state) => state.currentUser);
  const classes = styles();
  const [selectedStartDateTime, setSelectedStartDateTime] = useState(null);
  const [selectedEndDateTime, setSelectedEnDateTime] = useState(null);
  const [envBasin, setEnvBasin] = useState(null);

  const handleBasinTerm = (e) => {
    setEnvBasin(e.target.value);
  };

  const handleStartTime = (date) => {
    setSelectedStartDateTime(date);
  };

  const handleEndTime = (date) => {
    setSelectedEnDateTime(date);
  };

  const handleSearch = () => {
    const filters = [];
    if (envBasin) {
      filters.push({
        type: 'equals',
        field: 'ENVBasin',
        value: envBasin,
      });
    }
    if (selectedStartDateTime && selectedEndDateTime) {
      filters.push({
        type: 'between',
        field: 'FirstProdDate',
        value: [selectedStartDateTime, selectedEndDateTime],
      });
    }
    dispatch({
      type: REDUX_ACTIONS.ENVERUS_WELLS_SET_CURRENT_PAGE,
      payload: 0
    });
    dispatch(listEnverusWells({ filters }));
  };

  return (
    <Grid alignItems="flex-end" justifyContent="flex-end" sx={{ marginLeft: 'auto' }}>
      <Box className={classes.container}>
        <FormControl>
          <InputLabel
            htmlFor="outlined-adornment-search"
            inputprops={{ classes: { root: classes.searchBar } }}
          >
            Search Basin
          </InputLabel>
          <FilledInput
            className={classes.searchBar}
            id="outlined-adornment-search"
            type="text"
            label="Enter ENVBasin"
            value={envBasin || ''}
            onChange={handleBasinTerm}
          />
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            {...CALENDER_PROPS}
            label="Start Date"
            value={selectedStartDateTime}
            onChange={handleStartTime}
            className={classes.dateTimePicker}
            classes={{
              switchHeader: classes.datePickerHeader,
            }}
            textField={(params) => <TextField {...params} />}
          />
          <DateTimePicker
            {...CALENDER_PROPS}
            label="End Date"
            value={selectedEndDateTime}
            onChange={handleEndTime}
            className={classes.dateTimePicker}
            disabled={!selectedStartDateTime}
            minDateTime={selectedStartDateTime}
            textField={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
          <IconButton
            size="large"
            aria-label="orders"
            onClick={handleSearch}
          >
            <DirectionsRunRounded />
          </IconButton>
        <IconButton
          edge="end"
          onClick={() => {
            handleAspectRatio(!preferences.aspectChanged);
          }}
        >
          <AspectRatioRounded />
        </IconButton>
      </Box>
    </Grid>
  );
};

export default EnverusWellsControls;
