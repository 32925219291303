import { REDUX_ACTIONS } from '../../shared/config/constants';

const initialState = {
  loading: false,
  deleting: false,
  data: [],
  errorMessage: '',
  loadingDelete: false,
  saved: false,
  paginatedElements: 0,
};

const marketplaceTransactions = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.MARKETPLACE_TRANSACTION_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case REDUX_ACTIONS.MARKETPLACE_TRANSACTION_SET_LIST:
      return {
        ...state,
        loading: false,
        data: [...action.payload.transactionHistory],
        paginatedElements: action.payload.paginatedElements,
        errorMessage: '',
      };
    case REDUX_ACTIONS.MARKETPLACE_TRANSACTION_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: [],
      };
    default:
      return state;
  }
};

export default marketplaceTransactions;
