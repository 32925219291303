import { REDUX_ACTIONS } from '../../../shared/config/constants';

const initialState = {
  loading: false,
  errorMessage: '',
  data: [],
  marker_data: [],
  paginatedElements: 0,
  searchTerm: '',
  initialData: [],
  syncResponseMessage: '',
  wellDetails: {},
  wellDetailsLoading: false,
  wellDetailsError: '',
  nextPageLink: '',
  prevPageLink: '',
  totalCount: 0,
  currentPage: 0,
  limit: 100
};

const enverusWellsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.ENVERUS_WELLS_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };

    case REDUX_ACTIONS.ENVERUS_WELL_DETAILS_LOADING:
      return {
        ...state,
        wellDetails: {},
        wellDetailsLoading: true,
        wellDetailsError: '',
      };

    case REDUX_ACTIONS.ENVERUS_WELLS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...action.payload.data],
        marker_data: [...action.payload.data],
        initialData: [...action.payload.data],
        nextPageLink: action.payload.nextPageLink,
        prevPageLink: action.payload.prevPageLink,
        totalCount: action.payload.totalCount,
        paginatedElements: action.payload.paginatedElements,
        errorMessage: '',
      };

    case REDUX_ACTIONS.ENVERUS_WELL_DETAILS_SUCCESS:
      return {
        ...state,
        wellDetails: action.payload,
        wellDetailsLoading: false,
        wellDetailsError: '',
      };

    case REDUX_ACTIONS.ENVERUS_WELLS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: [],
        marker_data: []
      };

    case REDUX_ACTIONS.ENVERUS_WELL_DETAILS_ERROR:
      return {
        ...state,
        wellDetails: {},
        wellDetailsLoading: false,
        wellDetailsError: action.payload.message,
      };

    case REDUX_ACTIONS.ENVERUS_WELLS_SEARCH_CHANGE:
      return {
        ...state,
        searchTerm: action.payload.value,
      };

    case REDUX_ACTIONS.ENVERUS_WELLS_FILTER:
      return {
        ...state,
        data: [
          ...state.initialData.filter((item) =>
            item?.ENVBasin?.toUpperCase().includes(state.searchTerm.toUpperCase())
          ),
        ],
        marker_data: [
          ...state.initialData.filter((item) =>
            item?.ENVBasin?.toUpperCase().includes(state.searchTerm.toUpperCase())
          ),
        ],
      };

    case REDUX_ACTIONS.ENVERUS_WELLS_MARKER_FILTER:
      return {
        ...state,
        marker_data: [
          ...action.payload.data
        ],
      };

    case REDUX_ACTIONS.ENVERUS_WELLS_RESET:
      return {
        ...initialState,
      };

    case REDUX_ACTIONS.ENVERUS_WELLS_SYNC_LOADING:
      return {
        ...state,
        loadingWellSync: true,
        syncResponseMessage: action.payload.message,
      };

    case REDUX_ACTIONS.ENVERUS_WELLS_SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case REDUX_ACTIONS.ENVERUS_WELLS_SYNC_IDLE:
      return {
        ...state,
        loadingWellSync: false,
        syncResponseMessage: action.payload.message,
      };

    case REDUX_ACTIONS.ENVERUS_WELLS_SYNC_ERROR:
      return {
        ...state,
        loadingWellSync: false,
        syncResponseMessage: action.payload.message,
      };

    default:
      return state;
  }
};

export default enverusWellsReducer;
