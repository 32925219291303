import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { gridClasses } from '@mui/x-data-grid';
import { renderCell } from '../ui/Datagrid/table_config';
import RenderJsonField from '../ui/RenderJsonField';
import Datagrid from '../ui/Datagrid';
import columns from './table_config';
import { getMarkeplaceTransactionList } from '../../store/actions/marketplaceTransactions';

const MarketplaceTransactions = () => {
  const dispatch = useDispatch();
  const marketplaceTransactions = useSelector((state) => state.marketplaceTransactions);

  useEffect(() => {
    if (!marketplaceTransactions.loading && marketplaceTransactions.data.length === 0) {
      dispatch(getMarkeplaceTransactionList());
    }
  }, []);

  return <Grid container justifyContent="center">
    <Grid item xs={12}>
      <Datagrid
        data={marketplaceTransactions.data}
        autoHeight={false}
        columns={columns(renderCell, RenderJsonField)}
        loading={false}
        pageSize={100}
        disableStickyHeader={false}
        onRowClick={() => null}
        height={'90vh'}
        getRowHeight={() => 'auto'}
        sx={{
          '.MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold !important',
          },
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
        }}
        toolbar={{
          options: {
            columns: true,
            filters: true,
            export: false,
          },
        }}
      />
    </Grid>
  </Grid>;
};

export default MarketplaceTransactions;
