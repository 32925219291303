import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: '100%',
  },
  containerGrid: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  }
}));

export default useStyles;
