import fetch from '../../../api/fetch';
import { REDUX_ACTIONS } from '../../../shared/config/constants';
import { parseError } from '../../../utils/fetchErrorParser';
import { enqueueSnackbar } from '../snackbar';

export const listEpicorTrucks = () => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.EPICOR_TRUCKS_LIST_LOADING,
  });

  const body = {
    type: 'GET',
    url: '/bins/trucks',
    params: {
      top: 2500,
      company: 'POT',
    },
  };

  return fetch
    .post('epicorProxy', body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.EPICOR_TRUCKS_LIST_SUCCESS,
        payload: {
          epicorTrucks: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);

      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.EPICOR_TRUCKS_LIST_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};

export const syncEpicorTruck = (binNumber) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.EPICOR_SYNC_TRUCK_LOADING,
    payload: {
      loading: true,
      message: 'Please wait...',
    },
  });

  const body = {
    url: '/sync/syncTrucks',
    type: 'POST',
    binNum: binNumber
  };
  return fetch
    .post('epicorProxy/epicorProxyPost', body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
    }).then(() => {
      dispatch({
        type: REDUX_ACTIONS.EPICOR_SYNC_TRUCK_IDLE,
        payload: {
          loading: false,
          message: 'Truck Synced Successfully'
        },
      });
    })
    .catch(async (error) => {
      dispatch(
        enqueueSnackbar(
          error.message || 'Error syncing truck',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.EPICOR_SYNC_TRUCK_ERROR,
        payload: {
          loading: false,
          message: 'Error in Sync'
        },
      });
    });
};
