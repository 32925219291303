import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  formControl: {
    marginLeft: '0px',
    marginRight: '0px',
    marginTop: '0px',
    marginBottom: '0px',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '0px',
    paddingBottom: '0px',
    display: 'flex',
    direction: 'column',
    justifyContent: 'stretch',
    alignContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  lineHeight: {
    lineHeight: 1,
    backgroundColor: 'white',
  },
  textField: {
    width: '400px',
    height: 'auto',
  },
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
}));

export default styles;
