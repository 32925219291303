import { useSelector } from 'react-redux';

const columns = (renderCell, renderJsonField) => {
  const user = useSelector((state) => state.currentUser);

  return [
    {
      field: 'lastUpdate',
      headerName: 'Timestamp',
      width: 250,
      hide: false,
      renderCell,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'rig',
      headerName: 'Rig',
      width: 300,
      renderCell,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'message',
      headerName: 'Summary',
      width: 300,
      renderCell,
      headerAlign: 'center',
      align: 'left',
      flex: user.preferences.aspectChanged ? 1 : 'none',
      sortable: false
    },
    {
      field: 'details',
      headerName: 'Details',
      width: 300,
      renderCell: renderJsonField,
      headerAlign: 'center',
      align: 'left',
      flex: user.preferences.aspectChanged ? 1 : 'none',
      sortable: false
    },
  ];
};

export default columns;
