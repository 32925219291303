import { DEFAULT_ACTUATOR_FILTERS, GRID_COLUMNS_TO_EXCLUDE } from '../../../utils/constants';

function findLowestActuator(dataObject) {
  const keys = Object.keys(dataObject);
  let lowestActuator = null;

  keys.forEach(key => {
    if (key.startsWith('~')) {
      const actuator = key.split('_')[0]; // Extract the actuator part (~1, ~2, etc.)
      if (!lowestActuator || actuator < lowestActuator) {
        lowestActuator = actuator;
      }
    }
  });

  return lowestActuator;
}

export const generateDefaultActuatorColumns = (dataObject) => {
  const filteredDataObject = {};
  const columnsInitialState = {};
  const columnsColorDefinition = {};

  // Filter out excluded fields from dataObject
  Object.keys(dataObject).forEach(key => {
    if (!GRID_COLUMNS_TO_EXCLUDE.includes(key)) {
      filteredDataObject[key] = dataObject[key];
    }
  });

  // create template filtered object with all keys set to false except timestamp
  Object.keys(filteredDataObject).forEach(key => {
    if (key !== 'timestamp') {
      columnsInitialState[key] = false;
    }
  });

  const lowestActuator = findLowestActuator(filteredDataObject);
  const defaultCols = DEFAULT_ACTUATOR_FILTERS[0].fields;

  // set the default keys to show as true
  defaultCols.forEach(item => {
    const fullKey = `${lowestActuator}_${item.column}`;
    // eslint-disable-next-line no-prototype-builtins
    if (filteredDataObject.hasOwnProperty(fullKey)) {
      columnsInitialState[fullKey] = true;
    }
  });

  const datakeys = Object.keys(columnsInitialState).filter(key => key !== 'timestamp' && columnsInitialState[key]);

  defaultCols.forEach(item => {
    const key = Object.keys(columnsInitialState).find(key => key.endsWith(`_${item.column}`) && columnsInitialState[key]);
    if (key) {
      columnsColorDefinition[key] = item.color;
    }
  });

  return { columnsInitialState, datakeys, columnsColorDefinition };
};
