import { makeStyles } from '@mui/styles';
const styles = makeStyles(() => ({
  dialogModal: {
    '& .MuiPaper-root': {
      marginLeft: '10px',
      '& > div:first-child': {
        padding: '4px 11px !important',
      }
    },
    '& .MuiTypography-subtitle1': {
      fontSize: '1.3rem',
      fontWeight: 'bold',
    }
  },
  carousel: {
    '& ul': {
      margin: 0,
    },
  },
  actionIcon: {
    backgroundColor: 'transparent',
    borderRadius: '50%',
    color: '#000',
    fontSize: '50px',
  }
}));
export default styles;
