import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TicketsView from '../TicketsView';

import classNames from 'classnames';
import styles from './styles';
import {
  FormControl,
  InputLabel,
  FilledInput,
  Typography,
  Grid,
  Select,
  MenuItem,
} from '@mui/material';
import { isMobile } from '../../../utils/isMobile';
import ButtonComponent from '../../Button';

const ModalDialog = ({
  title,
  children,
  buttons,
  isOpen,
  onClose,
  showXButton,
  dialogClass,
  isFullScreen,
  extraFields,
  iconActions,
  ticketView,
  validationFields,
  maxWidth
}) => {
  const classes = styles();

  const renderDialogTitle = (children, onClose) => (
    <div className={classes.dialogTitle}>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: isMobile() && '15px' }}>{children}</Typography>
      {onClose && showXButton && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      )}
    </div>
  );

  const renderActionButtons = (buttons) =>
    buttons.map(({ text, ...config }, idx) => (
      <ButtonComponent
        label={text}
        autoFocus
        key={`dialog_action_button_${text}_${idx}`}
        variant={config.variant || 'contained'}
        size={config.size || 'small'}
        className={classNames(config.className || classes.actionButton)}
        {...config}
      />
    ));

  const getValidationMessage = (inputName) => {
    const field = validationFields.find((obj) => obj.name === inputName);
    return field ? field.message : '';
  };

  const renderExtraFields = (extraFields) => {
    const getField = ({
      type,
      label,
      value,
      handleChange,
      data,
      fieldName,
      keyValue,
      ref,
      inputName,
    }) => {
      switch (type) {
        case 'text':
          return (
            <FormControl
              fullWidth
              variant="filled"
              className={classes.inputField}
              sx={{ bgcolor: 'transparent' }}
              value={value}
              onChange={handleChange}
            >
              <InputLabel htmlFor="outlined-adornment-select">
                {label}
              </InputLabel>
              <FilledInput
                id="outlined-adornment-select"
                value={value}
                name={inputName}
              />
              <div className={classes.requiredMessage}>
                {getValidationMessage(inputName)}
              </div>
            </FormControl>
          );
        case 'select':
          return (
            <FormControl
              variant="filled"
              className={classes.inputField}
              sx={{ bgcolor: 'transparent' }}
            >
              <InputLabel id="demo-simple-select-standard-label">
                {label}
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={value}
                onChange={handleChange}
                label={label}
                ref={ref}
                name={inputName}
              >
                <MenuItem value="">None</MenuItem>
                {data.map((item, idItem) => (
                  <MenuItem
                    key={`item_${item[fieldName]}_${idItem}`}
                    value={keyValue ? item[keyValue] : item[fieldName]}
                  >
                    {item[fieldName]}
                  </MenuItem>
                ))}
              </Select>
              <div className={classes.requiredMessage}>
                {getValidationMessage(inputName)}
              </div>
            </FormControl>
          );
        default:
          break;
      }
    };

    return extraFields.reduce((chunks, field, idx) => {
      if (idx % 4 === 0) {
        // Start a new chunk for every 4 items
        chunks.push([]);
      }
      chunks[chunks.length - 1].push(field);
      return chunks;
    }, []).map((chunk, chunkIdx) => (
      <Grid container spacing={2} key={`chunk_${chunkIdx}`} style={{ marginTop: '0px' }}>
        {chunk.map((field, idx) => (
      <Grid
        item
            xs={chunk.length === 4 ? 3 : 4}
            key={`extra_field_${chunkIdx}_${idx}`}
            style={{}}
      >
        {getField(field)}
          </Grid>
        ))}
      </Grid>
    ));
  };

  const renderIconButtons = (iconActions) => {
    return iconActions.enabled
      ? iconActions.actions.map(({ label, icon, ...config }, idx) => (
        <Grid item key={`icon_${idx}`}>
          <IconButton
            aria-label={label}
            className={classNames(config.className || classes.iconButton)}
            {...config}
          >
            {icon}
          </IconButton>
        </Grid>))
      : null;
  };

  const getBinNum = (text) => {
    const data = text.split(' ');
    return data[0];
  };

  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      className={classNames(dialogClass, !isMobile() && classes.dialogModal)}
      fullWidth={isFullScreen}
      maxWidth={maxWidth || 'xl'}
      fullScreen={isMobile() && isFullScreen}
    >
      {ticketView && ( // ticketView === true means this is step 2 in the flow
        <Grid item xs={12} id="ticketview-true">
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={9}>
              {title && renderDialogTitle(title, onClose)}
              <DialogContent className={classes.dialogContent} dividers>
                {children}
              </DialogContent>
              {buttons && (<div>
                <DialogActions style={{
                  borderTop: '1px solid #f0f0f0',
                  paddingTop: '0px',
                  paddingRight: '4%',
                  paddingLeft: '4%',
                  paddingBottom: '16px',
                }}>
                  {extraFields && <Grid container>{renderExtraFields(extraFields)}</Grid>}
                  {iconActions && (
                    <Grid container justifyContent="left">
                      {renderIconButtons(iconActions)}
                    </Grid>
                  )}
                </DialogActions>
                <DialogActions style={{
                  borderTop: '1px solid #f0f0f0',
                  paddingTop: '20px'
                }}>
                  {renderActionButtons(buttons)}
                </DialogActions>
              </div>)}
            </Grid>
            <Grid item xs={3} style={{ borderLeft: '1px solid #ccc' }}>
            <TicketsView
              binNum={getBinNum(title)}
              onCloseModal={onClose}
            >
              {children}
            </TicketsView>
            </Grid>
          </Grid>
        </Grid>
      )}

      {title && !ticketView && renderDialogTitle(title, onClose)}

      {!ticketView && ( // ticketView === false means this is step 1 in the flow
        <DialogContent className={classes.dialogContent} dividers>
          {children}
        </DialogContent>
      )}
      {(buttons && !ticketView) && ( // ticketView === false means this is step 1 in the flow
        <>
          <DialogActions style={{
            borderTop: '1px solid #f0f0f0',
            marginTop: '10px',
            paddingLeft: '5%',
            paddingRight: '5%',
            paddingTop: '20px',
            paddingBottom: '20px',
          }}>
            {extraFields && (
              <Grid container justifyContent="left" spacing={2}>
                {renderExtraFields(extraFields)}
              </Grid>
            )}
          </DialogActions>
          <DialogActions>
            {iconActions && (
              <Grid container justifyContent="left">
                {renderIconButtons(iconActions)}
              </Grid>
            )}
            {renderActionButtons(buttons)}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

ModalDialog.propTypes = {
  title: PropTypes.any,
  children: PropTypes.node.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      variant: PropTypes.string,
      placeholder: PropTypes.string,
      'aria-label': PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  showXButton: PropTypes.bool,
  dialogClass: PropTypes.string,
  maxWidth: PropTypes.string,
  isFullScreen: PropTypes.bool,
  extraFields: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  iconActions: PropTypes.shape({
    enabled: PropTypes.bool,
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  ticketView: PropTypes.bool,
  validationFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      filled: PropTypes.bool,
      message: PropTypes.string,
    })
  ),
};

ModalDialog.defaultProps = {
  title: null,
  children: null,
  buttons: null,
  isOpen: false,
  onClose: null,
  showXButton: false,
  dialogClass: null,
  isFullScreen: true,
  extraFields: null,
  iconActions: null,
  ticketView: false,
  validationFields: [],
};

export default ModalDialog;
