const columns = (renderCell, RenderJsonField, renderActions) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'details',
    headerName: 'Details',
    width: 300,
    renderCell: (data) => {
      const value = data.value.reduce((obj, item) => Object.assign(obj, { [item.propertyName]: item.valueType }), {});
      return <RenderJsonField value={value} />;
    }
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 250,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  },
];

export default columns;
