/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../ModalDialog';
import { Box, Checkbox, FilledInput, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, InputLabel } from '@mui/material';
import { SPECIAL_INSTRUCTIONS } from '../../../utils/constants';
import _ from 'lodash';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { listUsers } from '../../../store/actions/users';
import { useAuth } from '../../../hooks/useAuth';
import AutocompleteComponent from '../Autocomplete';
import { CancelPresentationRounded } from '@mui/icons-material';

const SpecialInstructionsDialog = ({
  isOpen,
  data,
  onCloseClick,
  handleSave
}) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const [checkedValues, setCheckedValues] = useState({});
  const [notificationAmount, setNotificationAmount] = useState(data?.specialInstructions ? data?.specialInstructions?.['2'] : 0);
  const [epicorTransaction, setEpicorTransaction] = useState({
    custNum: data?.specialInstructions?.['4'] ? data?.specialInstructions['4']?.[0] : 0,
    shipToNum: data?.specialInstructions?.['4'] ? data?.specialInstructions['4']?.[1] : 0
  });
  const [mktAdmins, setMktAdmins] = useState(data?.specialInstructions?.['5'] || []);
  const [salesReps, setSalesReps] = useState(data?.specialInstructions?.['6'] || []);
  const [mktAdminsList, setMktAdminsList] = useState([]);

  useEffect(() => {
    if (!users.data.length) {
      dispatch(listUsers(user));
    }
  }, []);

  function filterPremiumEmails(array) {
    return array
      .filter(item => item?.emailAddress?.includes('premiumoilfield.com'))
      .map(item => item.emailAddress);
  }

  useEffect(() => {
    if (users.data.length) {
      // const mktAdminUsers = users.data.filter(item => item.type === USER_TYPE.PREMIUM_MARKETPLACE_ADMIN).map(item => item.emailAddress);
      const premiumUsers = filterPremiumEmails(users.data);
      setMktAdminsList(premiumUsers);
    }
  }, [users.data]);

  useEffect(() => {
    if (isOpen && data?.specialInstructions) {
      setCheckedValues(data?.specialInstructions || {});
    }
  }, [isOpen]);

  const handleChange = (event) => {
    const value = Number(event.target.value);
    setCheckedValues((prevValues) => {
      const newValues = { ...prevValues };
      // eslint-disable-next-line no-prototype-builtins
      if (newValues.hasOwnProperty(value)) {
        delete newValues[value];
      } else {
        newValues[value] = value === 2 ? 0 : value === 4 ? [0, 0] : value === 5 ? [] : '';
      }
      return newValues;
    });
  };

  const onDialogCloseClick = () => {
    onCloseClick();
  };

  const getModalTitle = () => {
    return (
      <>
      <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {`${data?.name || data?.companyName} - Special Instructions`}
          </Grid>
          <Grid item>
          <IconButton
              onClick={onCloseClick}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const createDialogButtons = () => [
    {
      text: 'Ok',
      placeholder: 'Ok',
      'aria-label': 'ok',
      onClick: () => {
        if (!_.isEqual(data?.specialInstructions, checkedValues)) {
          const updatedData = { ...data };
          updatedData.specialInstructions = { ...checkedValues };
          handleSave(updatedData);
        }
        onCloseClick();
      },
    },
  ];

  return (
    <ModalDialog
      title={getModalTitle()}
      isOpen={isOpen}
      onClose={onDialogCloseClick}
      buttons={createDialogButtons()}
      maxWidth={'sm'}
    >
          <Grid sx={{ padding: 4 }}>
              <FormGroup>
                  {SPECIAL_INSTRUCTIONS.map((item) => (
                   <Box key={item.value} display='flex' alignItems='center' sx={{ mb: 4 }}>
                      <FormControlLabel
                          control={
                              <Checkbox
                                  value={item.value}
                                  checked={checkedValues.hasOwnProperty(item.value)}
                                  onChange={handleChange}
                              />
                          }
                          label={item.label}
                          componentsProps={{ typography: { variant: 'body2' } }}
                      />
                      {item.value === 2 && checkedValues.hasOwnProperty(2) && (
                        <Box>
                          <FilledInput
                            id="outlined-adornment-search"
                            type="number"
                            label="Notification Amount"
                            value={notificationAmount}
                            onChange={(e) => {
                              setNotificationAmount(e.target.value);
                              setCheckedValues((prevValues) => {
                                const newValues = { ...prevValues };
                                newValues['2'] = Number(e.target.value);
                                return newValues;
                              });
                            }}
                            inputProps={{ style: { fontSize: 18, margin: 0, padding: 4 } }}
                            sx={{
                              width: '140px',
                              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                display: 'none',
                              },
                              '& input[type=number]': {
                                MozAppearance: 'textfield',
                              },
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                  <AttachMoneyOutlinedIcon fontSize='small' />
                              </InputAdornment>
                            }
                          />
                        </Box>
                      )}
                      {item.value === 4 && checkedValues.hasOwnProperty(4) && (
                        <Box>
                          <FormControl
                            sx={{
                              bgcolor: 'transparent',
                            }}
                            variant="filled"
                            onChange={(e) => {
                              setEpicorTransaction((prevValues) => {
                                return {
                                  ...prevValues,
                                  custNum: e.target.value
                                };
                              });
                              setCheckedValues((prevValues) => {
                                const newValues = { ...prevValues };
                                newValues['4'] = [e.target.value, epicorTransaction.shipToNum];
                                return newValues;
                              });
                            }}
                          >
                            <InputLabel sx={{ fontSize: 14 }} htmlFor="cust-num">
                              Cust Num
                            </InputLabel>
                            <FilledInput
                              id="cust-num"
                              value={epicorTransaction.custNum}
                              inputProps={{ style: { fontSize: 16 } }}
                              sx={{
                                width: '140px',
                                mr: 2,
                              }}
                            />
                          </FormControl>
                          <FormControl
                            sx={{
                              bgcolor: 'transparent',
                            }}
                            variant="filled"
                            onChange={(e) => {
                              setEpicorTransaction((prevValues) => {
                                return {
                                  ...prevValues,
                                  shipToNum: e.target.value
                                };
                              });
                              setCheckedValues((prevValues) => {
                                const newValues = { ...prevValues };
                                newValues['4'] = [epicorTransaction.custNum, e.target.value];
                                return newValues;
                              });
                            }}
                          >
                            <InputLabel sx={{ fontSize: 14 }} htmlFor="ship-to-num">
                              Ship To Num
                            </InputLabel>
                            <FilledInput
                              id="ship-to-num"
                              value={epicorTransaction.shipToNum}
                              inputProps={{ style: { fontSize: 16 } }}
                              sx={{
                                width: '140px',
                              }}
                            />
                          </FormControl>
                        </Box>
                      )}
                      {item.value === 5 && checkedValues.hasOwnProperty(5) && (
                        <Grid sx={{ width: '75%' }}>
                         <AutocompleteComponent
                          title={'MKT Admins'}
                          data={mktAdmins}
                          options={mktAdminsList}
                          onChange={(event, values) => {
                            setMktAdmins([...values]);
                            setCheckedValues((prevValues) => {
                              const newValues = { ...prevValues };
                              newValues['5'] = [...values];
                              return newValues;
                            });
                          }}
                          hideHeader
                          loading={users?.loading}
                          />
                        </Grid>
                      )}
                       {item.value === 6 && checkedValues.hasOwnProperty(6) && (
                        <Grid sx={{ width: '77%' }}>
                         <AutocompleteComponent
                          title={'Sales Reps'}
                          data={salesReps}
                          options={mktAdminsList}
                          onChange={(event, values) => {
                            setSalesReps([...values]);
                            setCheckedValues((prevValues) => {
                              const newValues = { ...prevValues };
                              newValues['6'] = [...values];
                              return newValues;
                            });
                          }}
                          hideHeader
                          loading={users?.loading}
                          />
                        </Grid>
                       )}
                    </Box>
                  ))}
              </FormGroup>
        </Grid>
    </ModalDialog>
  );
};

SpecialInstructionsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.object,
  onCloseClick: PropTypes.func.isRequired,
  handleSave: PropTypes.func
};

export default SpecialInstructionsDialog;
