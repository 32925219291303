import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Autocomplete, Box, Chip, TextField } from '@mui/material';
import { CancelPresentation } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { listUsersWithFilter } from '../../../store/actions/users';

// eslint-disable-next-line react/prop-types
const RecipientsComponent = ({ title, data, onChange, options, loading }) => {
  const [inputValue, setInputValue] = React.useState('');
  return (
        <Box sx={{ mb: 2.5 }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: '15px' }} gutterBottom>
            {title}
          </Typography>
                  <Autocomplete
                      loading={loading}
                      multiple
                      id={title}
                      options={options}
                      value={data}
                      onChange={onChange}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          const { key, ...tagProps } = getTagProps({ index });
                          return (
                                  <Chip variant="outlined" label={option} key={key} {...tagProps} />
                          );
                        })
                      }
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              variant="outlined"
                              label=""
                              placeholder={'Add ' + title}
                          />
                      )}
                  />
        </Box>
  );
};
const RecipientsDialog = ({ open, handleClose, rowData, handleSave }) => {
  const dispatch = useDispatch();
  const [recipients, setRecipients] = useState('');
  const [premiumUsers, setPremiumUsers] = useState([]);
  const users = useSelector((state) => state.users);

  useEffect(() => {
    if (!users.loading && !users.data.length) {
      dispatch(listUsersWithFilter({
        filters: [
          {
            columnField: 'emailAddress',
            operatorValue: 'endsWith',
            value: '@premiumoilfield.com'
          }
        ],
        sort: { emailAddress: 'asc' },
      }));
    }
  }, []);

  useEffect(() => {
    if (users.data.length) {
      const premiumEmails = users.data
        .map(user => user.emailAddress);
      setPremiumUsers(premiumEmails);
    }
  }, [users.loading]);

  useEffect(() => {
    setRecipients(rowData?.recipients || '');
  }, [rowData]);

  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth='md'
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {rowData.signal}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CancelPresentation />
        </IconButton>
        <DialogContent dividers sx={{ minWidth: '600px' }}>
            <RecipientsComponent
                title='Recipients'
                data={recipients.length ? recipients.split(' ') : []}
                onChange={(event, values) => setRecipients([...values].join(' '))}
                options={premiumUsers}
                loading={users.loading}
            />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => {
            const updatedData = { ...rowData };
            updatedData.recipients = recipients;
            handleSave(updatedData);
            handleClose();
          }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

RecipientsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired
};

export default RecipientsDialog;
