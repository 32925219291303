import { Box, IconButton } from '@mui/material';
import React from 'react';
import { TransformWrapper, TransformComponent, useControls } from 'react-zoom-pan-pinch';
import { RestoreOutlined, ZoomInOutlined, ZoomOutOutlined } from '@mui/icons-material';
import PropTypes from 'prop-types';
import styles from './styles';

function ImageZoom({ children }) {
  const classes = styles();

  const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    return (
              <Box className={classes.zoomControls}>
                  <IconButton className={classes.zoomControlIcon} onClick={() => zoomIn()}><ZoomInOutlined /></IconButton>
                  <IconButton className={classes.zoomControlIcon} onClick={() => zoomOut()}><ZoomOutOutlined /></IconButton>
                  <IconButton className={classes.zoomControlIcon} onClick={() => resetTransform()}><RestoreOutlined /></IconButton>
              </Box>
    );
  };

  return (
    <Box className={classes.zoomImageContainer}>
        <TransformWrapper>
            <>
                <Controls />
                <TransformComponent>
                    {children}
                </TransformComponent>
            </>
        </TransformWrapper>
    </Box>
  );
}

export default ImageZoom;

ImageZoom.propTypes = {
  children: PropTypes.element
};
