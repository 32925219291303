import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  map: { height: '50vh', width: '100%', border: '5px solid white' },
  noActionPin: {
    cursor: 'initial',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  title: {
    textAlign: 'center',
    width: '600px',
  },
  logo: {
    width: '70%',
    margin: 'auto',
  },
  statusMessage: {
    textAlign: 'center',
  },
}));

export default styles;
