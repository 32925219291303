import { makeStyles } from '@mui/styles';
const styles = makeStyles(({ spacing }) => ({
  chart: {},
  chartContainer: {
    marginTop: spacing(3),
    marginBottom: spacing(3),
  },
  speedChartContainer: {
    marginTop: spacing(0),
    marginBottom: spacing(0),
  },
  renderCell: {
    width: '100%',
    fontSize: 'medium',
  },
  speedLabel: { marginTop: -20, fontWeight: 'bold', fontSize: 16 },
}));

export default styles;
