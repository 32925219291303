import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import { Box, Grid, IconButton, InputBase, Slide, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import styles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { searchMarketplace } from '../../../store/actions/marketplace';
import FeaturedCard from '../../ui/marketplace/FeaturedCard';
import { REDUX_ACTIONS } from '../../../shared/config/constants';
import Premium from '../../../assets/images/PRO logo PMS green only.svg';
import { isMobile } from 'react-device-detect';
import ScrollToTopButton from '../../ui/ScrollToTopButton';
import useScrollEnd from '../../../utils/useScrollEnd';

function SearchScreen({ open, handleClose }) {
  const classes = styles();
  const dispatch = useDispatch();
  const inputRef = useRef();
  const searchRef = useRef();
  const [searchText, setSearchText] = useState('');
  const products = useSelector((state) => state.marketplace.search);
  const [showInput, setShowInput] = useState(false);
  const scrollRef = useRef();
  const itemsPerPage = 51;
  const pageReachedEnd = useScrollEnd(scrollRef);
  const totalPages = Math.ceil(products.paginatedElements / itemsPerPage);

  useEffect(() => {
    if (pageReachedEnd && !products.loadingNext && products.currentPage < totalPages) {
      dispatch({
        type: REDUX_ACTIONS.MARKETPLACE_SEARCH_LOAD_NEXT
      });
    }
  }, [pageReachedEnd]);

  useEffect(() => {
    if (products.loadingNext) {
      dispatch(searchMarketplace(searchText, products.currentPage + 1));
    }
  }, [products.loadingNext]);

  useEffect(() => {
    const body = document.querySelector('body');
    setTimeout(() => {
      setShowInput(true);
      body.style.overflow = 'hidden';
    }, 300);

    return () => {
      body.style.overflow = 'auto';
    };
  }, []);

  const handleSearch = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
    dispatch(searchMarketplace(searchText, 1));
    searchRef.current = true;
  };

  const closeSearchScreen = () => {
    handleClose();
    setSearchText('');
    dispatch({
      type: REDUX_ACTIONS.MARKETPLACE_SEARCH_ERROR
    });
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 1000);
      dispatch(searchMarketplace(searchText, 1));
    }
    return () => {
      setSearchText('');
      dispatch({
        type: REDUX_ACTIONS.MARKETPLACE_SEARCH_ERROR
      });
    };
  }, [open]);

  return (
      <Backdrop
          sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgb(144 144 144)' }}
          open={open}
      >
          <Box ref={scrollRef} className={classes.searchBarContainer}>
            <Slide
              in={showInput}
              direction='down'
              >
                  <Grid container className={classes.fixedSearch}>
                  <Box
                      component="img"
                      alt="Logo"
                      src={Premium}
                      className={classes.logo}
                      sx={{ mr: 2 }}
                    />
                    <Grid className={classes.searchBar} container alignItems='center'>
                    <IconButton onClick={closeSearchScreen}>
                          <ArrowBackIosIcon className={classes.icon} sx={{ ml: 2 }} />
                      </IconButton>
                    <Box sx={{ flex: 1 }}>
                    <InputBase
                        inputRef={inputRef}
                        fullWidth
                        sx={{ flex: 1, fontSize: isMobile ? '14px' : '18px', fontWeight: 'bold' }}
                        placeholder="Search Products"
                        value={searchText}
                        onChange={(e) => {
                          searchRef.current = null;
                          setSearchText(e.currentTarget.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') { handleSearch(); }
                        }}
                    />
                    </Box>
                      <IconButton onClick={handleSearch}>
                          <SearchIcon className={classes.icon} sx={{ mr: 2 }} />
                      </IconButton>
                    </Grid>
                  </Grid>
              </Slide>
              <Box component='ul' className={classes.cardsContainer}>
                  {open && (<Box id='back-to-top-anchor' />)}
                  {searchText && searchRef.current && !products?.loading && products?.data.length === 0 && (
                      <Typography variant='h5' sx={{ marginTop: '20px' }}>No products found for {searchText}</Typography>
                  )}
                  {products?.loading && (
                    <>
                    {Array(9).fill(true).map((v, i) => (
                      <Box sx={{ width: '345px', margin: '10px' }} key={`part-card-${i}`}>
                        <FeaturedCard
                          loading={products?.loading}
                        />
                      </Box>
                    ))}
                    </>
                  )}
                  {!products?.loading && products?.data.map((item) => {
                    return (
                          <Box sx={{ width: '345px', margin: '10px' }} key={`part-card-${item._id}`}>
                              <FeaturedCard
                                  myimage={item.Image}
                                  pn={item.PN}
                                  description={item.Description}
                                  category={item.Category}
                                  name={item.Name}
                                  classification={item.Classification}
                                  price={item.Price}
                                  itemId={item._id}
                              />
                          </Box>
                    );
                  })}
              </Box>
              {products?.loadingNext && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#FFF', padding: '14px 0' }}>
                  <Typography variant="h6" fontWeight="bolder">Loading more..</Typography>
                </Box>
              )}
            {open && (
              <ScrollToTopButton
                scrollTarget={scrollRef?.current || undefined}
              />
            )}
          </Box>
      </Backdrop>
  );
}

SearchScreen.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func
};

export default SearchScreen;
