import { isMobile } from 'react-device-detect';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { DEFAULT_MOBILE_PRESET, DEFAULT_PRESET } from '../../utils/constants';

const initialState = {
  loading: false,
  deleting: false,
  data: [],
  errorMessage: '',
  loadingDelete: false,
  saved: false,
  paginatedElements: 0,
  unassignedAssets: {
    loading: false,
    data: [],
  },
  dateRange: {
    preset: isMobile ? DEFAULT_MOBILE_PRESET : DEFAULT_PRESET,
    paused: false,
    start: null,
    end: null,
  },
  isDateRangeModalOpen: false,
  currentEvents: [],
  eventsLoading: false,
};

const assetDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.ASSET_DATA_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case REDUX_ACTIONS.ASSET_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...action.payload.assetData],
        paginatedElements: action.payload.paginatedElements,
        errorMessage: '',
      };
    case REDUX_ACTIONS.ASSET_DATA_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: [],
      };
    case REDUX_ACTIONS.ASSET_DATA_ERROR_NOTIFIED:
      return {
        ...state,
        errorMessage: '',
        data: [],
      };
    case REDUX_ACTIONS.ASSET_DATA_RESET:
      return {
        ...initialState,
      };
    case REDUX_ACTIONS.ASSET_DATA_SET_PRESET:
      return {
        ...state,
        dateRange: {
          ...state.dateRange,
          preset: action.payload,
        },
      };
    case REDUX_ACTIONS.ASSET_DATA_SET_DATES:
      return {
        ...state,
        dateRange: {
          ...state.dateRange,
          start: action.payload.dateRange.start,
          end: action.payload.dateRange.end,
        },
      };
    case REDUX_ACTIONS.ASSET_DATA_TOOGLE_DATES_MODAL:
      return {
        ...state,
        isDateRangeModalOpen: action.payload,
      };
    case REDUX_ACTIONS.ASSET_DATA_TOOGLE_PAUSE:
      return {
        ...state,
        dateRange: {
          ...state.dateRange,
          paused: !state.dateRange.paused,
        },
      };
    case REDUX_ACTIONS.ASSET_DATA_PAUSE:
      return {
        ...state,
        dateRange: {
          ...state.dateRange,
          paused: true,
        },
      };
    case REDUX_ACTIONS.ASSET_DATA_TAG_POST:
      return {
        ...state,
        currentEvents: [...action.payload.event],
      };
    case REDUX_ACTIONS.EVENTS_DATA_LOADING:
      return {
        ...state,
        eventsLoading: true,
        errorMessage: '',
      };
    case REDUX_ACTIONS.EVENTS_DATA_SUCCESS:
      return {
        ...state,
        eventsLoading: false,
        currentEvents: [...action.payload.events],
        paginatedElements: action.payload.paginatedElements,
        errorMessage: '',
      };
    default:
      return state;
  }
};

export default assetDataReducer;
