const columns = (RenderJsonField, renderActions, renderValueCell) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'run_details',
    headerName: 'Details',
    headerAlign: 'center',
    align: 'center',
    width: 300,
    renderCell: (data) => {
      return <RenderJsonField value={data?.value ? data?.value : {}} />;
    }
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 280,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  },
  {
    field: 'bh_esd',
    headerName: 'ESD (ppg)',
    width: 160,
    headerAlign: 'center',
    align: 'center',
    valueGetter: params => params?.row?.run_response?.summary?.bh_esd,
    cellClassName: 'cellLink'
  },
  {
    field: 'bh_ecd',
    headerName: 'ECD (ppg)',
    width: 160,
    headerAlign: 'center',
    align: 'center',
    valueGetter: params => params?.row?.run_response?.summary?.bh_ecd,
    cellClassName: 'cellLink'
  },
  {
    field: 'connection_sbp',
    headerName: 'SBP (psi) @ 0 GPM',
    width: 200,
    headerAlign: 'center',
    align: 'center',
    valueGetter: params => params?.row?.run_response?.summary?.connection_sbp,
    cellClassName: 'cellLink'
  }
];
export default columns;
