import fetch from '../../api/fetch';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { parseError } from '../../utils/fetchErrorParser';
import { getDateRange } from '../../utils/getDateRange';
import { enqueueSnackbar } from './snackbar';

export const getSpendChartData = (getDataFor, id, filterType) => (dispatch) => {
  if (filterType) {
    dispatch({
      type: REDUX_ACTIONS.SPEND_CHART_SET_FILTER_TYPE,
      payload: filterType
    });
  }
  dispatch({
    type: REDUX_ACTIONS.SPEND_CHART_DATA_LOADING,
  });

  const body = {
    query: {
      type: filterType,
      [getDataFor === 'user' ? 'userId' : 'companyId']: id,
      ...getDateRange(filterType)
    },
  };

  return fetch
    .post(`orders/spendSummary/${getDataFor}`, body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.SPEND_CHART_DATA,
        payload: data?.data?.map((item) => {
          const date = new Date(item.groupKey);
          date.setHours(0, 0, 0, 0);
          return {
            x: date,
            y: item.totalPrice,
            ...(filterType === 'year'
              ? {
                  indexLabel: `$${(item.totalPrice).toString()}`
                }
              : {})
          };
        })
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);

      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.SPEND_CHART_DATA_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};
