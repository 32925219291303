const columns = (renderCell, renderPartsCell, renderTicketsCell, renderSyncCell, renderPinsCell) => [
  {
    field: 'WarehouseCode',
    headerName: 'Warehouse Code',
    width: 200,
    renderCell,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'BinNum',
    headerName: 'Bin Number',
    width: 170,
    renderCell,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'sync',
    headerName: 'Sync',
    renderCell: renderSyncCell,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Name',
    headerName: 'Customer',
    renderCell,
    width: 500,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Latitude',
    headerName: 'Latitude',
    width: 100,
    renderCell,
    headerAlign: 'center',
    align: 'center',
    editable: true,
  },
  {
    field: 'Longitude',
    headerName: 'Longitude',
    width: 100,
    renderCell,
    headerAlign: 'center',
    align: 'center',
    editable: true
  },
  {
    field: 'Description',
    headerName: 'Description',
    width: 300,
    renderCell,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'parts',
    headerName: 'Parts',
    renderCell: renderPartsCell,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'receipts',
    headerName: 'Tickets',
    renderCell: renderTicketsCell,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'status',
    renderCell: renderPinsCell,
    headerName: 'Status',
    width: 300,
    headerAlign: 'center',
    align: 'center'
  }
];

export default columns;
