import { REDUX_ACTIONS } from '../../../shared/config/constants';

const initialState = {
  syncStatus: 'idle', // idle, loading, error
  syncResponseMessage: '',
  syncShipsToStatus: 'idel',
  syncShipsToResponseMessage: ''
};

const epicorSyncMobileDevices = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.EPICOR_SYNC_MOBILE_DATA_LOADING:
      return {
        ...state,
        syncStatus: 'loading',
        syncResponseMessage: action.payload.message,
      };
    case REDUX_ACTIONS.EPICOR_SYNC_MOBILE_DATA_IDLE:
      return {
        ...state,
        syncStatus: 'idle',
        syncResponseMessage: action.payload.message,
      };
    case REDUX_ACTIONS.EPICOR_SYNC_MOBILE_DATA_ERROR:
      return {
        ...state,
        syncStatus: 'error',
        syncResponseMessage: action.payload.message,
      };
    case REDUX_ACTIONS.EPICORE_RIGS_SYNC_SHIPSTO_LOADING:
      return {
        ...state,
        syncShipsToStatus: 'loading',
        syncShipsToResponseMessage: action.payload.message,
      };
    case REDUX_ACTIONS.EPICORE_RIGS_SYNC_SHIPSTO_IDLE:
      return {
        ...state,
        syncShipsToStatus: 'idle',
        syncShipsToResponseMessage: action.payload.message,
      };
    case REDUX_ACTIONS.EPICORE_RIGS_SYNC_SHIPSTO_ERROR:
      return {
        ...state,
        syncShipsToStatus: 'error',
        syncShipsToResponseMessage: action.payload.message,
      };
    default:
      return state;
  }
};

export default epicorSyncMobileDevices;
