import { Typography } from '@mui/material';
import React from 'react';
import { dateFormatter } from '../../utils/datetimeParser';
import {
  CHART_TIMESTAMP_FORMAT,
} from '../../utils/constants';
export const renderTimestampCell = ({ value }) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <Typography style={{ fontSize: 'medium', fontWeight: 'bold' }}>{dateFormatter(value, {
    ...CHART_TIMESTAMP_FORMAT,
    second: '2-digit',
  })}</Typography>
);

export const renderCell = ({ value }) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <Typography style={{ fontSize: 'medium', fontWeight: 'bold' }}>{value}</Typography>
);

export const columns = (renderCell, renderTimestampCell) => [
  {
    field: 'userEmail',
    headerName: 'User Email',
    width: 220,
    hide: true,
    renderCell,
  },
  {
    field: 'startTime',
    headerName: 'Timestamp',
    width: 220,
    renderCell: renderTimestampCell,
  },
  {
    field: 'endTime',
    headerName: 'End Time',
    width: 220,
    hide: true,
    renderCell: renderTimestampCell,
  },
  {
    field: 'event',
    headerName: 'Event',
    width: 420,
    hide: false,
    renderCell,
  },
];
