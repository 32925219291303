import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';

const styles = makeStyles((theme) => {
  return {
    logoContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    logo: {
      width: '300px',
      height: '200px',
      objectFit: 'contain',
    },
    mobileLogo: {
      width: '200px',
      height: '120px',
      objectFit: 'contain',
    },
    bar: {
      fontSize: '30px',
      marginLeft: '15px',
      marginRight: '10px',
      color: '#f0f0f0'
    },
    dropAreaMobile: {
      border: '2px dashed #ccc',
      borderRadius: '10px',
      padding: '20px',
      margin: '20px 0',
      width: '290px',
      textAlign: 'center'
    },
    dragDropMobile: {
      border: '2px dashed #ccc',
      borderRadius: '10px',
      padding: '20px',
      margin: '20px 0',
      width: '290px',
      textAlign: 'center',
      backgroundColor: grey[300]
    },
    dropArea: {
      border: '2px dashed #ccc',
      borderRadius: '10px',
      padding: '20px',
      margin: '20px 0',
      width: '600px',
      textAlign: 'center'
    },
    dragDrop: {
      border: '2px dashed #ccc',
      borderRadius: '10px',
      padding: '20px',
      margin: '20px 0',
      width: '600px',
      textAlign: 'center',
      backgroundColor: grey[300]
    },
    selectButton: {
      backgroundColor: grey[600],
      color: 'white',
      border: 'none',
      padding: '10px 20px',
      borderRadius: '0',
      cursor: 'pointer',
      fontSize: '16px',
      '&:hover': {
        backgroundColor: grey[700],
      },
    },
    errorMessage: {
      color: 'red'
    },
    textLimit: {
      whiteSpace: 'nowrap',
      overflow: 'hidden !important',
      textOverflow: 'ellipsis',
      fontWeight: 'bold',
      [theme.breakpoints.between('xs', 'sm')]: {
        fontSize: '16px',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        fontSize: '20px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '22px',
      },
    }
  };
});

export default styles;
