import { makeStyles } from '@mui/styles';

const styles = makeStyles(({ spacing, breakpoints }) => ({
  storyBookContainer: {
    padding: '30px 0',
    background: 'white',
    width: '100%',
    textAlign: 'center',
    [breakpoints.down(500)]: {
      padding: '30px 0',
    }
  },
  cardContainer: {
    maxWidth: '100rem',
    margin: '0 auto',
  },
  card: {
    textAlign: 'center',
    boxShadow: 'none',
    border: 'none',
    height: '540px',
    borderRadius: 10,
    [breakpoints.down(500)]: {
      height: '457px',
    }
  },
  icon: {
    width: '330px',
    height: '300px',
    marginTop: '20px',
    [breakpoints.down(500)]: {
      width: '380px',
      height: '300px',
    }
  },
  storybookTitle: {
    [breakpoints.down(500)]: {
      fontSize: '30px',
      marginBottom: '20px',
      width: '90%',
      margin: '0 auto',
      lineHeight: 1.5,
      textAlign: 'center'
    }
  },
  descriptionStyle: {
    bottom: 0,
    color: 'gray',
    fontSize: '18px',
    textAlign: 'justify',
    width: '100%',
    padding: '5px',
    [breakpoints.down(500)]: {
      fontSize: '14px',
      padding: spacing(1),
    },
  }
}));

export default styles;
