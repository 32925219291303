const columns = (renderCell) => [
  {
    field: '_id',
    headerName: '_id',
    hide: true,
    renderCell,
  },
  {
    field: 'name',
    headerName: 'Name',
    editable: false,
    renderCell,
  },
];
export default columns;
