const masterColumn = () => [
  {
    field: 'WellName',
    headerName: 'Name',
    width: 200,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'WellNumber',
    headerName: 'Well Number',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'WellID',
    headerName: 'Well ID',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'SpudDate',
    headerName: 'SpudDate',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'RigReleaseDate',
    headerName: 'RigReleaseDate',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'FirstProdDate',
    headerName: 'FirstProdDate',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'UpdatedDate',
    headerName: 'Updated Date',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'Latitude',
    headerName: 'Latitude',
    width: 200,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'Longitude',
    headerName: 'Longitude',
    width: 200,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'LeaseName',
    headerName: 'Lease Name',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  // additional columns
  {
    field: 'Country',
    headerName: 'Country',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'StateProvince',
    headerName: 'State Province',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'Trajectory',
    headerName: 'Trajectory',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  // no value fields
  {
    field: 'ENVBasin',
    headerName: 'ENVBasin',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'ENVInterval',
    headerName: 'ENVInterval',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'ENVOperator',
    headerName: 'ENVOperator',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'ENVPlay',
    headerName: 'ENVPlay',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'ENVRegion',
    headerName: 'ENVRegion',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'ENVWellStatus',
    headerName: 'ENVWellStatus',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'LastProducingMonth',
    headerName: 'LastProducingMonth',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'DeletedDate',
    headerName: 'DeletedDate',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
];

export { masterColumn };
