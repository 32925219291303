const columns = (renderValueCell, renderActions) => [
  {
    field: 'md',
    headerName: 'Measured Depth (ft)',
    width: 250,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      return isNaN(value) ? params.value : value.toFixed(2);
    },
  },
  {
    field: 'pp',
    headerName: 'Pore Pressure (ppg)',
    width: 250,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      return isNaN(value) ? params.value : value.toFixed(2);
    },
  },
  {
    field: 'fp',
    headerName: 'Fracture Pressure (ppg)',
    width: 250,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      return isNaN(value) ? params.value : value.toFixed(2);
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 220,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  },
];
export default columns;
