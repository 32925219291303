import { AddLocationAltRounded, AspectRatioRounded } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addNewWell } from '../../../../store/actions/wells';
import useAspectRatio from '../../../../hooks/useAspectRatio';
import { USER_TYPE } from '../../../../utils/constants';

const WellsControls = () => {
  const dispatch = useDispatch();
  const handleAspectRatio = useAspectRatio();
  const { preferences, type } = useSelector((state) => state.currentUser);

  const handleAddToWells = () => {
    dispatch(addNewWell());
  };

  return (
    <Box>
      <IconButton
      edge="end"
      onClick={handleAddToWells}
      sx={{ display: type === USER_TYPE.MPD_CUSTOMER_USER && 'none' }}
    >
      <AddLocationAltRounded />
      </IconButton>
      <IconButton
        edge="end"
        onClick={() => {
          handleAspectRatio(!preferences.aspectChanged);
        }}
        sx={{ marginLeft: type === USER_TYPE.MPD_CUSTOMER_USER ? 0 : 2 }}
      >
        <AspectRatioRounded />
      </IconButton>
    </Box>
  );
};

export default WellsControls;
