const columns = (renderCell, renderStatusCell) => [
  {
    field: 'BinNum',
    headerName: 'Bin Number',
    width: 350,
    renderCell,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'lastChangedStatus',
    headerName: 'Status',
    width: 150,
    renderCell: renderStatusCell,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'Division',
    headerName: 'Division',
    width: 300,
    renderCell,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'LastCheckDate',
    headerName: 'Last Update',
    width: 300,
    renderCell,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'SalesMgr_c',
    headerName: 'Sales Manager',
    width: 300,
    renderCell,
    headerAlign: 'center',
    align: 'center'
  },
];

export default columns;
