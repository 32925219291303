import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';

const position = {
  lat: 39.5,
  lng: -98.35
};

const GoogleMapView = ({
  data,
  mapData,
  rowId,
  latitudeField,
  longitudeField,
  mapPinField1,
  mapPinField2,
  popUpLabel,
  getAssetTypesLabel,
  getMapPin,
  getAssetType,
  isLoaded,
  setIconPinSize,
  showMaxDate
}) => {
  const user = useSelector((state) => state.currentUser);
  const mapRef = useRef(null);
  const [activeMarker, setActiveMarker] = useState(null);

  useEffect(() => {
    // this effect is used to update the map size when the user changes viewport to widescreen
    if (!mapRef?.current?.invalidateSize) return;
    mapRef.current.invalidateSize();
  }, [user.preferences.aspectRatioPage]);

  useEffect(() => {
    handleActiveMarker(String(rowId));
  }, [rowId]);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      setActiveMarker(null);
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    if (isLoaded && !data.loading) {
      const markers = mapData?.map((data) => {
        return {
          ...data,
          position: { lat: Number(data[latitudeField]) ?? '', lng: Number(data[longitudeField]) ?? '' }
        };
      });
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        const bounds = new google.maps.LatLngBounds();
        if (bounds) {
          markers.forEach(({ position }) => bounds.extend(position));
          map.fitBounds(bounds);
        }
      }, 100);
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        { isLoaded && !data.loading
          ? (
      <GoogleMap
          onLoad={(map) => {
            mapRef.current = map;
            handleOnLoad(map);
          }}
          onZoomChanged={() => {
            setIconPinSize && setIconPinSize([mapRef.current.zoom * 3.5, mapRef.current.zoom * 5.75]);
          }}
          onClick={() => setActiveMarker(null)}
          mapContainerStyle={{ width: '100%', height: '50vh' }}
          position={position}
          options={{ streetViewControl: false }}
        >
          <>
          {mapData.map((mappingField) => (
              <Marker
                key={mappingField._id}
                icon={getMapPin(mappingField[mapPinField1], mappingField[mapPinField2])}
                position={{ lat: Number(mappingField[latitudeField]) ?? '', lng: Number(mappingField[longitudeField]) ?? '' }}
                onClick={() => handleActiveMarker(mappingField._id)}
              >
                { activeMarker === mappingField._id
                  ? (
                <InfoWindow position={{ lat: Number(mappingField[latitudeField]) ?? '', lng: Number(mappingField[longitudeField]) ?? '' }} onCloseClick={() => setActiveMarker(null)}>
                <Box sx={{ fontSize: '12px', maxHeight: '80px', maxWidth: '250px', whiteSpace: 'pre-wrap', wordBreak: 'break-word', overflowY: 'auto' }}>
                  {popUpLabel && popUpLabel.map((label, index) => (
                    <div key={index}>{label.toUpperCase()}: {mappingField[label]}</div>
                  ))}
                  {getAssetType && <div>{getAssetType.toUpperCase()}: {getAssetTypesLabel(mappingField[getAssetType])}</div>}
                  {showMaxDate && (<div>{new Date(mappingField[showMaxDate[0]]) > new Date(mappingField[showMaxDate[1]]) ? mappingField[showMaxDate[0]] || '' : mappingField[showMaxDate[1]] || ''}</div>)}
                </Box>
                </InfoWindow>
                    )
                  : <div></div>}
              </Marker>
          ))}
          </>
        </GoogleMap>
            )
          : null}
      </Grid>
    </Grid>
  );
};

GoogleMapView.propTypes = {
  data: PropTypes.object,
  mapData: PropTypes.any,
  rowId: PropTypes.string,
  latitudeField: PropTypes.string,
  longitudeField: PropTypes.string,
  mapPinField1: PropTypes.string,
  mapPinField2: PropTypes.string,
  popUpLabel: PropTypes.any,
  getMapPin: PropTypes.func,
  getAssetTypesLabel: PropTypes.func,
  getAssetType: PropTypes.any,
  isLoaded: PropTypes.bool,
  setIconPinSize: PropTypes.func,
  showMaxDate: PropTypes.any
};

export default GoogleMapView;
