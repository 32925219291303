import { MenuSharp, ChevronLeft, NotificationsNoneRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { Box, Drawer, IconButton, List, MenuItem, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import NavMenu from '../../Appbar/NavMenu';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../../hooks/useAuth';
import { DEFAULT_SCREEN_VIEW_MODE, PAGES_DATA } from '../../../../utils/constants';
import styles from './styles';
import { ASSET_DATA_TYPES, REDUX_ACTIONS } from '../../../../shared/config/constants';
import { useDispatch, useSelector } from 'react-redux';
import Premium from '../../../../assets/images/PRO logo PMS green only.svg';
import { getJobById } from '../../../../store/actions/jobs';
import { truncateString } from '../../../../utils/truncateString';
import AlarmsStatusDialogMobile from '../../../mobile/ui/faultsWarningsAlarmDialog';

const MainMenu = ({ isMobile }) => {
  const classes = styles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [openSubMenu, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [pages, setPages] = useState(PAGES_DATA);
  const { onLogout, user } = useAuth();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { pageTitle } = useSelector((state) => state.menu);
  const searchParams = new URLSearchParams(location.search);
  const jobs = useSelector((state) => state.jobs);
  const [assetDataType] = useState(
    searchParams.get('assetDataType') ?? ASSET_DATA_TYPES.ACTUATORDATA
  );
  const jobId = assetDataType.split('_')[1];
  const [selectedJob, setSelectedJob] = useState();
  const [isAlarmStatusModalOpen, setIsAlarmStatusModalOpen] = useState(false);
  const intervalIdRef = useRef(null);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }

    if (isMobile && location?.pathname?.includes('/Data')) {
      intervalIdRef.current = setInterval(() => {
        dispatch(getJobById(jobId));
      }, 120 * 1000);

      return () => clearInterval(intervalIdRef.current);
    }
  }, [isMobile, location.pathname, jobId]);

  useEffect(() => {
    const selectedJob = jobs.data.find((element) => {
      return element._id === jobId;
    });
    setSelectedJob(selectedJob);
  }, [jobs.data]);

  const getActionType = () => {
    switch (location.pathname) {
      case '/Jobs':
        return {
          filter: REDUX_ACTIONS.JOBS_FILTER,
          searchChange: REDUX_ACTIONS.JOBS_SEARCH_CHANGE,
        };
      case '/Shop':
        return {
          filter: REDUX_ACTIONS.PARTS_LIST_FILTER,
          searchChange: REDUX_ACTIONS.PARTS_SEARCH_CHANGE,
        };
      case '/FieldBoxes':
        return {
          filter: REDUX_ACTIONS.EPICOR_BINS_FILTER,
          searchChange: REDUX_ACTIONS.EPICOR_BINS_SEARCH_CHANGE,
        };
      // Add more cases as needed
      default:
        return {
          filter: null,
          searchChange: null,
        };
    }
  };

  const handleCloseNav = (e) => {
    setOpen(false);
    setSelectedIndex(null);
    const { filter, searchChange } = getActionType();
    // clean search and reload all items on page change
    dispatch({
      type: searchChange,
      payload: { value: '' },
    });
    dispatch({
      type: filter,
    });
    dispatch({
      type: REDUX_ACTIONS.SEARCHBOX_SET_TERMS,
      payload: { searchTerm: '' },
    });
    dispatch({
      type: REDUX_ACTIONS.SET_CURRENT_JOB,
      payload: {
        jobId: null,
      },
    });

    dispatch({
      type: REDUX_ACTIONS.SET_ASPECT_RATIO_PAGE,
      payload: { aspect: DEFAULT_SCREEN_VIEW_MODE.aspectRatioPage, status: DEFAULT_SCREEN_VIEW_MODE.aspectChanged },
    });

    if (e && e.currentTarget) {
      setPages(
        pages.map((page) => {
          page.selected = false;
          if (e.currentTarget.textContent === page.label) {
            page.selected = true;
          }
          return page;
        })
      );
    }
  };

  const handleLogout = () => {
    onLogout();
  };

  const drawer = (
    <Box style={{ overflowY: 'auto' }}>
      <List>
      <NavMenu
          user={user}
          handleCloseNav={() => {
            if (isMobile) {
              dispatch({
                type: REDUX_ACTIONS.PART_CATEGORIES_RESET,
              });
            }
            handleDrawerToggle();
            handleCloseNav();
          }}
          setSubMenuOpen={setOpen}
          openSubMenu={openSubMenu}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
        <hr />
        <MenuItem
          key="main_menu_logout"
          onClick={handleLogout}
          className={classes.menuItem}
        >
          <Link className={classes.navMenuItem}>Log Out</Link>
        </MenuItem>
      </List>
    </Box>
  );

  if (isMobile) {
    return (
      <nav>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px 0px'
        }}>
          <IconButton
              color="#000"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuSharp />
          </IconButton>
          {location?.pathname?.includes('/Data')
            ? (
            <Typography
            component="div"
            sx={{
              flexGrow: 1,
              fontSize: '18px',
              fontWeight: 'bold',
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
            }}
            color="ButtonText"
          >
             {truncateString(pageTitle, 15)}
            {jobs.data.length > 0 && selectedJob && (
        <IconButton
          key="actuator_data"
          variant="solid"
          style={{ marginLeft: 5 }}
          onClick={() => {
            const { faults, warnings } = selectedJob.systemStatusDetails;
            if (faults.length || warnings.length || selectedJob.alarms?.alarms?.length) {
              setIsAlarmStatusModalOpen(true);
            }
          }}
        >
          <NotificationsNoneRounded
            color={`alarm${selectedJob.alarms.overallStatus}`}
            style={{ fontSize: 24 }}
          />
        </IconButton>
            )}
          </Typography>
              )
            : null}
          <Box
            component="img"
            alt="Logo"
            src={Premium}
            className={classes.logo}
            sx={{ width: 'auto !important' }}
          />
        </Box>

        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '60%' },
          }}
        >
          {drawer}
        </Drawer>
        <AlarmsStatusDialogMobile
                    isOpen={isAlarmStatusModalOpen}
                    onCloseClick={() => setIsAlarmStatusModalOpen(false)}
                    selectedJob={selectedJob}
                />
      </nav>
    );
  }

  return (
    <>
      <IconButton
        size="large"
        edge="start"
        color="black"
        aria-label="menu"
        sx={{ mr: 2 }}
        onClick={handleDrawerToggle}
      >
        <MenuSharp />
        <Box
          component="img"
          alt="Logo"
          src={Premium}
          className={classes.logo}
        />
      </IconButton>
      <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          sx={{ '& .MuiDrawer-paper': { position: 'relative', width: 'fit-content', overflowY: 'unset' } }}
           >
          {drawer}
          <div className={classes.menuTogglerBtn}>
          <IconButton onClick={handleDrawerToggle} ><ChevronLeft sx={{ fontSize: '33px' }}/></IconButton>
          </div>
        </Drawer>
    </>
  );
};

MainMenu.propTypes = {
  isMobile: PropTypes.any,
};

MainMenu.defaultProps = {
  isMobile: false,
};

export default MainMenu;
