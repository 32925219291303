import { REDUX_ACTIONS } from '../../shared/config/constants';

const initialState = {
  pageTitle: '',
  tab: '',
};

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.MENU_SET_TITLE:
      return {
        ...state,
        pageTitle: action.payload,
      };
    case REDUX_ACTIONS.MENU_SET_TAB:
      return {
        ...state,
        tab: action.payload,
      };
    default:
      return state;
  }
};

export default menuReducer;
