const columns = () => [
  {
    field: 'steps',
    headerName: 'Steps',
    headerAlign: 'center',
    align: 'center',
    width: 80,
  },
  {
    field: 'flow_in',
    headerName: 'Flow In (gpm)',
    width: 150,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'sbp',
    headerName: 'SBP (psi)',
    width: 150,
    headerAlign: 'center',
    align: 'center',
  },
];
export default columns;
